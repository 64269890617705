import React, { Component } from "react";
import { Col, Row, Button, Card, Typography } from "antd";
import "../App.less";
import "antd/dist/antd.css";
import { Player, ControlBar } from "video-react";
import "../Styles/videoReact.css";
import { connect } from "react-redux";
import ReactGA from "react-ga4";
const { Title, Text } = Typography;

//class part state they holds the data this page
class LocationTypeTabs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      productLocation: "Doors",
      productType: "Openable",
      productSubType: "Scuttlo",
      videoURL:
        "https://allmeshimages.s3.ap-south-1.amazonaws.com/productTypeImages/Product8.mp4",
      matches: window.matchMedia("(min-width: 512px)").matches,
    };
  }
  //functions login page

  componentDidMount = () => {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 512px)").addEventListener("change", handler);
  };

  location = (location, value) => {
    this.setState({
      productLocation: location,
      productType: Object.keys(value.value)[0],
      productSubType: "",
      videoURL: value.video,
    });
  };

  trackEventFunction = (event) => {
    ReactGA.event({
      category: "Location category",
      action: "Location " + event + " Clicked",
      label: "Location label",
      value: 1,
    });
  };

  render() {
    return (
      <div>
        {this.state.matches && (
          <>
            <Row>
              {Object.entries(this.props.common.productLocationTypeMap).map(
                ([key, value]) => {
                  return (
                    <Col
                      lg={6}
                      md={6}
                      sm={{ span: 24 }}
                      style={{ width: "100%" }}
                      onClick={this.location.bind(this, key, value)}
                    >
                      <Button
                        style={{
                          height: 105,
                          width: "100%",
                          backgroundColor:
                            this.state.productLocation == key
                              ? "#D32402"
                              : "#F3F3F3",

                          border: "none",
                          textAlign: "center",
                        }}
                        onClick={() => {
                          this.location(key, value);
                          this.trackEventFunction(key);
                        }}
                      >
                        <Col lg={24} md={24} sm={24}>
                          <h2
                            style={{
                              color:
                                this.state.productLocation == key
                                  ? "#FFFFFF"
                                  : "#353535",
                              fontWeight: 900,
                              fontSize: 24,
                              textAlign: "center",
                              padding: 30,
                            }}
                            className="font"
                          >
                            {key.toUpperCase()}
                          </h2>
                        </Col>
                      </Button>
                    </Col>
                  );
                }
              )}
            </Row>

            {this.state.productLocation !== "" && (
              <div>
                <Row style={{ height: "1%" }}>
                  <Col
                    lg={12}
                    md={12}
                    sm={24}
                    style={{ backgroundColor: "#F5FDFF" }}
                  >
                    <div
                      style={{
                        width: "100%",
                        backgroundColor: "#F5FDFF",
                      }}
                    >
                      <Col
                        lg={24}
                        md={24}
                        sm={24}
                        style={{ marginLeft: 61, marginTop: 46 }}
                      >
                        <p
                          style={{ fontWeight: 600, fontSize: 18 }}
                          className="font"
                        >
                          Door Type
                        </p>
                      </Col>

                      <Col
                        lg={24}
                        md={12}
                        sm={24}
                        style={{ marginLeft: 30, marginTop: 25 }}
                      >
                        {Object.entries(
                          this.props.common.productLocationTypeMap[
                            this.state.productLocation
                          ].value
                        ).map(([key, value]) => {
                          return (
                            <Button
                              onClick={() => {
                                this.setState({
                                  productType: key,
                                  productSubType: "",
                                });
                                this.trackEventFunction(key);
                              }}
                              style={{
                                marginLeft: 30,
                                width: "35.2%",
                                height: 60,
                                backgroundColor: "#F5FDFF",
                                marginTop: "3%",
                                borderRadius: 10,
                                boxShadow:
                                  "14px 14px 37px #c0c5c9, -14px -14px 37px #ffffff",
                                border: "none",
                              }}
                              className="neu"
                            >
                              <p
                                style={{
                                  fontWeight: 600,
                                  fontSize: 18,
                                  color:
                                    this.state.productType == key
                                      ? "#D32402"
                                      : "#353535",
                                  paddingTop: 10,
                                  textOverflow: "clip",
                                  width: "100%",
                                  whiteSpace: "nowrap",
                                  overflow: "hidden",
                                }}
                                className="font"
                              >
                                <img
                                  src={
                                    this.state.productType == key
                                      ? value.imageRed
                                      : value.image
                                  }
                                  width="49.21"
                                  height="36"
                                  style={{
                                    marginBottom: "auto",
                                    marginTop: "auto",
                                  }}
                                />
                                &nbsp;
                                {key}
                              </p>
                            </Button>
                          );
                        })}
                      </Col>
                      {this.state.productLocation !== "Security" ? (
                        <Col
                          lg={24}
                          md={24}
                          sm={24}
                          style={{ marginLeft: 61, marginTop: 36 }}
                        >
                          <p
                            style={{ fontWeight: 600, fontSize: 18 }}
                            className="font"
                          >
                            Opening Type
                          </p>
                        </Col>
                      ) : (
                        ""
                      )}
                      <Col
                        lg={24}
                        md={24}
                        sm={24}
                        style={{ marginLeft: 30, marginTop: 25 }}
                      >
                        {this.state.productType !== "" &&
                          Object.entries(
                            this.props.common.productLocationTypeMap[
                              this.state.productLocation
                            ].value[this.state.productType].value
                          ).length > 0 && (
                            <div>
                              <Col lg={24} md={24} sm={24}>
                                {Object.entries(
                                  this.props.common.productLocationTypeMap[
                                    this.state.productLocation
                                  ].value[this.state.productType].value
                                ).map((value) => {
                                  return (
                                    <Button
                                      onClick={() => {
                                        this.setState({
                                          productSubType: value[1].name,
                                          videoURL: value[1].video,
                                        });
                                        this.trackEventFunction(value[1].name);
                                      }}
                                      style={{
                                        width: "35.2%",
                                        height: 60,
                                        marginBottom: 30,
                                        marginLeft: 30,
                                        backgroundColor: "#F5FDFF",
                                        borderRadius: 10,
                                        boxShadow:
                                          "14px 14px 37px #c0c5c9, -14px -14px 37px #ffffff",
                                        border: "none",
                                      }}
                                      className="neu"
                                    >
                                      <p
                                        style={{
                                          fontWeight: 600,
                                          fontSize: 18,
                                          textAlign: "center",
                                          color:
                                            this.state.productSubType ==
                                            value[1].name
                                              ? "#D32402"
                                              : "#353535",

                                          paddingTop: 10,
                                        }}
                                        className="font"
                                      >
                                        <img
                                          src={
                                            this.state.productSubType ==
                                            value[1].name
                                              ? value[1].imageRed
                                              : value[1].image
                                          }
                                          width="69.21"
                                          height="36"
                                          style={{
                                            marginBottom: "auto",
                                            marginTop: "auto",
                                          }}
                                        />
                                        &nbsp;
                                        {value[1].name}
                                      </p>
                                    </Button>
                                  );
                                })}
                              </Col>
                              {this.state.productLocation === "Windows" ? (
                                <Col
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  style={{ marginTop: 8 }}
                                >
                                  <p
                                    style={{
                                      fontWeight: 400,
                                      marginLeft: 30,
                                      fontSize: 18,
                                      width: "80%",
                                    }}
                                    className="font"
                                  >
                                    To meet the needs of our various clients,
                                    AllMesh offers a variety of diverse goods
                                    and services. We provide premium window mesh
                                    solutions at affordable pricing for
                                    household clients and homeowners. We provide
                                    an easy ordering and quote process, quick
                                    turnaround times, and a dependable service
                                    for business clients like builders and
                                    architects.
                                  </p>
                                </Col>
                              ) : this.state.productLocation === "Doors" ? (
                                <Col
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  style={{ marginTop: 8 }}
                                >
                                  <p
                                    style={{
                                      fontWeight: 400,
                                      marginLeft: 30,
                                      fontSize: 18,
                                      width: "80%",
                                    }}
                                    className="font"
                                  >
                                    The statement that AllMesh screens are the
                                    best is bold, but we really believe it to be
                                    true. Our screens are carefully woven and
                                    employ the most recent advancements in
                                    insect screening, providing you a wider
                                    range of alternatives. People have trusted
                                    AllMesh for quality for a very long time,
                                    and we're still going strong! Whether you're
                                    looking for a door screen, material for a
                                    screened porch, or a door screen, we're the
                                    company to turn to!
                                  </p>
                                </Col>
                              ) : this.state.productLocation === "Balcony" ? (
                                <Col
                                  lg={24}
                                  md={24}
                                  sm={24}
                                  style={{ marginTop: 8 }}
                                >
                                  <p
                                    style={{
                                      fontWeight: 400,
                                      marginLeft: 30,
                                      fontSize: 18,
                                      width: "80%",
                                    }}
                                    className="font"
                                  >
                                    Balcony the place where we all unwind and
                                    connect with nature, AllMesh ensures we
                                    protect your experience from insects by
                                    giving multiple solutions. Birdo our bird
                                    mesh solution or our solution for your
                                    French doors, we ensure that you get
                                    handpicked solution to have a safe and
                                    healthy balcony experience…Lets merge with
                                    nature
                                  </p>
                                </Col>
                              ) : (
                                ""
                              )}
                            </div>
                          )}
                      </Col>
                      {this.state.productLocation === "Security" ? (
                        <Col
                          lg={24}
                          md={24}
                          sm={24}
                          style={{ marginTop: "8%" }}
                        >
                          <p
                            style={{
                              fontWeight: 400,
                              marginLeft: 30,
                              fontSize: 18,
                              width: "80%",
                            }}
                            className="font"
                          >
                            Balcony the place where we all unwind and connect
                            with nature, AllMesh ensures we protect your
                            experience from insects by giving multiple
                            solutions. Birdo our bird mesh solution or our
                            solution for your French doors, we ensure that you
                            get handpicked solution to have a safe and healthy
                            balcony experience…Lets merge with nature
                          </p>
                        </Col>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>

                  <Col
                    lg={12}
                    md={12}
                    sm={24}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Col
                      lg={24}
                      md={24}
                      sm={24}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <div style={{ width: "100%", height: "100%" }}>
                        <Player
                          className="video"
                          fluid={false}
                          autoPlay={true}
                          playsInline
                          src={this.state.videoURL}
                          // src={video}
                          muted={true}
                          // width={660}
                          height={680}
                          width="95%"
                          style={{
                            objectFit: "cover",
                            width: "auto",
                            height: "auto",
                          }}
                        >
                          <ControlBar autoHide={false} className="my-class" />
                        </Player>
                      </div>
                    </Col>
                  </Col>
                </Row>
              </div>
            )}
          </>
        )}
        {!this.state.matches && (
          <Row>
            {Object.entries(this.props.common.productLocationTypeMap).map(
              ([key, value]) => {
                return (
                  <Col span={6} style={{ width: "100%" }}>
                    <Button
                      style={{
                        height: 80,
                        width: "100%",
                        backgroundColor:
                          this.state.productLocation == key
                            ? "#D32402"
                            : "#F3F3F3",
                        border: "none",
                        textAlign: "center",
                      }}
                      onClick={() => {
                        this.location(key, value);
                        this.trackEventFunction(key);
                      }}
                    >
                      <Col lg={24} md={24} sm={24}>
                        <p
                          style={{
                            color:
                              this.state.productLocation == key
                                ? "white"
                                : "black",
                            fontWeight: 600,
                            fontSize: 12,
                            paddingTop: 15,
                            paddingRight: "auto",
                            paddingLeft: "auto",
                          }}
                          className="font"
                        >
                          {key.toUpperCase()}
                        </p>
                      </Col>
                    </Button>
                  </Col>
                );
              }
            )}

            {this.state.productLocation !== "" && (
              <div>
                <Row>
                  <Col
                    lg={24}
                    md={24}
                    sm={24}
                    style={{ width: "100%", height: "100%" }}
                  >
                    <Card
                      style={{
                        width: "100%",
                        backgroundColor: "#F5FDFF",
                        height: "100%",
                      }}
                    >
                      <Col
                        lg={24}
                        md={24}
                        sm={24}
                        style={{ marginLeft: "1%", marginTop: "1%" }}
                      >
                        <p
                          style={{
                            fontWeight: 600,
                            fontSize: 18,
                            color: "#353535",
                          }}
                          className="font"
                        >
                          Door Type
                        </p>
                      </Col>

                      <Col
                        lg={24}
                        md={24}
                        sm={24}
                        style={{ marginTop: 10, paddingRight: "2%" }}
                      >
                        {Object.entries(
                          this.props.common.productLocationTypeMap[
                            this.state.productLocation
                          ].value
                        ).map(([key, value]) => {
                          return (
                            <Button
                              onClick={() => {
                                this.setState({
                                  productType: key,
                                  productSubType: "",
                                });
                                this.trackEventFunction(key);
                              }}
                              style={{
                                marginLeft: 10,
                                width: "37%",
                                height: 60,
                                backgroundColor: "#F5FDFF",
                                marginBottom: 20,
                              }}
                              className="neu"
                            >
                              <img
                                src={
                                  this.state.productType == key
                                    ? value.imageRed
                                    : value.image
                                }
                                width="69.21"
                                height="16"
                                style={{
                                  marginRight: "auto",
                                  marginLeft: "auto",
                                  marginBottom: "auto",
                                  marginTop: "auto",
                                  display: "block",
                                }}
                              />
                              <p
                                style={{
                                  fontWeight: 700,
                                  fontSize: 12,
                                  textAlign: "center",
                                  color:
                                    this.state.productType == key
                                      ? "#D32402"
                                      : "#353535",
                                }}
                                className="font"
                              >
                                {key}
                              </p>
                            </Button>
                          );
                        })}
                      </Col>

                      <Col
                        lg={24}
                        md={24}
                        sm={24}
                        style={{ marginLeft: "1%", marginTop: "6%" }}
                      >
                        <p
                          style={{
                            fontWeight: 600,
                            fontSize: 18,
                            color: "#353535",
                          }}
                          className="font"
                        >
                          Opening Type
                        </p>
                      </Col>
                      <Col
                        lg={24}
                        md={24}
                        sm={24}
                        style={{ marginTop: 10, paddingRight: "2%" }}
                      >
                        <div>
                          <Col lg={24} md={24} sm={24}>
                            {Object.entries(
                              this.props.common.productLocationTypeMap[
                                this.state.productLocation
                              ].value[this.state.productType].value
                            ).map((value) => {
                              return (
                                <Button
                                  onClick={() => {
                                    this.setState({
                                      productSubType: value[1].name,
                                      videoURL: value[1].video,
                                    });
                                    this.trackEventFunction(value[1].name);
                                  }}
                                  style={{
                                    width: "37%",
                                    height: 60,
                                    marginBottom: 20,
                                    marginLeft: 10,
                                    backgroundColor: "#F5FDFF",
                                  }}
                                  className="neu"
                                >
                                  <img
                                    src={
                                      this.state.productSubType == value[1].name
                                        ? value[1].imageRed
                                        : value[1].image
                                    }
                                    width="69.21"
                                    height="16"
                                    style={{
                                      marginRight: "auto",
                                      marginLeft: "auto",
                                      marginBottom: "auto",
                                      marginTop: "auto",
                                      display: "block",
                                    }}
                                  />

                                  <p
                                    style={{
                                      fontWeight: 700,
                                      fontSize: 12,
                                      textAlign: "center",
                                      color:
                                        this.state.productSubType ==
                                        value[1].name
                                          ? "#D32402"
                                          : "#353535",
                                    }}
                                    className="font"
                                  >
                                    {value[1].name}
                                  </p>
                                </Button>
                              );
                            })}
                          </Col>
                          {this.state.productLocation === "Security" ? (
                            <Col
                              lg={24}
                              md={24}
                              sm={24}
                              style={{ marginTop: "8%" }}
                            >
                              <p
                                style={{
                                  fontWeight: 400,
                                  marginLeft: 30,
                                  fontSize: 18,
                                  width: "80%",
                                }}
                                className="font"
                              >
                                Balcony the place where we all unwind and
                                connect with nature, AllMesh ensures we protect
                                your experience from insects by giving multiple
                                solutions. Birdo our bird mesh solution or our
                                solution for your French doors, we ensure that
                                you get handpicked solution to have a safe and
                                healthy balcony experience…Lets merge with
                                nature
                              </p>
                            </Col>
                          ) : this.state.productLocation === "Doors" ? (
                            <Col
                              lg={24}
                              md={24}
                              sm={24}
                              style={{ marginTop: "8%" }}
                            >
                              <p
                                style={{
                                  fontWeight: 400,
                                  marginLeft: 30,
                                  fontSize: 18,
                                  width: "80%",
                                }}
                                className="font"
                              >
                                The statement that AllMesh screens are the best
                                is bold, but we really believe it to be true.
                                Our screens are carefully woven and employ the
                                most recent advancements in insect screening,
                                providing you a wider range of alternatives.
                                People have trusted AllMesh for quality for a
                                very long time, and we're still going strong!
                                Whether you're looking for a door screen,
                                material for a screened porch, or a door screen,
                                we're the company to turn to!
                              </p>
                            </Col>
                          ) : this.state.productLocation === "Balcony" ? (
                            <Col
                              lg={24}
                              md={24}
                              sm={24}
                              style={{ marginTop: "8%" }}
                            >
                              <p
                                style={{
                                  fontWeight: 400,
                                  marginLeft: 30,
                                  fontSize: 18,
                                  width: "80%",
                                }}
                                className="font"
                              >
                                Balcony the place where we all unwind and
                                connect with nature, AllMesh ensures we protect
                                your experience from insects by giving multiple
                                solutions. Birdo our bird mesh solution or our
                                solution for your French doors, we ensure that
                                you get handpicked solution to have a safe and
                                healthy balcony experience…Lets merge with
                                nature
                              </p>
                            </Col>
                          ) : this.state.productLocation === "Windows" ? (
                            <Col
                              lg={24}
                              md={24}
                              sm={24}
                              style={{ marginTop: "8%" }}
                            >
                              <p
                                style={{
                                  fontWeight: 400,
                                  marginLeft: 30,
                                  fontSize: 18,
                                  width: "80%",
                                }}
                                className="font"
                              >
                                To meet the needs of our various clients,
                                AllMesh offers a variety of diverse goods and
                                services. We provide premium window mesh
                                solutions at affordable pricing for household
                                clients and homeowners. We provide an easy
                                ordering and quote process, quick turnaround
                                times, and a dependable service for business
                                clients like builders and architects.
                              </p>
                            </Col>
                          ) : (
                            ""
                          )}
                        </div>
                      </Col>
                    </Card>
                  </Col>

                  <Col
                    lg={24}
                    md={24}
                    sm={24}
                    style={{
                      width: "100%",
                      height: "100%",
                      paddingRight: 20,
                      paddingLeft: 20,
                      marginTop: "10%",
                    }}
                  >
                    <div style={{ width: "100%", height: "100%" }}>
                      <Player
                        className="video"
                        fluid={false}
                        autoPlay={true}
                        playsInline
                        src={this.state.videoURL}
                        muted={true}
                        // width={660}
                        height={380}
                        width="100%"
                        style={{
                          objectFit: "cover",
                          width: "auto",
                          height: "auto",
                        }}
                      >
                        <ControlBar autoHide={false} className="my-class" />
                      </Player>
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </Row>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
  };
};

function mapDispatchToProps(dispatch) {
  return {};
}
export default connect(mapStateToProps, mapDispatchToProps)(LocationTypeTabs);
