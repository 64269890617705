import React, { useState, useEffect } from "react";
import "../App.less";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Card, Typography, Descriptions, Menu } from "antd";
import environment from "../configure";
import Faq from "react-faq-component";

const { Title, Text } = Typography;

const data = {
  rows: [
    {
      title: <p style={{fontWeight:700}}>I also have a dog and a gun. I need security screens, but why?</p>,
      content: `Because smart security uses layers of protection. If you can keep people out of your house, why let them in? We have security screens that virtually prohibit anyone from breaking in.`,
    },
    {
      title: <p style={{fontWeight:700}}>Can I cover my French doors with a security screen?</p>,
      content:
        "We can, yes! Even better, we can secure the dog door we install in the French door. security doors, please.",
    },
    {
      title: <p style={{fontWeight:700}}>I have a patio sliding door; can I add security screens to it?</p>,
      content: `Yes! Security for patio sliding doors is crucial because they are located in one of your home's most exposed spaces. Since there are poor lines of sight to the
      neighbours, it provides a simple entry for criminals. The security screens for patio sliding doors are shown here.`,
    },
    {
      title: <p style={{fontWeight:700}}>Are your storm-resistant security screens?</p>,
      content: `Our security screens do meet hurricane category 4 ratings. When there is bad weather, they are ideal (which, unfortunately, we can expect to have more of.). In windy conditions, our security screens will assist shield your glass windows and the occupants from flying debris.`,
    },
    {
        title: <p style={{fontWeight:700}}>What shades are possible?</p>,
       
        content: `Any colour is an option. Every detail is unique. Simply tell us what you need, and we'll make it. By the way, you should check out the purple doors we created for a Chennai client!
        `,
      },
      {
        title: <p style={{fontWeight:700}}>The security screens come with a warranty, right?</p>,
        content: `Yes! A lifetime guarantee is included with each security screen for both parts and labour.
        `,
      },
      {
        title: <p style={{fontWeight:700}}>What distinguishes AllMesh from other security screen businesses?</p>,
        content: <p>We are really distinct from each other:<br></br>1. Our product is backed by a No Break-In Guarantee. No, they don't.<br></br>2. Our work and materials are covered by a lifetime warranty. No, they don't.<br></br>3.Our aluminium extruded frames are more durable. higher price yet offers the best security. Once again, they choose a less durable design to save money.

        </p>,
      },
  ],
};

const styles = {
   bgColor: 'white',
  titleTextColor: "#353535",
  rowTitleColor: "#353535",
   rowContentColor: 'grey',
 //  arrowColor: "#D32402",
};

const config = {
   //animate: true,
  //arrowIcon: "V",
  // tabFocus: true
};

function AboutUs(props) {
  const navigation = useNavigate();
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 412px)").matches
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window
    .matchMedia("(min-width: 412px)")
    .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div>
      {/* <Header /> */}
      <Row>
        <Col lg={24} md={24} sm={24} style={{ width: "100%",paddingBottom:'6%',paddingRight:'10%',paddingLeft:'10%' }}>
        <Col
            xs={{ span: 24 }}
            xl={24}
            sm={24}
            style={{ paddingBottom: 55, marginTop: 10 }}
          >

{matches && (<h2
              align="center"
              className="font"
              style={{ fontWeight: 900, marginTop: 30, fontSize:35}}
            >
              FREQUENTLY ASKED QUESTIONS
            </h2>)}
      {!matches && (<h2
              align="center"
              className="font"
              style={{ fontWeight: 900, marginTop: 30, fontSize:22}}
            >
              FREQUENTLY ASKED QUESTIONS
            </h2>)}
            
          </Col>
          <Faq data={data} styles={styles} config={config} />
        </Col>
      </Row>
    </div>
  );
}

export default AboutUs;
