import React, { useState, useEffect } from "react";
import "../App.less";
import "./header.css"; // You can create a CSS file for styling
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Card, Dropdown, Drawer, Menu, Space } from "antd";
import { MenuOutlined } from "@ant-design/icons";
import ReactGA from "react-ga4";
const button = [
  "Home",
  "About Us",
  "Products",
  "Projects",
  "Locate Us",
  "Contact",
];

//class part state they holds the data this page
function Header() {
  const navigation = useNavigate();
  const [buttonLocation, setButtonLocation] = useState("Home");
  const [open, setOpen] = useState(false);
  const [sticky, setSticky] = useState("");

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1200px)").matches
  );

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                navigation("/products", { state: { data: "Doors" } });
              }}
            >
              <p>Doors</p>
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              onClick={() => {
                navigation("/products", { state: { data: "Windows" } });
              }}
            >
              <p>Windows</p>
            </a>
          ),
        },
        {
          key: "3",
          label: (
            <a
              onClick={() => {
                navigation("/products", { state: { data: "Balcony" } });
              }}
            >
              <p>Balcony</p>
            </a>
          ),
        },
        {
          key: "4",

          label: (
            <a
              onClick={() => {
                navigation("/products", { state: { data: "Security" } });
              }}
            >
              <p>Security</p>
            </a>
          ),
        },
      ]}
    />
  );

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const isSticky = () => {
    /* Method that will fix header after a specific scrollable */
    const scrollTop = window.scrollY;
    const stickyClass = scrollTop >= 10 ? "is-sticky" : "";
    setSticky(stickyClass);
    console.log(stickyClass);
  };

  useEffect(() => {
    // setButtonLocation("Home");

    navigation("/");
    window
      .matchMedia("(min-width: 1300px)")
      .addEventListener("change", (e) => setMatches(e.matches));

    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  }, []);

  const goTo = (location) => {
    if (location === "Home") {
      setButtonLocation("Home");
      navigation("/");
    } else if (location === "About Us") {
      setButtonLocation("About Us");
      navigation("/aboutUs");
    } else if (location === "Products") {
      setButtonLocation("Products");
      navigation("/products", { state: { data: "Doors" } });
    } else if (location === "Contact") {
      setButtonLocation("Contact");
      navigation("/contactUs");
    } else if (location === "Projects") {
      setButtonLocation("Projects");
      navigation("/projects");
    } else if (location === "Franchise") {
      setButtonLocation("Franchise");
      navigation("/franchise");
    } else if (location === "Locate Us") {
      setButtonLocation("Locate Us");
      navigation("/locateUs");
    }
  };
  const classes = `header-section d-none d-xl-block ${sticky}`;

  const trackEventFunction = (event) => {
    ReactGA.event({
      category: "Header category",
      action: "Header " + event + " Clicked",
      label: "Header label",
      value: 1,
    });
  };

  return (
    <div>
      {!matches && (
        <>
          <Row>
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ width: "100%" }}
              className={classes}
            >
              <Card
                style={{
                  height: 80,
                  width: "100%",
                  backgroundColor: " #FFFFFF",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/asset/AllmeshLogo.png"}
                  alt="Ant Design"
                  width="140"
                  height="85"
                  style={{
                    marginTop: -25,
                    flex: "none",
                    marginLeft: 14,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    setButtonLocation("Home");
                    goTo("Home");
                    trackEventFunction("Home");
                  }}
                />

                <Button
                  type="link"
                  onClick={showDrawer}
                  style={{ marginTop: -1, flex: "none", float: "right" }}
                >
                  <MenuOutlined
                    style={{ fontSize: "150%", color: "#D32402" }}
                  />
                </Button>
              </Card>
            </Col>
          </Row>
          <Drawer
            placement="right"
            onClose={onClose}
            open={open}
            size={"default"}
          >
            <Button
              style={{
                height: 30,
                bottom: 15,
                borderColor: "#D32402",
              }}
              onClick={() => {
                navigation("/contactUs");
                setOpen(false);
                trackEventFunction("Contact");
              }}
            >
              <p
                style={{
                  fontSize: 12,
                  fontWeight: 500,
                  color: "#D32402",
                  justifyContent: "center",
                }}
                className="font"
              >
                Call Us
              </p>
            </Button>
            <Button
              style={{
                fontSize: 12,
                backgroundColor: "#D32402",
                bottom: 15,
                height: 30,
                textAlign: "center",
                color: "white",
                fontWeight: 500,
                borderRadius: 4,
                marginLeft: 10,
              }}
              onClick={() => {
                navigation("/estimate");
                setOpen(false);
                trackEventFunction("Estimation");
              }}
            >
              Get an Estimate
            </Button>
            <Button
              style={{
                fontSize: 12,
                backgroundColor: "#D32402",
                bottom: 15,
                height: 30,
                textAlign: "center",
                color: "white",
                fontWeight: 500,
                borderRadius: 4,
                marginLeft: 10,
              }}
              onClick={() => {
                navigation("/franchise");
                setOpen(false);
                trackEventFunction("Franchise");
              }}
            >
              Franchise
            </Button>

            <Menu theme="light" mode="inline" defaultSelectedKeys={["1"]}>
              {Object.entries(button).map(([key, value]) => {
                return (
                  <>
                    <Menu.Item key="1">
                      <Button
                        type="link"
                        onClick={() => {
                          setButtonLocation(value);
                          goTo(value);
                          setOpen(false);
                          trackEventFunction(value);
                        }}
                      >
                        <p
                          style={{
                            color:
                              buttonLocation == value ? "#D32402" : "black",
                            fontWeight: 700,
                            fontSize: 14,
                          }}
                        >
                          {value}
                        </p>
                      </Button>
                    </Menu.Item>
                  </>
                );
              })}
            </Menu>
          </Drawer>
        </>
      )}
      {matches && (
        <Row>
          <Col lg={24} md={24} sm={24} className={classes}>
            <Card
              style={{
                height: 84,
                width: "100%",
                backgroundColor: " #FFFFFF",
                position: "sticky",
                top: 0,
              }}
              className="header"
            >
              <img
                src={process.env.PUBLIC_URL + "/asset/AllmeshLogo.png"}
                alt="Ant Design"
                width="160"
                height="90"
                style={{
                  marginTop: -25,
                  flex: "none",
                  marginLeft: "4%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  setButtonLocation("Home");
                  goTo("Home");
                  trackEventFunction("Home");
                }}
              />
              <Col style={{ bottom: 68, marginLeft: "20%" }}>
                {Object.entries(button).map(([key, value]) => {
                  return (
                    <>
                      <Button
                        type="link"
                        style={{ paddingRight: "1%" }}
                        onClick={() => {
                          goTo(value);
                          trackEventFunction(value);
                        }}
                      >
                        <h1
                          style={{
                            color:
                              buttonLocation == value ? "#D32402" : "black",
                            fontWeight: 700,
                            fontSize: 17,
                          }}
                        >
                          {value}
                        </h1>
                      </Button>
                      {/* <Dropdown
                        overlay={buttonLocation == "Products" ? menu : ""}
                        
                        onClick={goTo.bind(this, value)}
                        trigger={["click"]}
                      >
                        <a>
                          <Space style={{ paddingRight: "5%" }}>
                            <h1
                              style={{
                                color:
                                  buttonLocation == value ? "#D32402" : "black",
                                //  color:hover?"red":"black",
                                fontWeight: 800,
                                fontSize: 18,
                              }}
                            >
                              {value}
                            </h1>
                          </Space>
                        </a>
                      </Dropdown> */}
                    </>
                  );
                })}
                {/* <Dropdown overlay={menu}>
    <a onClick={e => e.preventDefault()}>
      <Space>
        Hover me
        
      </Space>
    </a>
  </Dropdown> */}
                <Button
                  style={{
                    marginLeft: "3%",
                    width: "8.4%",
                    height: 38,
                    borderColor: "#D32402",
                    paddingTop: 5,
                    borderRadius: 4,
                    fontWeight: 900,
                    fontSize: 15,
                    borderWidth: 2,
                    textAlign: "center",
                    bottom: 1,
                  }}
                  className="btn"
                  onClick={() => {
                    navigation("/contactUs");
                    trackEventFunction("Contact");
                  }}
                >
                  <p>Call Us</p>
                </Button>
                <Button
                  style={{
                    fontSize: 15,
                    bottom: 1,
                    height: 38,
                    width: "14%",
                    fontWeight: 900,
                    borderRadius: 4,
                    marginLeft: "1%",
                  }}
                  className="btn1"
                  onClick={() => {
                    navigation("/estimate");
                    trackEventFunction("Estimation");
                  }}
                >
                  <p>Get an Estimate</p>
                </Button>
                <Button
                  style={{
                    fontSize: 15,
                    bottom: 1,
                    height: 38,
                    width: "14%",
                    fontWeight: 900,
                    borderRadius: 4,
                    marginLeft: "1%",
                  }}
                  className="btn1"
                  onClick={() => {
                    navigation("/franchise");
                    trackEventFunction("Franchise");
                  }}
                >
                  <p>Franchise</p>
                </Button>
              </Col>
            </Card>
          </Col>
        </Row>
      )}
    </div>
  );
}

export default Header;
