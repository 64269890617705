import React, { useState, useEffect } from "react";
import "../App.less";
import "antd/dist/antd.css";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Card, Typography, Input } from "antd";
import Images from "../Images/ContactUs.png";
import uuid from "uuid/dist/v4";
import environment from "../configure";
import ReactGA from "react-ga4";
const { Text } = Typography;

//class part state they holds the data this page
function Contact(props) {
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );
  const navigation = useNavigate();
  const addedDate = new Date().toISOString().substr(0, 10);

  useEffect(() => {
    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  const submit = async () => {
    if (customerName == "") return alert("Please enter your name");
    if (customerEmail == "") return alert("Please enter your email");
    var mailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (customerEmail !== "" && mailReg.test(customerEmail) === false)
      return alert("Please enter valid email");
    if (customerPhone == "") return alert("Please enter your phone number");
    var phoneReg = /^[5-9]{1}[0-9]{9}$/;
    if (phoneReg.test(customerPhone) === false)
      return alert("Please enter valid phone number");

    try {
      var addOrder = await fetch(
        environment.apiGateway + "addQuotationDetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: uuid(),
            customerName: customerName,
            customerPhone: "+91" + customerPhone,
            customerEmail: customerEmail,
            customerReferenceNumber: "NA",
            addedDate: addedDate,
            time: new Date().toTimeString().substr(0, 8),
            estimatedAmount: 0,
            orderDetails: "NA",
            type: "Lead",
          }),
        }
      );
    } catch (error) {
      console.log(error);
    }
    alert("AllMesh team will contact you soon");
    navigation("/");
  };

  const trackEventFunction = () => {
    ReactGA.event({
      category: "Contact category",
      action: "Contact " + props.from + " Submit",
      label: "Contact label",
      value: 1,
    });
  };

  return (
    <div>
      {/* Desktop View */}
      {matches && (
        <>
          <Row>
            <Col lg={12} md={24} sm={24} style={{ width: "100%" }}>
              <Card
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + Images})`,
                  height: "80vh",
                  width: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></Card>
            </Col>
            <Col
              lg={12}
              md={24}
              sm={24}
              style={{
                width: "100%",
                backgroundColor: "#F5FDFF",
                //paddingBottom: "5%",
              }}
            >
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: "10%", width: "100%" }}
              >
                <h2
                  style={{
                    fontWeight: 700,
                    fontSize: 30,
                    width: "100%",
                    color: "#D32402",
                    textAlign: "center",
                  }}
                  className="font"
                >
                  CONTACT US
                </h2>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "5%", marginLeft: "20%" }}
                >
                  <Text style={{ fontSize: 18, fontWeight: 500 }}>NAME</Text>
                  <Text type="danger">*</Text>
                </Col>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "0.1%", marginLeft: "20%" }}
                >
                  <Input
                    style={{
                      width: "73.2%",
                      height: 40,
                      backgroundColor: "#F5FDFF",
                      borderRadius: "4px",
                      background: "#f5fdff",
                      boxShadow:
                        "-5px -5px 6px #ffffff, 5px 5px 10px rgba(49, 50, 64, 0.2)",
                    }}
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    className="inputneu"
                  />
                </Col>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "3%", marginLeft: "20%" }}
                >
                  <Text style={{ fontSize: 18, fontWeight: 500 }}>E-MAIL</Text>
                  <Text type="danger">*</Text>
                </Col>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "0.1%", marginLeft: "20%" }}
                >
                  <Input
                    style={{
                      width: "73.2%",
                      height: 40,
                      backgroundColor: "#F5FDFF",
                      borderRadius: "4px",
                      background: "#f5fdff",
                      boxShadow:
                        "-5px -5px 6px #ffffff, 5px 5px 10px rgba(49, 50, 64, 0.2)",
                    }}
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    className="inputneu"
                  />
                </Col>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "3%", marginLeft: "20%" }}
                >
                  <Text style={{ fontSize: 18, fontWeight: 500 }}>
                    MOBILE NUMBER
                  </Text>
                  <Text type="danger">*</Text>
                </Col>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "0.6%", marginLeft: "20%" }}
                >
                  <Input
                    style={{
                      width: "73.2%",
                      height: 40,
                      backgroundColor: "#F5FDFF",
                      borderRadius: "4px",
                      background: "#f5fdff",
                      boxShadow:
                        "-5px -5px 6px #ffffff, 5px 5px 10px rgba(49, 50, 64, 0.2)",
                    }}
                    value={customerPhone}
                    onChange={(e) => setCustomerPhone(e.target.value)}
                    maxLength={10}
                    className="inputneu"
                  />
                </Col>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "4.5%", marginLeft: "20%" }}
                >
                  <Button
                    style={{
                      width: "73.90%",
                      height: 40,
                      backgroundColor: "#F5FDFF",
                      background: 'linear-gradient(0deg, #f5fdff, #f5fdff), #e7ecf0',
                      border: 'none',
                      boxShadow: '-5px -5px 6px #ffffff, 5px 5px 10px rgba(49, 50, 64, 0.2)',
                      borderRadius: '10px',
                    }}
                    className="contactneu"
                    onClick={() => {
                      submit();
                      trackEventFunction();
                    }}
                  >
                    <Text style={{ color: "#D32402", fontWeight: 900 }}>
                      We will get back to you soon!
                    </Text>
                  </Button>
                </Col>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "3%", marginLeft: "20%" }}
                >
                  <Button
                    style={{
                      width: "73.90%",
                      height: 40,
                      backgroundColor: "#F5FDFF",
                      background: 'linear-gradient(0deg, #f5fdff, #f5fdff), #e7ecf0',
                      border: 'none',
                      boxShadow: '-5px -5px 6px #ffffff, 5px 5px 10px rgba(49, 50, 64, 0.2)',
                      borderRadius: '10px',
                    }}
                    onClick={() => {
                      openInNewTab(
                        "https://api.whatsapp.com/send?phone=919154805480"
                      );
                    }}
                    className="contactneu"
                  >
                    <Text
                      style={{
                        color: "#1E9A4C",
                        fontWeight: 900,
                        textAlign: "center",
                      }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/asset/WhatsApp.svg.webp"
                        }
                        alt="Ant Design"
                        width="22"
                        height="22"
                      />{" "}
                      Contact us through whatsapp
                    </Text>
                  </Button>
                </Col>
              </Col>
            </Col>
          </Row>
        </>
      )}

      {!matches && (
        <>
          <Row>
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ width: "100%", padding: 30, marginTop: 30 }}
            >
              <Card
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + Images})`,
                  height: "74vh",
                  width: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></Card>
            </Col>
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{
                marginTop: "10%",
                width: "100%",
                backgroundColor: "#F5FDFF",
              }}
            >
              <h2
                align="center"
                className="font"
                style={{
                  fontWeight: 600,
                  fontSize: 30,
                  width: "100%",
                  color: "red",
                  paddingTop: "10%",
                }}
              >
                CONTACT US
              </h2>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 50, marginLeft: 24 }}
              >
                <Text style={{ fontSize: 14, fontWeight: 500 }}>NAME</Text>
                <Text type="danger">*</Text>
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 16, marginLeft: 24 }}
              >
                <Input
                  style={{
                    width: "94%",
                    height: 40,
                    marginTop: -100,
                    backgroundColor: "#F5FDFF",
                  }}
                  className="inputneu"
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 30, marginLeft: 24 }}
              >
                <Text style={{ fontSize: 14, fontWeight: 500 }}>E-MAIL</Text>
                <Text type="danger">*</Text>
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 16, marginLeft: 24 }}
              >
                <Input
                  style={{
                    width: "94%",
                    height: 40,
                    marginTop: -100,
                    backgroundColor: "#F5FDFF",
                  }}
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                  className="inputneu"
                />
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 30, marginLeft: 24 }}
              >
                <Text style={{ fontSize: 14, fontWeight: 500 }}>
                  MOBILE NUMBER
                </Text>
                <Text type="danger">*</Text>
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 16, marginLeft: 24 }}
              >
                <Input
                  style={{
                    width: "94%",
                    height: 40,
                    marginTop: -100,
                    backgroundColor: "#F5FDFF",
                  }}
                  value={customerPhone}
                  onChange={(e) => setCustomerPhone(e.target.value)}
                  maxLength={10}
                  className="inputneu"
                />
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 45, marginLeft: 24 }}
              >
                <Button
                  style={{
                    width: "94%",
                    height: 40,
                    backgroundColor: "#F5FDFF",
                  }}
                  onClick={() => {
                    submit();
                    trackEventFunction();
                  }}
                  className="contactneu"
                >
                  <Text
                    style={{ color: "#D32402", fontWeight: 900, fontSize: 14 }}
                  >
                    We will get back to you soon!
                  </Text>
                </Button>
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 20, marginLeft: 24, paddingBottom: 60 }}
              >
                <Button
                  style={{
                    width: "94%",
                    height: 40,
                    backgroundColor: "#F5FDFF",
                  }}
                  onClick={() => {
                    openInNewTab(
                      "https://api.whatsapp.com/send?phone=919154805480"
                    );
                  }}
                  className="contactneu"
                >
                  <Text
                    style={{ color: "#1E9A4C", fontWeight: 900, fontSize: 14 }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/asset/WhatsApp.svg.webp"}
                      alt="Ant Design"
                      width="22"
                      height="22"
                    />{" "}
                    Contact us through whatsapp
                  </Text>
                </Button>
              </Col>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}
export default Contact;
