// import React from "react";
// import "./locateUs.css";
// import Map from "./map";
// import Footers from "./footer";
// import Logo from "../Images/LOGO.ai";

// const partners = [
//   {
//     location: "CHENNAI",
//     company: "New Dealers",
//     contactPerson: "Rajesh Reddy",
//     address: `H.No.2-38/4/21A, 2nd Floor ANR Complex, Doolapally,
//               Kompally Municipality, Medchal Malkajgiri Dt Telangana, Telangana`,
//   },
//   {
//     location: "INDORE",
//     company: "New Dealers",
//     contactPerson: "Arvind Banka",
//     address: `11J, 1st Floor, Athena Apartment, Mirania Gardens,
//               East Topsia, Topsia, Kolkata, West Bengal - 700046, West Bengal`,
//   },
// ];

// const handleCall = () => {
//   window.location.href = "tel:+919154805480";
// };

// function App() {
//   return (
//     <>
//       <div className="partner-cards">
//         {partners.map((partner, index) => (
//           <div className="partner-card" key={index}>
//             <div className="partner-card__header">
//               <span className="partner-card__location">{partner.location}</span>
//               <h2 className="partner-card__company">
//                 {partner.company}
//                 <br />
//                 <h1 style={{ fontSize: "25px" }}>{"Coming Soon"}</h1>
//               </h2>
//             </div>
//             <div className="partner-card__body">
//               <div className="partner-card__contact">
//                 <span className="partner-card__contact-label">
//                   CONTACT EMAIL:{" "}
//                 </span>
//                 <span className="partner-card__contact-person">
//                   cust.support@allmesh.in
//                 </span>
//               </div>
//             </div>
//             <div className="partner-card__footer">
//               <button className="partner-card__button" onClick={handleCall}>
//                 CLICK TO CONTACT PARTNER
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>
// <Map />
// <Footers />
//     </>
//   );
// }

// export default App;

import React from "react";
import "./locateUs.css";
import Footers from "./footer";
import Logo from "../Images/logo.jpg"; // Ensure the image path is correct
import Map from "./map";
import Chennai from "../Images/6.jpg";
import Coimbatore from "../Images/7.jpg";
import Bangalore from "../Images/8.jpg";
const handleCall = () => {
  window.location.href = "tel:+919154805480";
};

function App() {
  return (
    <>
      <header>
        {/* <h1>UP COMING PARTNERS SHOWROOM</h1> */}
        <h1>FRANCHISE EXPERIENCE CENTERS</h1>
      </header>
      <div className="partner-cards">
        {/* Card 1 */}
        <div className="partner-card">
          <div className="partner-card__header">
            <img src={Logo} alt="Logo" className="partner-card__logo" />
          </div>
          <div className="partner-card__body">
            <div className="partner-card__contact">
              <span className="partner-card__contact-label">
                BUSINESS NAME:{" "}
              </span>
              <span className="partner-card__contact-person">ALU SYSTEMS</span>
              <br />
              <span className="partner-card__contact-label">
                CONTACT PHONE:{" "}
              </span>
              <span className="partner-card__contact-person">
                +91 9154805480
              </span>
              <br />
              <span className="partner-card__contact-label">
                CONTACT EMAIL:{" "}
              </span>
              <span className="partner-card__contact-person">
                cust.support@allmesh.in
              </span>
              <br />
              <span className="partner-card__contact-label">Address: </span>
              <span className="partner-card__contact-person">
                <a
                  href="https://www.google.com/maps/search/?api=1&query=342,+Kanadia+Main+Rd,+Alok+Nagar,+Indore,+Madhya+Pradesh+452016"
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ textDecoration: "none", color: "inherit" }}
                >
                  342, Kanadia Main Rd, Alok Nagar, Indore, Madhya Pradesh
                  452016
                </a>
              </span>
              <br />
            </div>
          </div>

          {/* <div className="partner-card__footer">
            <button className="partner-card__button" onClick={handleCall}>
              CLICK TO CONTACT PARTNER
            </button>
          </div> */}
        </div>

        {/* Card 2 */}
        <div className="partner-card">
          <div className="partner-card__header">
            <img src={Chennai} alt="Logo" className="partner-card__logo" />
            <span className="partner-card__location">{"CHENNAI"}</span>
            <h2 className="partner-card__company">
              <h1 style={{ fontSize: "25px" }}>{"Coming Soon"}</h1>
            </h2>
          </div>
          <div className="partner-card__body">
            <div className="partner-card__contact">
              {/* <span className="partner-card__contact-label">
                BUSINESS NAME:{" "}
              </span> */}
              {/* <span className="partner-card__contact-person">XYZ SYSTEMS</span> */}
              <span className="partner-card__contact-label">
                CONTACT EMAIL:{" "}
              </span>
              <span className="partner-card__contact-person">
                cust.support@allmesh.in
              </span>
              <br />
              <span className="partner-card__contact-label">
                CONTACT PHONE:{" "}
              </span>
              <span className="partner-card__contact-person">
                +91 9154805480
              </span>
            </div>
          </div>
        </div>

        {/* Card 3 */}
        <div className="partner-card">
          <div className="partner-card__header">
            <img src={Coimbatore} alt="Logo" className="partner-card__logo" />
            <span className="partner-card__location">{"COIMBATORE"}</span>
            <h2 className="partner-card__company">
              <h1 style={{ fontSize: "25px" }}>{"Coming Soon"}</h1>
            </h2>
          </div>
          <div className="partner-card__body">
            <div className="partner-card__contact">
              {/* <span className="partner-card__contact-label">
                BUSINESS NAME:{" "}
              </span>
              <span className="partner-card__contact-person">MNO SYSTEMS</span> */}
              <span className="partner-card__contact-label">
                CONTACT EMAIL:{" "}
              </span>
              <span className="partner-card__contact-person">
                cust.support@allmesh.in
              </span>
              <br />
              <span className="partner-card__contact-label">
                CONTACT PHONE:{" "}
              </span>
              <span className="partner-card__contact-person">
                +91 9154805480
              </span>
            </div>
          </div>
        </div>

        {/* Card 4 */}
        <div className="partner-card">
          <div className="partner-card__header">
            <img src={Bangalore} alt="Logo" className="partner-card__logo" />
            <span className="partner-card__location">{"BANGALORE"}</span>
            <h2 className="partner-card__company">
              <h1 style={{ fontSize: "25px" }}>{"Coming Soon"}</h1>
            </h2>
          </div>
          <div className="partner-card__body">
            <div className="partner-card__contact">
              {/* <span className="partner-card__contact-label">
                BUSINESS NAME:{" "}
              </span>
              <span className="partner-card__contact-person">PQR SYSTEMS</span> */}
              <br />
              <span className="partner-card__contact-label">
                CONTACT EMAIL:{" "}
              </span>
              <span className="partner-card__contact-person">
                cust.support@allmesh.in
              </span>
              <br />
              <span className="partner-card__contact-label">
                CONTACT PHONE:{" "}
              </span>
              <span className="partner-card__contact-person">
                +91 9154805480
              </span>
            </div>
          </div>
        </div>
      </div>
      <Map />

      <Footers />
    </>
  );
}

export default App;
