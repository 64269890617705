import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import allmesh from "../Images/allmesh.png";
import moment from "moment";

// Create Document Component
export default function GeneratePDF(props) {
  return (
    <>
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.header}>
            <Image src={allmesh} />
            <View style={styles.headerText}>
              <View style={styles.headerTextRow}>
                <Text style={styles.headerTextLeft}>Office:</Text>
                <Text style={styles.headerTextRight}>
                  #8-3-323, Flat No. 813, 8th Level, 7th Floor, Vasavi's MPM
                  Grand, Ameerpeth, Hyderabad - 500 016.
                </Text>
              </View>
              <View style={styles.headerTextRow}>
                <Text style={styles.headerTextLeft}>Workshop:</Text>
                <Text style={styles.headerTextRight}>
                  Survey No. 44,45,46 & 47, VNR Park Lane, Other than NH-7,
                  Grampanchayat Shamshabad Village & Mandal, Ranga Reddy
                  District, Telangana - 501 218.
                </Text>
              </View>
            </View>
          </View>
          <View style={styles.topSection}>
            <View style={styles.topTextLeft}>
              <Text>To, </Text>
              <Text>{props.customerName}</Text>
              <Text>{props.customerPhone}</Text>
              <Text>{props.customerEmail}</Text>
            </View>
            <View style={styles.topTextRight}>
              <Text>Reference ID: {props.customerReferenceNumber}</Text>
              <Text>
                Enquiry Date:{" "}
                {moment(props.addedDate, "YYYY-MM-DD").format("DD-MM-YYYY")}
              </Text>
            </View>
          </View>
          <View style={styles.table}>
            <View style={styles.tableHeading}>
              <Text style={styles.name}>Product Name</Text>
              <Text style={styles.count}>Width (mm)</Text>
              <Text style={styles.count}>Height (mm)</Text>
              <Text style={styles.value}>Location</Text>
              <Text style={styles.amount}>Unit Price</Text>
              <Text style={styles.count}>Qty</Text>
              <Text style={styles.amount}>Total</Text>
            </View>

            {Object.entries(props.orderDetails).map(([key, value]) => {
              return (
                <>
                  <View style={styles.tableData}>
                    <Text style={styles.name}>
                      {value.productName}({value.productDescription})
                    </Text>
                    <Text style={styles.count}>{value.width}</Text>
                    <Text style={styles.count}>{value.height}</Text>
                    <Text style={styles.value}>{value.productLocation}</Text>
                    <Text style={styles.amount}>
                      {parseFloat(value.itemPrice).toFixed(2)}
                    </Text>
                    <Text style={styles.count}>{value.quantity}</Text>
                    <Text style={styles.amount}>
                      {parseFloat(value.itemTotal).toFixed(2)}
                    </Text>
                  </View>
                </>
              );
            })}

            <View style={styles.tableDataBlue}>
              <Text style={styles.additionalCharges}>Total:</Text>
              <Text style={styles.amount}>
                {parseFloat(props.estimatedAmount).toFixed(2)}
              </Text>
            </View>
          </View>
          <View style={styles.notes}>
            <Text>Notes:</Text>
            <Text>1. Above price is including Installation price.</Text>
            <Text>2. Above price is excluding Transpotation price.</Text>
            <Text>
              3. Lead time will be 15 days from the date of confirmed order.
            </Text>
            <Text>
              4. Extra Material and additional support sections will be charged
              extra
            </Text>
            <Text>
              5. Invoice will be raised based on the unit price of each window /
              door as given in the offer. - The average rate per sft has no
              relevance while making the invoice.
            </Text>
            <Text>
              6. The above price is for standard colours, wooden finish
              /customised colours will be charged extra
            </Text>
            <Text>
              7. This quotation is Prepared with the avarage SFT price if there
              are any changes in count of openings - price may differ.
            </Text>
            <Text>8. Minium chargeable SFT Area 10 Sft</Text>
            <Text>9. Pricing may differ upon the actual measurement.</Text>
          </View>
          <View style={styles.belowDetailsBold}>
            <Text>PAYMENT:</Text>
            <Text style={styles.leftPadding}>
              50% advance along with Purchase order/Work order/Confirmation
            </Text>
            <Text style={styles.leftPadding}>
              50% against goods receipt on site.
            </Text>
          </View>
          <View style={styles.belowDetails}>
            <Text>Our Bank Details:</Text>
            <Text style={styles.leftPadding}>
              A/c Name : AllMesh Secure Solutions LLP
            </Text>
            <Text style={styles.leftPadding}>Bank Name : IDBI BANK</Text>
            <Text style={styles.leftPadding}>A/c No : 0426102000016409</Text>
            <Text style={styles.leftPadding}>Branch : Ameerpet</Text>
            <Text style={styles.leftPadding}>
              IFSC CODE / RTGS :- IBKL0000426
            </Text>
          </View>
          <View style={styles.belowDetails}>
            <Text>
              1. The above cost is for standard aluminium frame colour (Black,
              White, Honey Gold, Mocha Brown).
            </Text>
            <Text>
              2. Any specific/Customized colour cost will be given on request.{" "}
            </Text>
            <Text>
              3. Any other Customized size other than above, to be confirmed
              through RFQ.
            </Text>
            <Text>
              4. Electrical connection to be provided at site wherever
              necessary.
            </Text>
            <Text>
              5. Any civil defects to be rectified by your person at your cost.
            </Text>
            <Text>6. Quotation validity: 15 days</Text>
          </View>
          <View style={styles.belowDetailsBold}>
            <Text>
              Thanking you and assuring you of our best services at all times.
            </Text>
            <Text>Yours faithfully,</Text>
            <Text>For AllMesh Secure Solutions LLP</Text>
          </View>
        </Page>
      </Document>
    </>
  );
}

// Create styles
const styles = StyleSheet.create({
  page: { padding: 20 },
  header: {
    flexDirection: "row",
    alignContent: "space-between",
    marginLeft: 40,
    marginRight: 40,
    borderBottom: "1px solid black",
    height: 70,
  },
  headerText: {
    paddingTop: 10,
    paddingLeft: 10,
    fontSize: 9,
    alignContent: "flex-end",
    width: 400,
    color: "grey",
  },
  headerTextRow: {
    flexDirection: "row",
  },
  headerTextLeft: {
    width: 70,
  },
  headerTextRight: {
    width: 330,
  },
  topSection: {
    justifyContent: "space-between",
    alignItems: "center",
    flexDirection: "row",
    marginLeft: 20,
    marginRight: 20,
    marginTop: 20,
    padding: 10,
    fontSize: 10,
    border: "1px solid black",
  },
  topTextLeft: {
    textAlign: "left",
    width: "250",
    paddingBottom: 5,
  },
  topTextRight: {
    textAlign: "left",
    width: "200",
    paddingBottom: 5,
  },
  table: {
    borderRight: "1px solid black",
    borderLeft: "1px solid black",
    marginLeft: 20,
    marginRight: 20,
  },
  tableHeading: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    paddingTop: 2,
    paddingBottom: 2,
    backgroundColor: "#87CEEB",
  },
  tableData: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
  },
  tableDataBlue: {
    justifyContent: "space-around",
    alignItems: "center",
    borderBottom: "1px solid black",
    flexDirection: "row",
    fontSize: 10,
    backgroundColor: "#87CEEB",
  },
  name: {
    padding: 2,
    width: 150,
    textAlign: "left",
  },
  count: {
    padding: 2,
    width: 55,
    textAlign: "center",
  },
  value: {
    padding: 2,
    width: 80,
    textAlign: "center",
  },
  amount: {
    padding: 2,
    width: 80,
    textAlign: "right",
  },
  additionalCharges: {
    width: 530,
    textAlign: "right",
  },
  notes: {
    marginLeft: 20,
    marginRight: 20,
    marginBottom: 10,
    padding: 10,
    color: "red",
    fontSize: 10,
  },
  belowDetails: {
    marginLeft: 20,
    marginRight: 20,
    padding: 10,
    fontSize: 10,
    fontFamily: "Times-Roman",
  },
  belowDetailsBold: {
    marginLeft: 20,
    marginRight: 20,
    padding: 10,
    fontSize: 10,
    fontFamily: "Times-Bold",
  },
  leftPadding: {
    paddingLeft: 20,
  },
});
