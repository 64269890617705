import React, { useEffect, useState, useRef } from "react";
import "../Styles/Sticky.css";

function SideStickyBar() {
  const [sideBar, setSideBar] = useState(false);
  const [modal, setModal] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const showBar = () => {
    if (window.scrollY >= 150) {
      setSideBar(true);
    } else {
      setSideBar(false);
    }
  };
  const openInNewTab = url => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  window.addEventListener("scroll", showBar);

  return (
    <div>
      <div
        className={
          sideBar
            ? "whatsappsideBarAfterScroll"
            : "whatsappsideBarMainContainer"
        }
      >
        {matches && (
          <>
            {" "}
            <a
             // href="https://api.whatsapp.com/send?phone=919154805480"
             onClick={() => {
              openInNewTab('https://api.whatsapp.com/send?phone=919154805480')
              }}
              className="stickButttons whatsappSideButton"
              style={{ fontWeight: 600, fontSize: 18 }}
            >
              <img
                src={process.env.PUBLIC_URL + "/asset/WhatsApp.svg.webp"}
                alt="Ant Design"
                width="60"
                height="60"
              />
              &nbsp;&nbsp;&nbsp;&nbsp; WhatsApp &nbsp;&nbsp;&nbsp;
            </a>
          </>
        )}
        {!matches && (
          <a
           // href="https://api.whatsapp.com/send?phone=919154805480"
           onClick={() => {
            openInNewTab('https://api.whatsapp.com/send?phone=919154805480')
            }}
            className="stickButttons whatsappSideButton"
            style={{ fontWeight: 600, fontSize: 12 }}
          >
            <img
              src={process.env.PUBLIC_URL + "/asset/WhatsApp.svg.webp"}
              alt="Ant Design"
              width="25"
              height="25"
            />
            &nbsp;&nbsp;&nbsp; WhatsApp &nbsp;&nbsp;&nbsp;
          </a>
        )}
      </div>
    </div>
  );
}
export default SideStickyBar;
