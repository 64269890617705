import React, { useState, useEffect } from "react";
import "../App.less";
import { useNavigate } from "react-router-dom";
import { Col, Button, Card, Typography, Descriptions, Menu } from "antd";
import environment from "../configure";
import Footers from "./footer";
import {
  Box,
  Container,
  Row,
  Column,
  FooterLink,
  Heading,
} from "../Styles/footer";
import Contact from "./contact";
import Images from "../Images/Quatation.png";

const { Title, Text } = Typography;

function AboutUs(props) {
  const navigation = useNavigate();
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1169px)").matches
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <div>
      <Box>
        <Container>
          <Row>
            <Column>
              <Heading>
                {" "}
                <img
                  src={process.env.PUBLIC_URL + "/asset/allmesh.png"}
                  alt="Ant Design"
                  style={{ marginTop: -18, paddingBottom: "10%" }}
                />
                <br></br>
                CONTACT US
              </Heading>
              <FooterLink>
                {" "}
                <a
                  onClick={() => {
                    openInNewTab(
                      "https://www.google.com/maps/place/AllMesh/@16.4960762,77.9599088,8.64z/data=!4m19!1m13!4m12!1m4!2m2!1d80.2476727!2d13.0610295!4e1!1m6!1m2!1s0x3bcb912c8cf30361:0xeded66e8cafb08bf!2sAllMesh,+Ameerpet,+Hyderabad,+Telangana!2m2!1d78.4449227!2d17.4341521!3m4!1s0x3bcb912c8cf30361:0xeded66e8cafb08bf!8m2!3d17.4341521!4d78.4449227"
                    );
                  }}
                >
                  <p
                    className="font"
                    style={{ fontWeight: 500, color: "white", fontSize: 18 }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/asset/Footer2.png"}
                      alt="Ant Design"
                    />{" "}
                    &nbsp; Location
                  </p>
                </a>
              </FooterLink>
              <FooterLink href="#">
                {" "}
                <p
                  className="font"
                  style={{ fontWeight: 500, color: "white", fontSize: 18 }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/asset/Footer1.png"}
                    alt="Ant Design"
                  />{" "}
                  &nbsp; +91 9154805480
                </p>
              </FooterLink>
              <FooterLink href="#">
                {" "}
                <p
                  className="font"
                  style={{ fontWeight: 500, color: "white", fontSize: 16 }}
                >
                  <img
                    src={process.env.PUBLIC_URL + "/asset/Footer3.png"}
                    alt="Ant Design"
                  />{" "}
                  cust.support@allmesh.in
                </p>
              </FooterLink>
            </Column>
            <Column>
              <Heading>OUR PRODUCTS</Heading>
              <FooterLink
                onClick={() => {
                  navigation("/products");
                }}
              ></FooterLink>
              <FooterLink
                onClick={() => {
                  navigation("/products", { state: { data: "Doors" } });
                }}
              >
                Doors
              </FooterLink>
              <FooterLink
                onClick={() => {
                  navigation("/products", { state: { data: "Windows" } });
                }}
              >
                Windows
              </FooterLink>

              <FooterLink
                onClick={() => {
                  navigation("/products", { state: { data: "Balcony" } });
                }}
              >
                Balcony
              </FooterLink>
              <FooterLink
                onClick={() => {
                  navigation("/products", { state: { data: "Security" } });
                }}
              >
                Security
              </FooterLink>
            </Column>
            <Column>
              <Heading>CONNECT WITH ALLMESH</Heading>
              <FooterLink>
                <p>
                  <a
                    onClick={() => {
                      openInNewTab(
                        "https://m.facebook.com/people/AllMesh-Secure-Solution/100068325666464/"
                      );
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/asset/Footer8.png"}
                      alt="Ant Design"
                      style={{ padding: 10 }}
                    />
                  </a>
                  <a
                    onClick={() => {
                      openInNewTab(
                        "https://www.instagram.com/allmeshsolutions/?next=%2Foauth%2Fauthorize%2F%3Fapp_id%3D17951132926087090%26redirect_uri%3Dhttps%3A%2F%2Fwww.facebook.com%2Fpage%2Finstagram%2Foauthlink%2F%26response_type%3Dcode%26state%3D%257B%2522entry_point%2522%3A%2522profile_plus_settings%2522%2C%2522flow_type%2522%3A%2522linking%2522%2C%2522link_flow_source%2522%3A%2522comet%2522%2C%2522nonce%2522%3A%2522m0HVmV4TtXbHn5H6%2522%2C%2522require_professional%2522%3Afalse%257D%26logger_id%3D8268b085-c387-4278-b5b4-4409f93bab60"
                      );
                    }}
                  >
                    <img
                      src={process.env.PUBLIC_URL + "/asset/Footer5.png"}
                      alt="Ant Design"
                      style={{ padding: 15 }}
                    />
                  </a>
                </p>
              </FooterLink>
              <Heading>POLICY</Heading>
              <FooterLink>Privacy Policy</FooterLink>
              <FooterLink
                onClick={() => {
                  navigation("/condition");
                }}
              >
                Terms and Conditions
              </FooterLink>
            </Column>
          </Row>
        </Container>
      </Box>
    </div>
  );
}

export default AboutUs;
