import React, { useState, useEffect } from "react";
import {
  Row,
  Typography,
  Card,
  Col,
  Button,
  Popover,
  Radio,
  InputNumber,
  message,
  Select,
} from "antd";
import "../App.less";
import Images from "../Images/Estimation.png";
import { connect } from "react-redux";
import Footers from "./footer";
import environment from "../configure";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";
const { Title, Text } = Typography;
const { Option } = Select;

function Estimate(props) {
  const navigation = useNavigate();
  const [height, setHeight] = useState("");
  const [width, setWidth] = useState("");
  const [panelCount, setPanelCount] = useState(2);
  const [quantity, setQuantity] = useState("");
  const [allProducts, setAllProducts] = useState([]);
  const [productType, setProductType] = useState("");
  const [productSubType, setProductSubType] = useState("");
  const [productLocation, setProductLocation] = useState("Doors");
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [profileArray, setProfileArray] = useState([]);
  const [allComponents, setAllComponents] = useState([]);
  const [color, setColor] = useState("Basic");
  const [cartVisbile, setCartVisible] = useState(false);
  const [cartVisbile1, setCartVisible1] = useState(false);
  const [uom, setUom] = useState("");
  //const [size, setSize] = useState("");
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));
    async function fetchProductDetails() {
      try {
        var fetchedDetails = await fetch(
          environment.apiGateway + "fetchProductDetails",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: "Group",
            }),
          }
        );
        var fetchedRespone = await fetchedDetails.json();
        setAllProducts(fetchedRespone.Items);
      } catch (err) {
        alert(err);
        return err;
      }

      try {
        var fetchedDetails = await fetch(
          environment.apiGateway + "fetchProductDetails",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: "Component",
            }),
          }
        );
        var fetchedRespone = await fetchedDetails.json();
        setAllComponents(fetchedRespone.Items);
      } catch (err) {
        alert(err);
        return err;
      }
    }
    fetchProductDetails();
  }, []);

  const combinationUtil = (arr, data, start, end, index, r) => {
    {
      let temp = [];
      if (index == r) {
        for (let j = 0; j < r; j++) temp.push(data[j]);
        profileArray.push(temp);
      }
      for (let i = start; i <= end && end - i + 1 >= r - index; i++) {
        data[index] = arr[i];
        combinationUtil(arr, data, i + 1, end, index + 1, r);
      }
    }
  };
  const printCombination = (arr, n, r) => {
    {
      let data = new Array(r);
      combinationUtil(arr, data, 0, n - 1, 0, r);
    }
  };

  const hide = () => {
    setCartVisible(false);
  };

  const trackEventFunction = (event) => {
    ReactGA.event({
      category: "Estimation category",
      action: "Estimation " + event,
      label: "Estimation label",
      value: 1,
    });
  };

  const addToCart = async () => {
    var numReg = /^[0-9]*$/;
    if (productLocation == "")
      return message.error("Please select product location");
    if (productType == "") return message.error("Please select product type");
    if (width == "") return message.error("Please enter width in mm");
    if (numReg.test(width) === false)
      return message.error("Please enter valid width");
    if (height == "") return message.error("Please enter height in mm");
    if (numReg.test(height) === false)
      return message.error("Please enter valid height");
    if (quantity == "") return message.error("Please enter quantity");
    if (numReg.test(quantity) === false)
      return message.error("Please enter valid quantity");
    if (color == "") return message.error("Please select color");
    if (selectedGroup.length == 0) return message.error("Select a product");

    //calculate price

    let widthinmm = "";
    let heightinmm = "";

    if (uom == "ft") widthinmm = width * 304.8;
    else if (uom == "m") widthinmm = width * 1000;
    else if (uom == "inch") widthinmm = width * 25.4;
    else widthinmm = width;

    if (uom == "ft") heightinmm = height * 304.8;
    else if (uom == "m") heightinmm = height * 1000;
    else if (uom == "inch") heightinmm = height * 25.4;
    else heightinmm = height;

    let sideArray = [];
    let widthArray = [];
    let heightArray = [];
    let profile12 = 0;
    let profile7 = 0;
    let profile7Present = false;
    let profile12Present = false;

    allComponents.map(async (item) => {
      if (Object.keys(selectedGroup.componentList).includes(item.id)) {
        if (item.calBasedOn) {
          if (item.calBasedOn == "7 feet") profile7Present = true;
          else if (item.calBasedOn == "12 feet") profile12Present = true;
        }
      }
    });

    let widthSide = Math.round((widthinmm / panelCount) * 0.00328084);
    let heightSide = Math.round(heightinmm * 0.00328084);
    while (widthSide > 12) {
      widthSide = widthSide - 12;
      profile12 = profile12 + panelCount * 2;
    }
    while (heightSide > 12) {
      heightSide = heightSide - 12;
      profile12 = profile12 + panelCount * 2;
    }
    widthArray = Array(panelCount * 2).fill(widthSide);
    heightArray = Array(panelCount * 2).fill(heightSide);

    sideArray = widthArray.concat(heightArray);

    setProfileArray([]);
    for (let i = 1; i <= sideArray.length; i++)
      await printCombination(sideArray, sideArray.length, i);

    if (
      (widthinmm * 0.00328084 > 7 || heightinmm * 0.00328084 > 7) &&
      profile12Present
    )
      for (let total = 12; total > 7; total--) {
        if (sideArray.length > 0) {
          profileArray.map((value) => {
            var sum = value.reduce(function (a, b) {
              return a + b;
            }, 0);
            let temp = [...sideArray];
            let findElements = true;
            value.map((item) => {
              if (temp.includes(item)) {
                const index1 = temp.indexOf(item);
                if (index1 > -1) temp.splice(index1, 1);
                return;
              } else findElements = false;
            });

            if (findElements && sum == total) {
              value.map((item) => {
                const index1 = sideArray.indexOf(item);
                if (index1 > -1) sideArray.splice(index1, 1);
              });
              profile12++;
            }
          });
        }
      }
    else if (profile7Present)
      for (let total = 7; total > 0; total--) {
        if (sideArray.length > 0) {
          profileArray.map((value) => {
            var sum = value.reduce(function (a, b) {
              return a + b;
            }, 0);
            let temp = [...sideArray];
            let findElements = true;
            value.map((item) => {
              if (temp.includes(item)) {
                const index1 = temp.indexOf(item);
                if (index1 > -1) temp.splice(index1, 1);
                return;
              } else findElements = false;
            });

            if (findElements && sum == total) {
              value.map((item) => {
                const index1 = sideArray.indexOf(item);
                if (index1 > -1) sideArray.splice(index1, 1);
              });
              profile7++;
            }
          });
        }
      }

    // alert(profile12 + " " + profile7);

    if (profile12 == 0 && profile7 == 0) {
      for (let total = 12; total > 0; total--) {
        if (sideArray.length > 0) {
          profileArray.map((value) => {
            var sum = value.reduce(function (a, b) {
              return a + b;
            }, 0);
            let temp = [...sideArray];
            let findElements = true;
            value.map((item) => {
              if (temp.includes(item)) {
                const index1 = temp.indexOf(item);
                if (index1 > -1) temp.splice(index1, 1);
                return;
              } else findElements = false;
            });

            if (findElements && sum == total) {
              value.map((item) => {
                const index1 = sideArray.indexOf(item);
                if (index1 > -1) sideArray.splice(index1, 1);
              });
              profile12++;
              profile7++;
            }
          });
        }
      }
    }

    var groupSubTotal = 0;
    var marginAmount = 0;
    var groupTotal = 0;
    var areaInSqFt = widthinmm * 0.00328084 * heightinmm * 0.00328084;
    var parameter =
      ((parseFloat(widthinmm) + parseFloat(heightinmm)) * 2) / 1000;

    await Promise.all(
      allComponents.map(async (item) => {
        if (Object.keys(selectedGroup.componentList).includes(item.id)) {
          selectedGroup.componentList[item.id].productName = item.productName;
          selectedGroup.componentList[item.id].productPrice = item.productPrice;
          selectedGroup.componentList[item.id].productExcel = item.productExcel;
          if (item.calBasedOn)
            selectedGroup.componentList[item.id].calBasedOn = item.calBasedOn;
          if (item.minValue)
            selectedGroup.componentList[item.id].minValue = item.minValue;
          if (item.unitPerPanel)
            selectedGroup.componentList[item.id].unitPerPanel =
              item.unitPerPanel;

          if (item.calBasedOn) {
            if (item.calBasedOn == "7 feet")
              selectedGroup.componentList[item.id].count = profile7;
            else if (item.calBasedOn == "12 feet")
              selectedGroup.componentList[item.id].count = profile12;
            else if (item.calBasedOn === "panel count")
              selectedGroup.componentList[item.id].count =
                parseFloat(item.unitPerPanel) * parseFloat(panelCount);
            else if (item.calBasedOn === "parameter")
              selectedGroup.componentList[item.id].count = (
                parseFloat(parameter) * parseFloat(panelCount)
              ).toFixed(2);
            else if (item.calBasedOn === "width")
              selectedGroup.componentList[item.id].count = (
                (parseFloat(widthinmm) / 1000) *
                parseFloat(panelCount)
              ).toFixed(2);
            else if (item.calBasedOn === "height")
              selectedGroup.componentList[item.id].count = (
                (parseFloat(heightinmm) / 1000) *
                parseFloat(panelCount)
              ).toFixed(2);
            else if (item.calBasedOn === "unit")
              selectedGroup.componentList[item.id].count = 1;
            else if (item.calBasedOn === "thread")
              selectedGroup.componentList[item.id].count = (
                ((parseFloat(widthinmm) + parseFloat(heightinmm)) *
                  (parseFloat(heightinmm) * 0.00328084)) /
                  1000 +
                5
              ).toFixed(2);
            else if (item.calBasedOn === "sq feet") {
              let count = 0;
              if (productLocation === "Doors") {
                if (areaInSqFt <= 21) count = 21;
                else if (areaInSqFt > 21 && areaInSqFt <= 24) count = 24;
                else if (areaInSqFt > 24 && areaInSqFt <= 28) count = 28;
                else if (areaInSqFt > 28 && areaInSqFt <= 32) count = 32;
                else if (areaInSqFt > 32) count = areaInSqFt;
                selectedGroup.componentList[item.id].count =
                  parseFloat(count).toFixed(2);
              } else if (productLocation === "Windows") {
                if (areaInSqFt <= 9) count = 9;
                else if (areaInSqFt > 9 && areaInSqFt <= 12) count = 12;
                else if (areaInSqFt > 12 && areaInSqFt <= 16) count = 16;
                else if (areaInSqFt > 16 && areaInSqFt <= 20) count = 20;
                else if (areaInSqFt > 20 && areaInSqFt <= 25) count = 25;
                else if (areaInSqFt > 25 && areaInSqFt <= 30) count = 30;
                else if (areaInSqFt > 30 && areaInSqFt <= 36) count = 36;
                else if (areaInSqFt > 36) count = areaInSqFt;
                selectedGroup.componentList[item.id].count =
                  parseFloat(count).toFixed(2);
              } else
                selectedGroup.componentList[item.id].count =
                  parseFloat(areaInSqFt).toFixed(2);
            } else selectedGroup.componentList[item.id].count = 1;
          } else selectedGroup.componentList[item.id].count = 1;

          if (selectedGroup.componentList[item.id].productExcel != "") {
            let filename = selectedGroup.componentList[item.id].productExcel;
            try {
              const response = await fetch(
                environment.apiGateway + "fetchPrice",
                {
                  method: "POST",
                  headers: { "Content-Type": "application/json" },
                  body: JSON.stringify({
                    width: Math.ceil(widthinmm / 100) * 100,
                    height: Math.ceil(heightinmm / 100) * 100,
                    fileName: filename.substring(filename.lastIndexOf("/") + 1),
                  }),
                }
              );
              const responseJson = await response.json();
              if (responseJson) {
                selectedGroup.componentList[item.id].productPrice =
                  parseFloat(responseJson);
                selectedGroup.componentList[item.id].componentTotal =
                  parseFloat(selectedGroup.componentList[item.id].count) *
                  parseFloat(responseJson);
              }
            } catch (error) {
              console.error(error);
            }
          } else {
            selectedGroup.componentList[item.id].componentTotal =
              parseFloat(selectedGroup.componentList[item.id].count) *
              parseFloat(selectedGroup.componentList[item.id].productPrice);
          }

          groupSubTotal =
            parseFloat(groupSubTotal) +
            parseFloat(selectedGroup.componentList[item.id].componentTotal);
          console.log(
            selectedGroup.componentList[item.id].productName +
              " " +
              selectedGroup.componentList[item.id].count +
              " " +
              selectedGroup.componentList[item.id].productPrice +
              " " +
              selectedGroup.componentList[item.id].componentTotal
          );
        }
      })
    );

    groupSubTotal = parseFloat(groupSubTotal) * parseFloat(quantity);
    var colorCharges =
      parseFloat(quantity) *
      parseFloat(props.common.color[color].pricePerFeet) *
      parseFloat(areaInSqFt);

    groupSubTotal = parseFloat(groupSubTotal) + parseFloat(colorCharges);

    marginAmount =
      (parseFloat(selectedGroup.productMargin) / 100) *
      parseFloat(groupSubTotal);

    groupTotal = parseFloat(groupSubTotal) + parseFloat(marginAmount);

    console.log("groupTotal " + groupTotal);
    var price = parseFloat(groupTotal) / parseFloat(quantity);

    props.addToBill({
      productType: productType,
      productLocation: productLocation,
      width: widthinmm,
      height: heightinmm,
      panelCount: panelCount,
      item: selectedGroup,
      total: parseFloat(groupTotal).toFixed(2),
      areaInSqFt: parseFloat(areaInSqFt).toFixed(2),
      subTotal: parseFloat(groupSubTotal).toFixed(2),
      productMargin: selectedGroup.productMargin,
      marginAmount: parseFloat(marginAmount).toFixed(2),
      color: color,
      price: parseFloat(price).toFixed(2),
      quantity: quantity,
      colorCharges: colorCharges,
      selectedColorCharge: parseFloat(props.common.color[color].pricePerFeet),
    });
    setCartVisible(true);
    setProductType("");
    setProductLocation("");
    setProductSubType("");
    setWidth("");
    setHeight("");
    setColor("");
    setQuantity("");
    setPanelCount("");
    setSelectedGroup([]);
  };

  const selectAfter = (
    <Select defaultValue="mm" className="select-after">
      <Option value="mm">
        <a
          onClick={() => {
            setUom("mm");
          }}
        >
          mm
        </a>
      </Option>
      <Option value="ft">
        <a
          onClick={() => {
            setUom("ft");
          }}
        >
          ft
        </a>
      </Option>
      <Option value="m">
        <a
          onClick={() => {
            setUom("m");
          }}
        >
          m
        </a>
      </Option>
      <Option value="inch">
        <a
          onClick={() => {
            setUom("inch");
          }}
        >
          inch
        </a>
      </Option>
    </Select>
  );

  return (
    <>
      <div className="fill-window" style={{ backgroundColor: "#F5FDFF" }}>
        {!matches && (
          <Col lg={24} md={24} sm={{ span: 24 }}>
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ width: "100%", backgroundColor: "#F5FDFF" }}
            >
              <h2
                style={{ textAlign: "center", marginTop: 25, fontWeight: 900 }}
                className="font"
              >
                GET AN ESTIMATE
              </h2>
            </Col>
            <div style={{ backgroundColor: "#F5FDFF" }}>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 5, width: "100%" }}
              >
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 20,
                    marginLeft: 20,
                    width: "100%",
                  }}
                  className="font"
                >
                  PRODUCT DETAILS
                </Text>
                <img
                  src={process.env.PUBLIC_URL + "/asset/Pagination.png"}
                  alt="Ant Design"
                  width="50"
                  height="20"
                  style={{ marginLeft: 93, marginTop: -10 }}
                />
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 30, marginLeft: 20, width: "100%" }}
              >
                {Object.entries(props.common.productLocationTypeMap).map(
                  ([key, value]) => {
                    return (
                      <>
                        <Button
                          value={key}
                          style={{
                            width: "23%",
                            height: 38,
                            backgroundColor:
                              productLocation == key ? "#D32402" : "#FFFFFF",
                            borderColor: "#F5FDFF",
                            borderRadius: 8,
                            textAlign: "center",
                          }}
                          onClick={() => {
                            setProductLocation(key);
                            setProductType("");
                            setProductSubType("");
                            trackEventFunction(key);
                          }}
                        >
                          <p
                            style={{
                              color: productLocation == key ? "white" : "black",
                              fontWeight: 900,
                              fontSize: 14,
                              textAlign: "center",
                              padding: 4,
                            }}
                            className="font"
                          >
                            {key}
                          </p>
                        </Button>
                      </>
                    );
                  }
                )}
              </Col>
              {productLocation !== "" && (
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: 36, marginLeft: 10, width: "100" }}
                >
                  {Object.entries(
                    props.common.productLocationTypeMap[productLocation].value
                  ).map(([key, value]) => {
                    return (
                      <>
                        <Button
                          value={key}
                          style={{
                            marginLeft: 10,
                            width: 113,
                            height: 60,
                            backgroundColor: "#F5FDFF",
                            marginBottom: 10,
                          }}
                          className="neu1"
                          onClick={(e) => {
                            setProductType(key);
                            setProductSubType("");
                            trackEventFunction(key);
                          }}
                        >
                          <img
                            src={value.image}
                            alt="Ant Design"
                            width="67"
                            height="14"
                            style={{
                              marginRight: "auto",
                              marginLeft: "auto",
                              display: "block",
                              paddingTop: 2,
                            }}
                          />
                          <p
                            style={{
                              fontWeight: 900,
                              fontSize: 12,
                              textAlign: "center",
                              color: productType == key ? "#C3371C" : "black",
                            }}
                            className="font"
                          >
                            {key}
                          </p>
                        </Button>
                      </>
                    );
                  })}
                </Col>
              )}
              {productType !== "" &&
                Object.entries(
                  props.common.productLocationTypeMap[productLocation].value[
                    productType
                  ].value
                ).length > 0 && (
                  <Col
                    lg={24}
                    md={24}
                    sm={24}
                    style={{ marginTop: 36, marginLeft: 10, width: "100%" }}
                  >
                    {Object.entries(
                      props.common.productLocationTypeMap[productLocation]
                        .value[productType].value
                    ).map((value) => {
                      return (
                        <>
                          <Button
                            value={value[1].name}
                            style={{
                              marginLeft: 10,
                              width: 113,
                              height: 66,

                              marginBottom: 20,
                              backgroundColor: "#F5FDFF",
                            }}
                            className="neu1"
                            onClick={(e) => {
                              setProductSubType(value[1].name);
                              trackEventFunction(value[1].name);
                            }}
                          >
                            <img
                              src={value[1].image}
                              alt="Ant Design"
                              width="79"
                              height="26"
                              style={{
                                marginRight: "auto",
                                marginLeft: "auto",
                                display: "block",
                              }}
                            />

                            <p
                              style={{
                                fontWeight: 900,
                                fontSize: 12,
                                textAlign: "center",
                                color:
                                  productSubType == value[1].name
                                    ? "#C3371C"
                                    : "black",
                              }}
                              className="font"
                            >
                              {value[1].name}
                            </p>
                          </Button>
                        </>
                      );
                    })}
                  </Col>
                )}

              {allProducts.filter(
                (item) =>
                  item.productType.includes(productType) &&
                  item.productLocation.includes(productLocation)
              ).length > 0 && (
                <>
                  <Col
                    lg={24}
                    md={24}
                    sm={24}
                    style={{ marginTop: 35, marginLeft: 18 }}
                  >
                    <Text style={{ fontSize: 14, fontWeight: 500 }}>Width</Text>
                    <Text
                      style={{ fontSize: 14, fontWeight: 500, marginLeft: 140 }}
                    >
                      Height
                    </Text>
                  </Col>
                  <Col
                    lg={24}
                    md={24}
                    sm={24}
                    style={{ marginTop: 4, marginLeft: 25 }}
                  >
                    <InputNumber
                      addonAfter={selectAfter}
                      style={{
                        width: "43%",
                        height: 40,
                        marginLeft: -8,
                        borderColor: "#353535",
                      }}
                      onChange={(value) => setWidth(value)}
                      value={width}
                    />
                    <InputNumber
                      addonAfter={selectAfter}
                      style={{
                        width: "43%",
                        height: 40,
                        marginLeft: 13,
                        borderColor: "#353535",
                      }}
                      onChange={(value) => setHeight(value)}
                      value={height}
                    />
                  </Col>

                  <Col style={{ marginTop: 35, marginLeft: 18 }}>
                    <Text style={{ fontSize: 14, fontWeight: 500 }}>
                      Quantity
                    </Text>
                  </Col>

                  <Col style={{ marginTop: 4, marginLeft: 25 }}>
                    <InputNumber
                      style={{
                        width: "43%",
                        height: 35,
                        borderColor: "#353535",
                        marginLeft: -8,
                      }}
                      onChange={(value) => setQuantity(value)}
                      value={quantity}
                    />
                  </Col>
                </>
              )}
              <Col style={{ marginTop: 35, marginLeft: 16, width: "100%" }}>
                {(allProducts.filter(
                  (item) =>
                    item.productType.includes(productType) &&
                    item.productLocation.includes(productLocation)
                ).length > 0 &&
                  allProducts
                    .filter(
                      (item) =>
                        item.productType.includes(productType) &&
                        item.productLocation.includes(productLocation)
                    )
                    .map((data, key) => {
                      return (
                        <>
                          <Button
                            style={{
                              width: "41.5%",
                              height: 40,
                              borderRadius: 4,
                              marginRight: 13,
                              marginBottom: 20,
                              borderColor: "#353535",
                            }}
                            onClick={() => setSelectedGroup(data)}
                          >
                            <Text className="overflow" style={{ width: "80%" }}>
                              {data.productName}
                            </Text>
                          </Button>
                        </>
                      );
                    })) || (
                  <Text type="danger" style={{ width: "80%" }}>
                    Please contact AllMesh team for pricing
                  </Text>
                )}
                <Col
                  style={{
                    marginTop: 50,
                    marginLeft: 1,
                    width: "100%",
                    paddingBottom: 65,
                  }}
                >
                  <Popover
                    placement="bottom"
                    content={
                      <Row>
                        <Col span={24}>
                          <Text type="danger">
                            Your Product is added to estimation
                          </Text>
                          <br></br>
                          <br></br>
                          <Button
                            onClick={() => hide()}
                            style={{ left: 170, backgroundColor: "red" }}
                          >
                            <p style={{ color: "white", fontWeight: 500 }}>
                              Ok
                            </p>
                          </Button>
                        </Col>
                      </Row>
                    }
                    trigger="click"
                    visible={cartVisbile}
                  >
                    <Button
                      style={{
                        width: "38%",
                        height: 40,
                        backgroundColor: "#D32402",
                        borderRadius: 4,
                      }}
                      onClick={() => {
                        addToCart();
                        trackEventFunction("Add to cart");
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontWeight: 900,
                          fontSize: 14,
                        }}
                      >
                        Add to Estimation
                      </Text>
                    </Button>
                  </Popover>

                  {Object.entries(props.bill.items).length > 0 && (
                    <Button
                      style={{
                        width: "38%",
                        height: 40,
                        backgroundColor: "red",
                        borderRadius: 4,
                        marginLeft: 43,
                      }}
                      onClick={() => {
                        navigation("/custContactDetails");
                        trackEventFunction("Add customer details");
                      }}
                    >
                      <Text
                        style={{
                          color: "white",
                          fontWeight: 900,
                          fontSize: 14,
                        }}
                      >
                        View Estimate
                      </Text>
                    </Button>
                  )}
                </Col>
              </Col>
            </div>
          </Col>
        )}
        {matches && (
          <Row>
            <Col lg={12} md={24} sm={24} style={{ width: "100%" }}>
              <Card
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + Images})`,
                  height: "90vh",
                  width: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <Col lg={24} md={24} sm={24}>
                  <Title
                    style={{
                      color: "white",
                      marginTop: 250,
                      fontWeight: 600,
                      fontSize: 40,
                      marginLeft: 60,
                      lineHeight: 1,
                    }}
                    className="font"
                  >
                    Get an Estimate
                  </Title>
                </Col>
                <Col lg={16} md={24} sm={24}>
                  <Title
                    style={{
                      color: "white",
                      fontSize: 24,
                      marginTop: 1,
                      fontWeight: 500,
                      marginLeft: 60,
                      lineHeight: 1.5,
                    }}
                    className="font"
                  >
                    Having Doubts? Get your{" "}
                    <Text type="danger">Estimation</Text> Now!
                  </Title>
                </Col>
              </Card>
            </Col>

            <Col lg={12} md={24} sm={24} style={{ backgroundColor: "#F5FDFF" }}>
              <Col lg={24} md={24} sm={24} style={{ marginTop: 20 }}>
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 30,
                    marginLeft: 70,
                    color: "#353535",
                  }}
                  className="font"
                >
                  PRODUCT DETAILS
                </Text>
                <img
                  src={process.env.PUBLIC_URL + "/asset/Pagination.png"}
                  alt="Ant Design"
                  width="50"
                  height="20"
                  style={{ marginLeft: "24%" }}
                />
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 20, marginLeft: 70 }}
              >
                {Object.entries(props.common.productLocationTypeMap).map(
                  ([key, value]) => {
                    return (
                      <>
                        <Button
                          value={key}
                          style={{
                            width: 133,
                            height: 38,
                            backgroundColor:
                              productLocation == key ? "#D32402" : "#FFFFFF",
                            borderColor: "#E5EBEF",
                            borderRadius: 8,
                          }}
                          onClick={() => {
                            setProductLocation(key);
                            setProductType("");
                            setProductSubType("");
                            trackEventFunction(key);
                          }}
                        >
                          <p
                            style={{
                              color: productLocation == key ? "white" : "black",
                              fontWeight: 900,
                              fontSize: 16,
                              textAlign: "center",
                              padding: 2,
                            }}
                            className="font"
                          >
                            {key}
                          </p>
                        </Button>
                      </>
                    );
                  }
                )}
              </Col>

              {productLocation !== "" && (
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: 20, marginLeft: 65 }}
                >
                  <Radio.Group buttonStyle="outline">
                    {Object.entries(
                      props.common.productLocationTypeMap[productLocation].value
                    ).map(([key, value]) => {
                      return (
                        <>
                          <Button
                            value={key}
                            style={{
                              marginLeft: 10,
                              width: 173,
                              height: 55,
                              backgroundColor: "#F5FDFF",
                              marginBottom: 10,
                            }}
                            className="neu1"
                            onClick={(e) => {
                              setProductType(key);
                              setProductSubType("");
                              trackEventFunction(key);
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 900,
                                fontSize: 12,
                                textAlign: "center",
                                color: productType == key ? "#D32402" : "black",
                                paddingTop: 10,
                              }}
                              className="font"
                            >
                              <img
                                src={value.image}
                                alt="Ant Design"
                                width="15%"
                                height="26"
                                style={{ paddingTop: 2 }}
                              />
                              &nbsp;&nbsp;
                              {key}
                            </p>
                          </Button>
                        </>
                      );
                    })}
                  </Radio.Group>
                </Col>
              )}

              {productType !== "" &&
                Object.entries(
                  props.common.productLocationTypeMap[productLocation].value[
                    productType
                  ].value
                ).length > 0 && (
                  <Col lg={24} md={24} sm={24} style={{ marginLeft: 65 }}>
                    {Object.entries(
                      props.common.productLocationTypeMap[productLocation]
                        .value[productType].value
                    ).map((value) => {
                      return (
                        <>
                          <Button
                            value={value[1].name}
                            style={{
                              marginLeft: 10,
                              marginTop: 20,
                              width: 163,
                              height: 60,
                              backgroundColor: "#F5FDFF",
                            }}
                            className="neu1"
                            onClick={(e) => {
                              setProductSubType(value[1].name);
                              trackEventFunction(value[1].name);
                            }}
                          >
                            <p
                              style={{
                                fontWeight: 900,
                                fontSize: 12,
                                textAlign: "center",
                                color:
                                  productSubType == value[1].name
                                    ? "#D32402"
                                    : "black",
                                paddingTop: 10,
                              }}
                              className="font"
                            >
                              <img
                                src={value[1].image}
                                alt="Ant Design"
                                width="40"
                                height="26"
                                style={{ paddingTop: "auto" }}
                              />
                              &nbsp;
                              {value[1].name}
                            </p>
                          </Button>
                        </>
                      );
                    })}
                  </Col>
                )}
              {allProducts.filter(
                (item) =>
                  item.productType.includes(productType) &&
                  item.productLocation.includes(productLocation)
              ).length > 0 && (
                <>
                  <Col style={{ marginTop: 10, marginLeft: 70 }}>
                    <Text style={{ fontSize: 14, fontWeight: 500 }}>Width</Text>
                    <Text
                      style={{ fontSize: 14, fontWeight: 500, marginLeft: 228 }}
                    >
                      Height
                    </Text>
                  </Col>
                  <Col style={{ marginTop: 4, marginLeft: 25 }}>
                    <InputNumber
                      // addonAfter={<Text style={{ fontWeight: 500 }}>mm</Text>}
                      addonAfter={selectAfter}
                      style={{
                        width: 255,
                        height: 40,
                        marginLeft: 45,
                        borderColor: "#353535",
                      }}
                      onChange={(value) => setWidth(value)}
                      value={width}
                    />
                    <InputNumber
                      //   addonAfter={<Text style={{ fontWeight: 500 }}>mm</Text>}
                      addonAfter={selectAfter}
                      style={{
                        width: 255,
                        height: 40,
                        marginLeft: 13,
                        borderColor: "#353535",
                      }}
                      onChange={(value) => setHeight(value)}
                      value={height}
                    />
                  </Col>

                  <Col style={{ marginTop: 20, marginLeft: 70 }}>
                    <Text style={{ fontSize: 14, fontWeight: 500 }}>
                      Quantity
                    </Text>
                  </Col>

                  <Col style={{ marginTop: 4, marginLeft: 70 }}>
                    <InputNumber
                      style={{
                        width: 255,
                        height: 40,
                        borderColor: "#353535",
                      }}
                      onChange={(value) => setQuantity(value)}
                      value={quantity}
                    />
                  </Col>
                </>
              )}
              <Col style={{ marginTop: 30, marginLeft: 70 }}>
                {(allProducts.filter(
                  (item) =>
                    item.productType.includes(productType) &&
                    item.productLocation.includes(productLocation)
                ).length > 0 &&
                  allProducts
                    .filter(
                      (item) =>
                        item.productType.includes(productType) &&
                        item.productLocation.includes(productLocation)
                    )
                    .map((data, key) => {
                      return (
                        <>
                          <Button
                            style={{
                              width: "41.5%",
                              height: 40,
                              borderRadius: 4,
                              marginRight: 13,
                              marginBottom: 20,
                              borderColor: "#353535",
                            }}
                            onClick={() => {
                              setSelectedGroup(data);
                              setCartVisible1(true);
                            }}
                          >
                            <Text className="overflow" style={{ width: "80%" }}>
                              {data.productName}
                            </Text>
                          </Button>
                        </>
                      );
                    })) || (
                  <Text type="danger" style={{ width: "80%" }}>
                    Please contact AllMesh team for pricing
                  </Text>
                )}
              </Col>

              <Col style={{ marginTop: 30, marginLeft: 70 }}>
                <Popover
                  placement="bottom"
                  content={
                    <Row>
                      <Col span={24}>
                        <Text type="danger">
                          Your Product is added to estimation
                        </Text>
                        <br></br>
                        <br></br>
                        <Button
                          onClick={() => hide()}
                          style={{ left: 170, backgroundColor: "red" }}
                        >
                          <p style={{ color: "white", fontWeight: 500 }}>Ok</p>
                        </Button>
                      </Col>
                    </Row>
                  }
                  trigger="click"
                  visible={cartVisbile}
                >
                  <Button
                    style={{
                      width: 173,
                      height: 40,
                      backgroundColor: "#D32402",
                      borderRadius: 4,
                    }}
                    onClick={() => {
                      addToCart();
                      trackEventFunction("Add to cart");
                    }}
                  >
                    <Text
                      style={{ color: "white", fontWeight: 900, fontSize: 14 }}
                    >
                      Add to Estimation
                    </Text>
                  </Button>
                </Popover>
                {Object.entries(props.bill.items).length > 0 && (
                  <Button
                    style={{
                      width: 173,
                      height: 40,
                      backgroundColor: "#D32402",
                      borderRadius: 4,
                      marginLeft: 195,
                    }}
                    onClick={() => {
                      navigation("/custContactDetails");
                      trackEventFunction("Add customer details");
                    }}
                  >
                    <Text
                      style={{ color: "white", fontWeight: 900, fontSize: 14 }}
                    >
                      View Estimate
                    </Text>
                  </Button>
                )}
              </Col>
            </Col>
          </Row>
        )}
        <Footers />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bill: state.bill,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    addToBill: (data) => dispatch({ type: "ADD_ITEM", payload: data }),
    removeFromBill: (data) => dispatch({ type: "REMOVE_ITEM", payload: data }),
    setBillItems: (data) => dispatch({ type: "SET_BILL_ITEMS", payload: data }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Estimate);
