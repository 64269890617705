import React, { useState, useEffect } from "react";
import "../App.less";
import { Col, Row } from "antd";
import Footers from "./footer";
import Contact from "./contact";

function AboutUs() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  return (
    <div>
      <Row>
        <Col lg={24} md={24} sm={24} style={{ width: "100%" }}>
          <Contact from="menu contact us" />
        </Col>
      </Row>
      <Footers />
    </div>
  );
}

export default AboutUs;
