import React, { useState, useEffect, useRef } from "react";
import "../App.less";
import { Col, Row } from "antd";

//class part state they holds the data this page
function Header() {
  const [ticking, setTicking] = useState(true),
    [count, setCount] = useState(0);

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));

    const timer = setTimeout(() => ticking && setCount(count + 1), 3000);
    return () => clearTimeout(timer);
  }, [count, ticking]);

  return (
    <div>
      {matches && (
        <Row>
          <Col
            xs={{ span: 24 }}
            xl={24}
            sm={24}
            style={{ paddingBottom: 55, marginTop: 50 }}
          >
            <h2
              align="center"
              className="font"
              style={{ fontWeight: 900, marginTop: 30, fontSize: 35 }}
            >
              JOURNEY
            </h2>
          </Col>

          <Col
            lg={2}
            md={2}
            sm={2}
            style={{ marginLeft: "24%", cursor: "pointer" }}
          >
            {count === 0 || count === 3 || count === 6 || count > 8 ? (
              <img
                src={process.env.PUBLIC_URL + "/asset/1.1.svg"}
                alt="Ant Design"
                width="100%"
                height="100"
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/asset/1.svg"}
                alt="Ant Design"
                width="100%"
                height="100"
                onClick={() => {
                  setCount(0);
                }}
              />
            )}
          </Col>
          <Col lg={3} md={3} sm={3} style={{ bottom: 30 }}>
            <img
              src={process.env.PUBLIC_URL + "/asset/arrow1.svg"}
              alt="Ant Design"
              width="100%"
              height="100"
            />
          </Col>
          <Col lg={2} md={2} sm={2} style={{ cursor: "pointer" }}>
            {count === 1 || count === 4 || count === 7 ? (
              <img
                src={process.env.PUBLIC_URL + "/asset/2.1.svg"}
                alt="Ant Design"
                width="100%"
                height="100"
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/asset/2.svg"}
                alt="Ant Design"
                width="100%"
                height="100"
                onClick={() => {
                  setCount(1);
                }}
              />
            )}
          </Col>
          <Col lg={3} md={3} sm={3} style={{ top: 30 }}>
            <img
              src={process.env.PUBLIC_URL + "/asset/arrow2.svg"}
              alt="Ant Design"
              width="100%"
              height="100"
            />
          </Col>
          <Col lg={2} md={2} sm={2} style={{ cursor: "pointer" }}>
            {count === 2 || count === 5 || count === 8 ? (
              <img
                src={process.env.PUBLIC_URL + "/asset/3.1.svg"}
                alt="Ant Design"
                width="100%"
                height="100"
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/asset/3.svg"}
                alt="Ant Design"
                width="100%"
                height="100"
                onClick={() => {
                  setCount(2);
                }}
              />
            )}
          </Col>
          {count === 0 || count === 3 || count === 6 || count > 8 ? (
            <>
              {" "}
              <Col lg={24} md={24} sm={24}>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: 30,
                    marginTop: "3%",
                    color: "#353535",
                  }}
                  className="font"
                  align="center"
                >
                  Imagine a Plan
                </h3>
              </Col>
              <Col lg={24} md={24} sm={24}>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    marginTop: 30,
                    color: "#3D3D3D",
                  }}
                  className="font"
                  align="center"
                >
                  Talk to our mesh expert - who will give you a right proposal
                </p>
              </Col>
            </>
          ) : count === 1 || count === 4 || count === 7 ? (
            <>
              <Col lg={24} md={24} sm={24}>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: 30,
                    marginTop: "3%",
                    color: "#353535",
                  }}
                  className="font"
                  align="center"
                >
                  Customize your mesh
                </h3>
              </Col>
              <Col lg={24} md={24} sm={24}>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    marginTop: 30,
                    color: "#3D3D3D",
                  }}
                  className="font"
                  align="center"
                >
                  Execute your plan, by customizing mesh that matches your
                  interior
                </p>
              </Col>
            </>
          ) : count === 2 || count === 5 || count === 8 ? (
            <>
              <Col lg={24} md={24} sm={24}>
                <h3
                  style={{
                    fontWeight: 600,
                    fontSize: 30,
                    marginTop: "3%",
                    color: "#353535",
                  }}
                  className="font"
                  align="center"
                >
                  Install and safeguard
                </h3>
              </Col>
              <Col lg={24} md={24} sm={24}>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 18,
                    marginTop: 30,
                    color: "#3D3D3D",
                  }}
                  className="font"
                  align="center"
                >
                  Install and relax, let mesh protect you and your family
                </p>
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
      )}
      {!matches && (
        <Row>
          <Col
            xs={{ span: 24 }}
            xl={24}
            sm={24}
            style={{ paddingBottom: 55, marginTop: 10 }}
          >
            <h2
              align="center"
              className="font"
              style={{ fontWeight: 900, marginTop: 30, fontSize: 35 }}
            >
              JOURNEY
            </h2>
          </Col>

          <Col
            lg={2}
            md={2}
            sm={2}
            style={{ marginLeft: "14%", cursor: "pointer" }}
          >
            {count === 0 || count === 3 || count === 6 || count > 8 ? (
              <img
                src={process.env.PUBLIC_URL + "/asset/1.1.svg"}
                alt="Ant Design"
                width="50"
                height="50"
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/asset/1.svg"}
                alt="Ant Design"
                width="50"
                height="50"
                onClick={() => {
                  setCount(0);
                }}
              />
            )}
          </Col>
          <Col lg={3} md={3} sm={3} style={{ bottom: 20 }}>
            <img
              src={process.env.PUBLIC_URL + "/asset/arrow1.svg"}
              alt="Ant Design"
              width="70"
              height="50"
            />
          </Col>
          <Col lg={2} md={2} sm={2} style={{ cursor: "pointer" }}>
            {count === 1 || count === 4 || count === 7 ? (
              <img
                src={process.env.PUBLIC_URL + "/asset/2.1.svg"}
                alt="Ant Design"
                width="50"
                height="50"
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/asset/2.svg"}
                alt="Ant Design"
                width="50"
                height="50"
                onClick={() => {
                  setCount(1);
                }}
              />
            )}
          </Col>
          <Col lg={3} md={3} sm={3} style={{ top: 20 }}>
            <img
              src={process.env.PUBLIC_URL + "/asset/arrow2.svg"}
              alt="Ant Design"
              width="70"
              height="50"
            />
          </Col>
          <Col lg={2} md={2} sm={2} style={{ cursor: "pointer" }}>
            {count === 2 || count === 5 || count === 8 ? (
              <img
                src={process.env.PUBLIC_URL + "/asset/3.1.svg"}
                alt="Ant Design"
                width="50"
                height="50"
              />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/asset/3.svg"}
                alt="Ant Design"
                width="50"
                height="50"
                onClick={() => {
                  setCount(2);
                }}
              />
            )}
          </Col>
          {count === 0 || count === 3 || count === 6 || count > 8 ? (
            <>
              {" "}
              <Col xs={{ span: 24 }} xl={24} sm={24} style={{ marginTop: 10 }}>
                <h2
                  align="center"
                  className="font"
                  style={{ fontWeight: 600, marginTop: 10, fontSize: 20 }}
                >
                  Imagine a Plan
                </h2>
              </Col>
              <Col lg={24} md={24} sm={24}>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 16,
                    marginTop: 10,
                    color: "#3D3D3D",
                  }}
                  className="font"
                  align="center"
                >
                  Talk to our mesh expert - who will give you a right proposal
                </p>
              </Col>
            </>
          ) : count === 1 || count === 4 || count === 7 ? (
            <>
              <Col xs={{ span: 24 }} xl={24} sm={24} style={{ marginTop: 10 }}>
                <h2
                  align="center"
                  className="font"
                  style={{ fontWeight: 600, marginTop: 10, fontSize: 20 }}
                >
                  Customize your mesh
                </h2>
              </Col>
              <Col lg={24} md={24} sm={24}>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 16,
                    marginTop: 10,
                    color: "#3D3D3D",
                  }}
                  className="font"
                  align="center"
                >
                  Execute your plan, by customizing mesh that matches your
                  interior
                </p>
              </Col>
            </>
          ) : count === 2 || count === 5 || count === 8 ? (
            <>
              <Col xs={{ span: 24 }} xl={24} sm={24} style={{ marginTop: 10 }}>
                <h2
                  align="center"
                  className="font"
                  style={{ fontWeight: 600, marginTop: 10, fontSize: 20 }}
                >
                  Install and safeguard
                </h2>
              </Col>

              <Col lg={24} md={24} sm={24}>
                <p
                  style={{
                    fontWeight: 400,
                    fontSize: 16,
                    marginTop: 10,
                    color: "#3D3D3D",
                  }}
                  className="font"
                  align="center"
                >
                  &nbsp; &nbsp; Install and relax, let mesh protect you and your
                  family
                </p>
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
      )}
    </div>
  );
}

export default Header;
