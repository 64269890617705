import React, { useState, useEffect } from "react";
import "../App.less";
import { Col, Row, Button, Card, Typography, Image } from "antd";
import environment from "../configure";
import { connect } from "react-redux";
import RightBar from "./rightBar";
import Whatsapp from "./whatsapp";
import Footers from "./footer";
const { Text } = Typography;
var resLength = 0;
//class part state they holds the data this page
function Products() {
  const [Project, setProject] = useState([]);
  const [Length, setLength] = useState(2);

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));

    async function fetchBanners() {
      try {
        await fetch(environment.apiGateway + "fetchProductDetails", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "Gallery",
          }),
        }).then(async (fetchedDetails) => {
          var fetchedRespone = await fetchedDetails.json();
          let res = fetchedRespone.Items;
          setProject(res);
          resLength = res.length;
        });
      } catch (err) {
        return err;
      }
    }

    fetchBanners();
  }, []);

  return (
    <div className="fill-window">
      {matches && (
        <>
          <Row>
            <RightBar />
            <Whatsapp />

            {Project.slice(0, Length).map((data) => {
              return (
                <>
                  <Col
                    lg={11}
                    md={11}
                    sm={11}
                    style={{ marginTop: 76, paddingLeft:"5%", width: "100%",paddingBottom: 52, }}
                  >
                    <Row>
                      <Col style={{ marginLeft: "10%" }}>
                        <Image
                          src={data.productImages}
                          alt="Ant Design"
                          height={"100%"}
                          width={"100%"}
                        />
                      </Col>
                      <Col lg={20} md={20} sm={20} style={{ marginTop: 19 ,marginLeft:'10%'}}>
                        <Text type="secondary" className="font">
                         {data.text1}
                        </Text>
                        
                      </Col>
                    </Row>
                  </Col>
                </>
              );
            })}

            {Length === 2 ? (
              <Col
                style={{
                  width: "100%",
                  paddingBottom: 92,
                  paddingTop: 92,
                  paddingRight: "40%",
                  paddingLeft: "40%",
                }}
              >
                <Button
                  style={{
                    backgroundColor: "red",
                    width: 289,
                    height: 48,
                    padding: 10,
                  }}
                  onClick={() => {
                    setLength(resLength);
                  }}
                >
                  <p
                    style={{ color: "white", fontSize: 16, fontWeight: 900 }}
                    className="font"
                  >
                    Show more products
                  </p>
                </Button>
              </Col>
            ) : (
              ""
            )}
          </Row>
        </>
      )}
      {!matches && (
        <>
          <Row>
            <RightBar />
            <Whatsapp/>
            <Col lg={24} md={24} sm={24} style={{ width: "100%",paddingBottom: 52, }}>
              {Project.slice(0, Length).map((data) => {
                return (
                  <>
                    <Col
                      lg={10}
                      md={10}
                      sm={10}
                      style={{ marginTop: 76, left: "5%", width: "100%" }}
                    >
                      <Row>
                        <Col>
                          <Image
                            src={data.productImages}
                            alt="Ant Design"
                            height={"95%"}
                          />
                        </Col>
                        <Col lg={20} md={20} sm={20} style={{ marginTop: 19 }}>
                          
                          <Text type="secondary" className="font">
                          {data.text1}
                          </Text>
                        </Col>
                      </Row>
                    </Col>
                  </>
                );
              })}

              {Length === 2 ? (
                <Col
                  style={{
                    width: "100%",
                    left: "25%",
                    paddingBottom: 52,
                    paddingTop: 52,
                  }}
                >
                  <Button
                    style={{
                      backgroundColor: "red",
                      width: "50%",
                      height: 40,
                      padding: 5,
                    }}
                    onClick={() => {
                      setLength(resLength);
                    }}
                  >
                    <p
                      style={{ color: "white", fontSize: 16, fontWeight: 900 }}
                      className="font"
                    >
                      Show more products
                    </p>
                  </Button>
                </Col>
              ) : (
                ""
              )}
            </Col>
          </Row>
        </>
      )}

      <Footers />
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bill: state.bill,
  };
};

export default connect(mapStateToProps)(Products);
