import React, { useEffect, useState, useRef } from "react";
import "../Styles/Sticky.css";
import { useNavigate } from "react-router-dom";
import ReactGA from "react-ga4";

function SideStickyBar() {
  const [sideBar, setSideBar] = useState(false);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const showBar = () => {
    if (window.scrollY >= 150) {
      setSideBar(true);
    } else {
      setSideBar(false);
    }
  };

  const navigation = useNavigate();

  window.addEventListener("scroll", showBar);

  const trackEventFunction = () => {
    ReactGA.event({
      category: "Estimation category",
      action: "Estimation Right Bar Clicked",
      label: "Estimation label",
      value: 1,
    });
  };
  return (
    <div>
      <div className={sideBar ? "sideBarAfterScroll" : "sideBarMainContainer"}>
        {matches && (
          <>
            {" "}
            <p
              className="stickButttons getEstimateSideButton"
              onClick={() => {
                navigation("/estimate");
                trackEventFunction();
              }}
              style={{ fontWeight: 600, fontSize: 18 }}
            >
              <img
                src={process.env.PUBLIC_URL + "/asset/estimate.svg"}
                alt="Ant Design"
                height={60}
                width={60}
              />{" "}
              Get Estimation
            </p>
          </>
        )}
        {!matches && (
          <p
            className="stickButttons getEstimateSideButton"
            onClick={() => {
              navigation("/estimate");
              trackEventFunction();
            }}
            style={{ fontWeight: 600, fontSize: 12 }}
          >
            <img
              src={process.env.PUBLIC_URL + "/asset/estimate.svg"}
              alt="Ant Design"
              height={25}
              width={25}
            />{" "}
            Get Estimation
          </p>
        )}
      </div>
    </div>
  );
}
export default SideStickyBar;
