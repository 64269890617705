import React, { Component } from "react";
import { Col, Row } from "antd";
import "../App.less";
import environment from "../configure";
import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

//class part state they holds the data this page
class Banners extends Component {
  state = {
    galleryDetails: [],
    matches: window.matchMedia("(min-width: 512px)").matches,
  };
  //functions login page

  componentDidMount() {
    this.fetchBanners();
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 512px)").addEventListener("change", handler);
    // alert("hii");
  }
  async fetchBanners() {
    try {
      var fetchedDetails = await fetch(
        environment.apiGateway + "fetchProductDetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "Gallery",
          }),
        }
      );
      var fetchedRespone = await fetchedDetails.json();
      this.setState({ galleryDetails: fetchedRespone.Items });
    } catch (err) {
      return err;
    }
  }

  render() {
    return (
      <div>
        <Row>
          <Col xs={24} xl={24} sm={24}>
            <div>
              {this.state.matches && (
                <>
                  <Col xs={{ span: 24 }} xl={24} sm={24}>
                    <h2
                      align="center"
                      className="font"
                      style={{ fontWeight: 900, marginTop: 80, fontSize: 35 }}
                    >
                      OUR PROJECTS
                    </h2>
                  </Col>
                  <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true}
                    arrows={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    infinite={true}
                    keyBoardControl={true}
                    transitionDuration={1000}
                    itemClass="items"
                    sliderClass="slider"
                  >
                    {this.state.galleryDetails.map((item) => {
                      return (
                        <img
                          src={item.productImages}
                          width={"100%"}
                          style={{
                            paddingTop: 30,
                            paddingBottom: 50,
                            paddingLeft: "5%",
                            paddingRight: "5%",
                          }}
                        />
                      );
                    })}
                  </Carousel>
                </>
              )}
              {!this.state.matches && (
                <>
                  <Col xs={{ span: 24 }} xl={24} sm={24}>
                    <h2
                      align="center"
                      className="font"
                      style={{ fontWeight: 900, marginTop: 50, fontSize: 35 }}
                    >
                      OUR PROJECTS
                    </h2>
                  </Col>
                  <Carousel
                    swipeable={true}
                    draggable={false}
                    showDots={true}
                    responsive={responsive}
                    ssr={true}
                    arrows={true}
                    autoPlay={true}
                    autoPlaySpeed={5000}
                    infinite={true}
                    keyBoardControl={true}
                    transitionDuration={1000}
                    itemClass="items"
                    sliderClass="slider"
                  >
                    {this.state.galleryDetails.map((item) => {
                      return (
                        <img
                          src={item.productImages}
                          width={"100%"}
                          style={{
                            paddingTop: 30,
                            paddingBottom: 50,
                            paddingLeft: "5%",
                            paddingRight: "5%",
                          }}
                        />
                      );
                    })}
                  </Carousel>
                </>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default Banners;
