import { SET_JSON } from "../action";

const initialState = {
  productLocationTypeMap: {},
  color: {},
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case SET_JSON:
      return {
        ...state,
        productLocationTypeMap: action.payload.productLocationTypeMap,
        color: action.payload.color,
      };
    default:
      return state;
  }
}
