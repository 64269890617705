import React, { useState, useEffect } from "react";
import "../App.less";
import { useNavigate } from "react-router-dom";
import { Col, Row,  Typography } from "antd";
import Footers from "./footer";



function AboutUs(props) {
  const navigation = useNavigate();
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  return (
    <div>
     
      <Row>
        <Col lg={24} md={24} sm={24} style={{ width: "100%" }}>
          <Col
            xs={{ span: 24 }}
            xl={24}
            sm={24}
            style={{ paddingBottom: 55, marginTop: 50 }}
          >
             {matches && (<h2
              align="center"
              className="font"
              style={{ fontWeight: 900, marginTop: 30, fontSize: 35 }}
            >
  TERMS AND CONDITIONS
            </h2>)}
      {!matches && (<h2
              align="center"
              className="font"
              style={{ fontWeight: 900, marginTop: 20, fontSize: 25 }}
            >
  TERMS AND CONDITIONS
            </h2>)}
            
          </Col>
          <Col style={{paddingRight:'10%',paddingLeft:'10%',paddingBottom:'10%'}}>
            <h3>
              This estimate is an approximation and is not guaranteed. The
              estimate is based on information provided from the client
              regarding project requirements. Actual cost may change once all
              project elements are finalized or negotiated. Prior to any changes
              of cost, the client will be notified. Estimate valid for 30 days.
              </h3>
              <h3>
              2. Services. Upon acceptance by you, AllMesh will perform the
              installation or other services described in the estimate. Any
              additional services requested by you and not covered by the
              estimate will incur additional charges.
              </h3>
              <h3>
               3. Schedule. The services
              will be completed and delivery will be made in accordance with the
              schedule in the estimate, or as otherwise approved by the parties
              in writing. There could be delay resulting from supply chain or
              other factors, the team will ensure to communicate the same at the
              earliest. 
              </h3>
              <h3>
              4. Changes. Changes in the specifications, quantities,
              schedule or other aspects of the services that are requested or
              approved by you do not become binding upon AllMesh unless accepted
              by Ivie in writing. Any such changes may result in additional or
              increased charges, and you agree to pay such increased charges.
              </h3>
              <h3>
         5.Unless explicitly agreed prior to commencement of work, payment
              will be due in partial/full upon completion of the work or
              services provided. Where a quotation has been provided, the full
              amount, less any payments already made, is payable. Where a
              quotation has not been provided, The Company’s agent will advise
              the customer of the amount due and will post a detailed receipt
              within 72 hours. All prices include sales tax at the appropriate
              rate. All materials and goods supplied by The Company shall remain
              the property of The Company until the full invoice has been paid
              by the customer.
              </h3>
          </Col>
        </Col>
      </Row>
      <Footers />
    </div>
  );
}

export default AboutUs;
