import React, { useEffect, useState } from "react";
import { Input, Row, Typography, Card, Col, Button, message } from "antd";
import Images from "../Images/Estimation.png";
import Footers from "./footer";
import { useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import "../App.less";
import environment from "../configure";
import uuid from "uuid/dist/v4";
import ReactGA from "react-ga4";
const { Title, Text } = Typography;

function CustomerContactDetails(props) {
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );
  const customerRefId = Math.floor(100000 + Math.random() * 9000000000);
  const addedDate = new Date().toISOString().substr(0, 10);

  const navigation = useNavigate();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const submit = async () => {
    if (customerName == "") return message.error("Please enter your name");
    if (customerEmail == "") return message.error("Please enter your email");
    var mailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (customerEmail !== "" && mailReg.test(customerEmail) === false)
      return message.error("Please enter valid email");
    if (customerPhone == "")
      return message.error("Please enter your phone number");
    var phoneReg = /^[5-9]{1}[0-9]{9}$/;
    if (phoneReg.test(customerPhone) === false)
      return message.error("Please enter valid phone number");

    props.setValue({
      customerName: customerName,
      customerEmail: customerEmail,
      customerPhone: customerPhone,
    });

    try {
      var addOrder = await fetch(
        environment.apiGateway + "addQuotationDetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: uuid(),
            customerName: customerName,
            customerPhone: "+91" + customerPhone,
            customerEmail: customerEmail,
            customerReferenceNumber: customerRefId.toString(),
            addedDate: addedDate,
            time: new Date().toTimeString().substr(0, 8),
            estimatedAmount: props.bill.total,
            orderDetails: props.bill.items,
            type: "Lead",
          }),
        }
      );
    } catch (error) {
      console.log(error);
    }

    navigation("/quotationDetails");
  };

  const trackEventFunction = () => {
    ReactGA.event({
      category: "Estimation Contact category",
      action: "Estimation Contact Submit",
      label: "Estimation Contact label",
      value: 1,
    });
  };

  return (
    <>
      <div className="fill-window">
        {matches && (
          <>
            <Row>
              <Col lg={12} md={12} sm={24}>
                <Card
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + Images})`,
                    height: "90vh",
                    backgroundRepeat: "no-repeat",
                    backgroundSize: "cover",
                  }}
                >
                  <Col lg={24} md={24} sm={24}>
                    <Title
                      style={{
                        color: "white",
                        marginTop: 250,
                        fontWeight: 600,
                        fontSize: 40,
                        marginLeft: 60,
                        lineHeight: 1,
                      }}
                      className="font"
                    >
                      Get an Estimate
                    </Title>
                  </Col>
                  <Col lg={16} md={24} sm={24}>
                    <Title
                      style={{
                        color: "white",
                        fontSize: 24,
                        marginTop: 1,
                        fontWeight: 500,
                        marginLeft: 60,
                        lineHeight: 1.5,
                      }}
                      className="font"
                    >
                      Having Doubts? Get your{" "}
                      <Text type="danger">Estimation</Text> Now!
                    </Title>
                  </Col>
                </Card>
              </Col>

              <Col
                lg={12}
                md={12}
                sm={24}
                style={{ backgroundColor: "#F5FDFF" }}
              >
                <Col lg={20} md={20} sm={20} style={{ marginTop: "3%" }}>
                  <img
                    src={process.env.PUBLIC_URL + "/asset/Pagination2.png"}
                    alt="Ant Design"
                    width="50"
                    height="20"
                    style={{ marginLeft: 540 }}
                  />
                </Col>
                <Col lg={24} md={24} sm={24} style={{ marginTop: "7%" }}>
                  <Text
                    style={{
                      fontWeight: 600,
                      fontSize: 30,
                      marginLeft: "9%",
                      color: "#353535",
                    }}
                    className="font"
                  >
                    CONTACT DETAILS
                  </Text>
                </Col>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "6%", marginLeft: "10%" }}
                >
                  <Text style={{ fontSize: 14, fontWeight: 500 }}>
                    YOUR NAME
                  </Text>
                  <Text type="danger">*</Text>
                </Col>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "0.6%", marginLeft: "3.8%" }}
                >
                  <Input
                    style={{
                      width: "80%",
                      height: 40,

                      marginLeft: "6.5%",
                      backgroundColor: "#F5FDFF",
                      borderRadius: "4px",
                      background: "#f5fdff",
                      boxShadow:
                        "-5px -5px 6px #ffffff, 5px 5px 10px rgba(49, 50, 64, 0.2)",
                    }}
                    value={customerName}
                    onChange={(e) => setCustomerName(e.target.value)}
                    className="inputneu"
                  />
                </Col>

                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "5%", marginLeft: "10%" }}
                >
                  <Text style={{ fontSize: 14, fontWeight: 500 }}>
                    YOUR EMAIL
                  </Text>
                  <Text type="danger">*</Text>
                </Col>

                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "0.6%", marginLeft: "3.8%" }}
                >
                  <Input
                    style={{
                      width: "80%",
                      height: 40,
                      marginLeft: "6.5%",

                      backgroundColor: "#F5FDFF",
                      borderRadius: "4px",
                      background: "#f5fdff",
                      boxShadow:
                        "-5px -5px 6px #ffffff, 5px 5px 10px rgba(49, 50, 64, 0.2)",
                    }}
                    value={customerEmail}
                    onChange={(e) => setCustomerEmail(e.target.value)}
                    className="inputneu"
                  />
                </Col>

                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "5%", marginLeft: "10%" }}
                >
                  <Text style={{ fontSize: 14, fontWeight: 500 }}>
                    YOUR MOBILE NUMBER
                  </Text>
                  <Text type="danger">*</Text>
                </Col>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "0.6%", marginLeft: "3.8%" }}
                >
                  <Input
                    style={{
                      width: "80%",
                      height: 40,
                      marginLeft: "6.5%",
                      backgroundColor: "#F5FDFF",
                      borderRadius: "4px",
                      background: "#f5fdff",
                      boxShadow:
                        "-5px -5px 6px #ffffff, 5px 5px 10px rgba(49, 50, 64, 0.2)",
                    }}
                    value={customerPhone}
                    onChange={(e) => setCustomerPhone(e.target.value)}
                    maxLength={10}
                    className="inputneu"
                  />
                </Col>

                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ marginTop: "6%", marginLeft: "10%" }}
                >
                  <Button
                    style={{
                      width: "86%",
                      height: 40,
                      backgroundColor: "#F5FDFF",
                      fontSize: 14,
                      fontWeight: 900,
                      background: 'linear-gradient(0deg, #f5fdff, #f5fdff), #e7ecf0',
                      border: 'none',
                      boxShadow: '-5px -5px 6px #ffffff, 5px 5px 10px rgba(49, 50, 64, 0.2)',
                      borderRadius: '10px',
                    }}
                    className="contactneu"
                    onClick={() => {
                      submit();
                      trackEventFunction();
                    }}
                  >
                    <Text style={{ color: "#D32402" }}>Submit</Text>
                  </Button>
                </Col>
              </Col>
            </Row>
          </>
        )}
        {!matches && (
          <>
            <Row>
              <Col lg={24} md={24} sm={{ span: 24 }}>
                <Card
                  style={{
                    backgroundImage: `url(${process.env.PUBLIC_URL + Images})`,
                    height: 582,
                    width: "100%",
                  }}
                >
                  <Col lg={24} md={24} sm={24}>
                    <Title
                      style={{
                        color: "white",
                        marginTop: 250,
                        fontWeight: 600,
                        fontSize: 40,
                        // marginLeft: 60,
                        lineHeight: 1,
                      }}
                      className="font"
                    >
                      Get an Estimate
                    </Title>
                  </Col>
                  <Col lg={16} md={24} sm={24}>
                    <Title
                      style={{
                        color: "white",
                        fontSize: 24,
                        marginTop: 1,
                        fontWeight: 500,
                        lineHeight: 1.5,
                      }}
                      className="font"
                    >
                      Having Doubts? Get your{" "}
                      <Text type="danger">Estimation</Text> Now!
                    </Title>
                  </Col>
                </Card>
              </Col>

              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 99, width: "100%" }}
              >
                <Text
                  style={{
                    fontWeight: 600,
                    fontSize: 30,
                    marginLeft: 20,
                    width: "100%",
                  }}
                  className="font"
                >
                  Contact Details{" "}
                </Text>
                <img
                  src={process.env.PUBLIC_URL + "/asset/Pagination2.png"}
                  alt="Ant Design"
                  width="50"
                  height="20"
                  style={{ marginLeft: 93, marginTop: -10 }}
                />
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 25, marginLeft: 24, width: "100%" }}
              >
                <Text style={{ fontSize: 14, fontWeight: 500 }}>Your Name</Text>
                <Text type="danger">*</Text>
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 16, marginLeft: 24, width: "100%" }}
              >
                <Input
                  style={{
                    width: "94%",
                    height: 40,
                    backgroundColor: "#F5FDFF",
                  }}
                  value={customerName}
                  onChange={(e) => setCustomerName(e.target.value)}
                />
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 25, marginLeft: 24, width: "100%" }}
              >
                <Text style={{ fontSize: 14, fontWeight: 500 }}>
                  Your Email
                </Text>
                <Text type="danger">*</Text>
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 16, marginLeft: 24, width: "100%" }}
              >
                <Input
                  style={{
                    width: "94%",
                    height: 40,
                    backgroundColor: "#F5FDFF",
                  }}
                  value={customerEmail}
                  onChange={(e) => setCustomerEmail(e.target.value)}
                />
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 25, marginLeft: 24, width: "100%" }}
              >
                <Text style={{ fontSize: 14, fontWeight: 500 }}>
                  Your Mobile Number
                </Text>
                <Text type="danger">*</Text>
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ marginTop: 16, marginLeft: 24, width: "100%" }}
              >
                <Input
                  style={{
                    width: "94%",
                    height: 40,
                    backgroundColor: "#F5FDFF",
                  }}
                  value={customerPhone}
                  onChange={(e) => setCustomerPhone(e.target.value)}
                  maxLength={10}
                />
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{
                  marginTop: 55,
                  marginLeft: 24,
                  width: "100%",
                  paddingBottom: 103,
                }}
              >
                <Button
                  style={{
                    width: "94%",
                    height: 40,
                    backgroundColor: "#F5FDFF",
                  }}
                  onClick={() => {
                    submit();
                    trackEventFunction();
                  }}
                  className="contactneu"
                >
                  <Text
                    style={{ color: "#D32402", fontWeight: 900, fontSize: 14 }}
                  >
                    Submit
                  </Text>
                </Button>
              </Col>
            </Row>
          </>
        )}

        <Footers />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    bill: state.bill,
  };
};
function mapDispatchToProps(dispatch) {
  return {
    setValue: (data) => dispatch({ type: "SET_VALUE", payload: data }),
  };
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomerContactDetails);
