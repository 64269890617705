import React, { useEffect, useState } from "react";
import { Row, Typography, Card, Col, Button, message } from "antd";
import Footers from "./footer";
import { DownloadOutlined, DeleteFilled } from "@ant-design/icons";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import environment from "../configure";
import uuid from "uuid/dist/v4";
import "../App.less";
import { pdf } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import GeneratePDF from "./generatePDF";
import ReactGA from "react-ga4";
import moment from "moment";

const { Text } = Typography;
const customerRefId = Math.floor(100000 + Math.random() * 9000000000);
const addedDate = new Date().toISOString().substr(0, 10);

function QuotationDetails(props) {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );
  const [quotationCounter, setQuotationCounter] = useState("");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));
    async function fetchQuotations() {
      try {
        var fetchedDetails = await fetch(
          environment.apiGateway + "fetchProductDetails",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              type: "Quotation",
            }),
          }
        );
        var fetchedRespone = await fetchedDetails.json();
        if (fetchedRespone.Items.length > 0) {
          let allQuotaions = fetchedRespone.Items.sort(
            (a, b) =>
              moment(b.addedDate + " " + b.time, "YYYY-MM-DD  HH:mm:ss") -
              moment(a.addedDate + " " + a.time, "YYYY-MM-DD  HH:mm:ss")
          );
          setQuotationCounter(allQuotaions[0].customerReferenceNumber);
        } else setQuotationCounter("AMSS99999");
      } catch (err) {
        alert(err);
        return err;
      }
    }
    fetchQuotations();
  }, []);

  const navigation = useNavigate();

  const downloadPDF = async () => {
    let tempCounter =
      parseInt(quotationCounter.substring(4, quotationCounter.length)) + 1;
    await pdf(
      <GeneratePDF
        customerName={props.bill.customerName}
        customerPhone={props.bill.customerPhone}
        customerEmail={props.bill.customerEmail}
        customerReferenceNumber={"AMSS".concat(tempCounter)}
        addedDate={addedDate}
        time={new Date().toTimeString().substr(0, 8)}
        estimatedAmount={props.bill.total}
        orderDetails={props.bill.items}
      />
    )
      .toBlob()
      .then((blob) => saveAs(blob, `${"AMSS".concat(tempCounter)}.pdf`));
  };

  //to add the quotation to db
  const addOrderDetails = async () => {
    let tempCounter =
      parseInt(quotationCounter.substring(4, quotationCounter.length)) + 1;
    message.success(
      "Thanks for contacting us! Our mesh experts will call back soon"
    );

    try {
      var addOrder = await fetch(
        environment.apiGateway + "addQuotationDetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: uuid(),
            customerName: props.bill.customerName,
            customerPhone: "+91" + props.bill.customerPhone,
            customerEmail: props.bill.customerEmail,
            customerReferenceNumber: "AMSS".concat(tempCounter),
            addedDate: addedDate,
            time: new Date().toTimeString().substr(0, 8),
            estimatedAmount: props.bill.total,
            orderDetails: props.bill.items,
            type: "Quotation",
          }),
        }
      );
    } catch (error) {
      console.log(error);
    }

    props.setBillItems({
      orderDetails: {},
      total: 0,
      customerName: "",
      customerEmail: "",
      customerPhone: "",
    });
    navigation("/");
  };

  const trackEventFunction = (event) => {
    ReactGA.event({
      category: "Quotation category",
      action: "Quotation " + event + " Clicked",
      label: "Quotation label",
      value: 1,
    });
  };

  return (
    <>
      <div className="fill-window">
        {matches && (
          <>
            <Row>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{
                  width: "100%",
                  backgroundColor: "#FBFBFB",
                  paddingLeft: "3%",
                  paddingRight: "3%",
                }}
              >
                <Col lg={24} md={24} sm={24}>
                  <div style={{ marginTop: 10 }}>
                    <div id="divToPrint" className="pdf">
                      <Col lg={24} md={24} sm={24} style={{ width: "100%" }}>
                        <h2
                          style={{
                            fontWeight: 600,
                            fontSize: 30,
                            textAlign: "center",
                          }}
                        >
                          Estimation
                        </h2>
                      </Col>

                      <Row style={{ marginTop: 20 }}>
                        <Col span={24} offset={1}>
                          <h3>Estimation For:</h3>
                        </Col>
                        <Col span={24} offset={1}>
                          <Text>{"Date : " + addedDate}</Text>
                        </Col>
                        <Col span={24} offset={1}>
                          <Text>
                            {"RefId : " +
                              "AMSS".concat(
                                parseInt(
                                  quotationCounter.substring(
                                    4,
                                    quotationCounter.length
                                  )
                                ) + 1
                              )}
                          </Text>
                        </Col>
                        <Col span={24} offset={1}>
                          <Text>{"Name : " + props.bill.customerName}</Text>
                        </Col>
                        <Col span={24} offset={1}>
                          <Text>{"Phone : " + props.bill.customerPhone}</Text>
                        </Col>
                        <Col span={24} offset={1}>
                          <Text>{"Email : " + props.bill.customerEmail}</Text>
                        </Col>
                      </Row>

                      <br></br>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "space-between",
                          borderBottom: "1px solid gray",
                        }}
                      >
                        <p style={{ fontWeight: "bold", width: 160 }}>
                          Product Name
                        </p>
                        <p style={{ fontWeight: "bold", width: 40 }}>
                          Width (mm)
                        </p>
                        <p style={{ fontWeight: "bold", width: 50 }}>
                          Height (mm)
                        </p>
                        <p style={{ fontWeight: "bold", width: 50 }}>Color</p>
                        <p style={{ fontWeight: "bold", width: 80 }}>
                          Location
                        </p>
                        <p style={{ fontWeight: "bold", width: 80 }}>Type</p>
                        <p style={{ fontWeight: "bold", width: 80 }}>
                          Quantity
                        </p>
                        <p style={{ fontWeight: "bold", width: 100 }}>
                          Total (₹){" "}
                        </p>
                      </div>
                      {Object.entries(props.bill.items).map(([key, value]) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "space-between",
                              borderBottom: "1px solid gray",
                              height: "100%",
                              paddingTop: 30,
                            }}
                          >
                            <p style={{ width: 170 }}>
                              {value.productName}({value.productDescription})
                            </p>
                            <p style={{ width: 40 }}>{value.width}</p>
                            <p style={{ width: 40 }}>{value.height}</p>
                            <p style={{ width: 40 }}>{value.color}</p>
                            <p style={{ width: 80 }}>{value.productLocation}</p>
                            <p style={{ width: 80 }}>{value.productType}</p>
                            <p style={{ width: 70 }}>{value.quantity} </p>
                            <p style={{ width: 100 }}>
                              ₹ {parseFloat(value.itemTotal).toFixed(2)} &nbsp;
                              <DeleteFilled
                                style={{ color: "red" }}
                                onClick={() => props.removeFromBill(key)}
                              />
                            </p>
                          </div>
                        );
                      })}
                    </div>

                    <Col span={24}></Col>
                    <div style={{ height: 50 }}></div>

                    <tr>
                      <td></td>
                      <td></td>
                      <td></td> <td></td> <td></td>
                      <td style={{ textAlign: "right" }}>Total ₹: </td>
                      <td style={{ textAlign: "center" }}>
                        ₹ {parseFloat(props.bill.total).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td> <td></td> <td></td>
                      <td style={{ textAlign: "right" }}>
                        Initial Payment ₹:{" "}
                      </td>
                      <td style={{ textAlign: "center" }}>
                        ₹{" "}
                        {((parseFloat(props.bill.total) * 10) / 100).toFixed(2)}
                      </td>
                    </tr>
                    <br></br>
                    <Button
                      onClick={() => {
                        downloadPDF();
                        trackEventFunction("Download PDF");
                      }}
                      style={{
                        backgroundColor: "#D32402",
                        marginLeft: "30%",
                        width: "25%",
                        height: 48,
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 900,
                          fontSize: 16,
                          textAlign: "center",
                          color: "#FFFFFF",
                          paddingTop: 5,
                          borderColor: "black",
                        }}
                        className="font"
                      >
                        <DownloadOutlined /> Download your esitimate
                      </p>
                    </Button>
                    <Button
                      size="large"
                      style={{
                        marginLeft: 15,
                        borderColor: "black",
                        width: "20%",
                        height: 48,
                      }}
                      onClick={() => {
                        addOrderDetails();
                        trackEventFunction("Add Order Details");
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 900,
                          fontSize: 16,
                          textAlign: "center",
                          color: "black",
                          paddingTop: 3,
                        }}
                        className="font"
                      >
                        Submit Request
                      </p>
                    </Button>

                    <Col span={24} offset={16} style={{ paddingTop: 40 }}>
                      <Text type="danger">
                        *estimate is for basic colors excluding transportation
                        charges and GST
                      </Text>
                    </Col>
                    <Col span={24} offset={16}>
                      <Text type="danger">
                        *The actual estimation may vary upon your actual
                        requirement
                      </Text>
                    </Col>
                  </div>

                  <br></br>
                </Col>
              </Col>
            </Row>
          </>
        )}
        {!matches && (
          <>
            <Row>
              <Col lg={24} md={24} sm={24} style={{ width: "100%" }}>
                <Text
                  style={{ fontWeight: 600, fontSize: 22, marginLeft: "38%" }}
                >
                  Estimation
                </Text>
              </Col>

              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ width: "100%", marginTop: 24 }}
              >
                <Col lg={24} md={24} sm={24}>
                  <div className="pdf">
                    <Row>
                      <Col lg={24} md={24} sm={24} style={{ marginLeft: 14 }}>
                        <h3>Estimation For:</h3>
                        <Text>{"Date : " + addedDate}</Text>
                        <br></br>
                        <Text>
                          {"RefId : " +
                            "AMSS".concat(
                              parseInt(
                                quotationCounter.substring(
                                  4,
                                  quotationCounter.length
                                )
                              ) + 1
                            )}
                        </Text>
                        <br></br>
                        <Text>{"Name : " + props.bill.customerName}</Text>
                        <br></br>
                        <Text>{"Phone : " + props.bill.customerPhone}</Text>
                        <br></br>
                        <Text>{"Email : " + props.bill.customerEmail}</Text>
                      </Col>
                    </Row>

                    <div className="scrollbar">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          borderBottom: "1px solid gray",

                          width: "1150px",
                          marginTop: 30,
                        }}
                      >
                        <p style={{ fontWeight: "bold", width: 160 }}>
                          Product Name
                        </p>
                        <p style={{ fontWeight: "bold", width: 40 }}>
                          Width (mm)
                        </p>
                        <p style={{ fontWeight: "bold", width: 50 }}>
                          Height (mm)
                        </p>
                        <p style={{ fontWeight: "bold", width: 50 }}>Color</p>
                        <p style={{ fontWeight: "bold", width: 80 }}>
                          Location
                        </p>
                        <p style={{ fontWeight: "bold", width: 80 }}>Type</p>
                        <p style={{ fontWeight: "bold", width: 80 }}>
                          Quantity
                        </p>
                        <p style={{ fontWeight: "bold", width: 80 }}>
                          Total (₹){" "}
                        </p>
                      </div>
                      {Object.entries(props.bill.items).map(([key, value]) => {
                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "space-between",
                              borderBottom: "1px solid gray",
                              width: "1150px",
                              height: "100%",
                              paddingTop: 30,
                            }}
                          >
                            <p style={{ width: 170 }}>
                              {value.productName}({value.productDescription})
                            </p>
                            <p style={{ width: 50 }}>{value.width}</p>
                            <p style={{ width: 50 }}>{value.height}</p>
                            <p style={{ width: 50 }}>{value.color}</p>
                            <p style={{ width: 80 }}>{value.productLocation}</p>
                            <p style={{ width: 80 }}>{value.productType}</p>
                            <p style={{ width: 70 }}>{value.quantity} </p>
                            <p style={{ width: 80 }}>
                              ₹ {parseFloat(value.itemTotal).toFixed(2)} &nbsp;
                              <DeleteFilled
                                style={{ color: "red" }}
                                onClick={() => props.removeFromBill(key)}
                              />
                            </p>
                          </div>
                        );
                      })}
                    </div>

                    <Col span={24}></Col>
                    <div style={{ height: 50 }}></div>

                    <tr>
                      <td style={{ textAlign: "left" }}>Total ₹: </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>

                      <td style={{ textAlign: "right" }}>
                        ₹ {parseFloat(props.bill.total).toFixed(2)}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ textAlign: "left" }}>Initial Payment ₹: </td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td style={{ textAlign: "right" }}>
                        ₹{" "}
                        {((parseFloat(props.bill.total) * 10) / 100).toFixed(2)}
                      </td>
                    </tr>
                    <br></br>
                  </div>
                  <Col
                    lg={{ span: 24 }}
                    md={{ span: 24, offset: 14 }}
                    sm={24}
                    style={{ width: "100%", paddingBottom: 4 }}
                  >
                    <Button
                      size="middle"
                      onClick={() => {
                        downloadPDF();
                        trackEventFunction("Download PDF");
                      }}
                      style={{
                        backgroundColor: "#D32402",
                        marginLeft: 30,
                        height: 35,
                        padding: 5,
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 900,
                          fontSize: 12,
                          textAlign: "center",
                          color: "#FFFFFF",
                          paddingTop: 2,
                        }}
                        className="font"
                      >
                        <DownloadOutlined /> Download your esitimate
                      </p>
                    </Button>
                    <Button
                      size="middle"
                      style={{ marginLeft: 25, borderColor: "black" }}
                      onClick={() => {
                        addOrderDetails();
                        trackEventFunction("Add Order Details");
                      }}
                    >
                      <p
                        style={{
                          fontWeight: 500,
                          fontSize: 12,
                          textAlign: "center",
                          color: "black",
                          paddingTop: 2,
                        }}
                        className="font"
                      >
                        Submit Request
                      </p>
                    </Button>
                  </Col>
                  <Col span={24}>
                    <Text type="danger">
                      *estimate is for basic colors excluding transportation
                      charges and GST
                    </Text>
                  </Col>
                  <Col span={24}>
                    <Text type="danger">
                      *The actual estimation may vary upon your actual
                      requirement
                    </Text>
                  </Col>

                  <br></br>
                </Col>
              </Col>
            </Row>
          </>
        )}

        <Footers />
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    bill: state.bill,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    removeFromBill: (data) => dispatch({ type: "REMOVE_ITEM", payload: data }),
    setBillItems: (data) => dispatch({ type: "SET_BILL_ITEMS", payload: data }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(QuotationDetails);
