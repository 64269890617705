import React from "react";
import "./map.css";
import mapImage from "../Images/map.jpg"; // Import the map image file here

function App() {
  return (
    <div className="container">
      {/* Header Section */}
      <header>
        <h1>WE ARE HERE FOR YOU FROM START TO FINISH</h1>
      </header>

      {/* Presence Section */}
      <section className="presence-section">
        <h2>OUR PRESENCE</h2>
        <p>
          We have executed a wide range of projects covering complex, iconic,
          tall, and interesting designs. Our projects are spread across Chennai
          and Indore. We bring rich experiences through challenging projects.
        </p>
      </section>

      {/* Map Section */}
      <section className="map-section">
        <img
          src={mapImage}
          alt="India Map with Branch Locations"
          className="map-image"
        />
        {/* Legend */}
      </section>
    </div>
  );
}

export default App;
