import React from "react";
// import { Layout } from "antd";
import "../App.less";

import Footers from "./footer";
import RightBar from "./rightBar";
import Whatsapp from "./whatsapp";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Col, Row, Button, Dropdown, Menu, Space, Card, Tabs } from "antd";

import data from "./productJson.json";
import { Player, ControlBar } from "video-react";
import "../Styles/videoReact.css";
const button = ["Home", "About Us", "Products", "Projects", "Contact"];

//class part state they holds the data this page
function Products(props) {
  const location = useLocation();

  const navigation = useNavigate();
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );

  const [Location, settLocation] = useState("Doors");
  const [productLocation, setproductLocation] = useState("");
  const [subProduct, setsubProduct] = useState("");
  const [video, setVideo] = useState(
    "https://allmeshimages.s3.ap-south-1.amazonaws.com/productTypeImages/Product8.mp4"
  );

  useEffect(() => {
    settLocation("Doors");

    if (location.state.data === "Doors") {
      settLocation("Doors");
      setproductLocation("Openable");
      setsubProduct("Scuttlo");
    } else if (location.state.data === "Windows") {
      settLocation("Windows");
      setproductLocation("Openable");
      setsubProduct("Simplo");
    } else if (location.state.data === "Balcony") {
      settLocation("Balcony");
      setproductLocation("Mesh");
      setsubProduct("Bird Mesh");
    } else if (location.state.data === "Security") {
      settLocation("Security");
      setproductLocation("Doors");
      setsubProduct("Safetec");
    }

    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, [location.state.data]);

  var Url =
    "data.productLocationTypeMap[Location].value[productLocation].value" +
    video;

  const onChange = (key) => {
    console.log(key);
  };

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                settLocation("Doors");
                setsubProduct("");
              }}
            >
              <p>Doors</p>
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              onClick={() => {
                settLocation("Windows");
                setsubProduct("");
              }}
            >
              <p>Windows</p>
            </a>
          ),
        },
        {
          key: "3",
          label: (
            <a
              onClick={() => {
                settLocation("Balcony");
                setsubProduct("");
              }}
            >
              <p>Balcony</p>
            </a>
          ),
        },
        {
          key: "4",

          label: (
            <a
              onClick={() => {
                settLocation("Security");
                setsubProduct("");
              }}
            >
              <p>Security</p>
            </a>
          ),
        },
      ]}
    />
  );

  const DoorOpenable = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("Scuttlo");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Scuttlo"].Video
                );
              }}
            >
              <p>Scuttlo</p>
            </a>
          ),
        },
      ]}
    />
  );
  const DoorSliding = (
    <Menu
      items={[
        {
          key: "3",
          label: (
            <a
              onClick={() => {
                setsubProduct("Slido");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Slido"].Video
                );
              }}
            >
              <p>Slido</p>
            </a>
          ),
        },
      ]}
    />
  );

  const DoorPleated = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("Pleato");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Pleato"].Video
                );
              }}
            >
              <p>Pleato</p>
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              onClick={() => {
                setsubProduct("Pleato Plus");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Pleato Plus"].Video
                );
              }}
            >
              <p>Pleato Plus</p>
            </a>
          ),
        },
        {
          key: "3",
          label: (
            <a
              onClick={() => {
                setsubProduct("Pleato Power");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Pleato Power"].Video
                );
              }}
            >
              <p>Pleato Power</p>
            </a>
          ),
        },
        {
          key: "4",
          label: (
            <a
              onClick={() => {
                setsubProduct("Pleato Centra");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Pleato Centra"].Video
                );
              }}
            >
              <p>Pleato Centra</p>
            </a>
          ),
        },
        {
          key: "5",
          label: (
            <a
              onClick={() => {
                setsubProduct("Mesh + Blind");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Mesh + Blind"].Video
                );
              }}
            >
              <p>Mesh + Blind</p>
            </a>
          ),
        },
      ]}
    />
  );

  const BalconyMotorizer = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("MotoScreens Mesh");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["MotoScreens Mesh"].Video
                );
              }}
            >
              <p>MotoScreens Mesh</p>
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              onClick={() => {
                setsubProduct("MotoScreens Blinds");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["MotoScreens Blinds"].Video
                );
              }}
            >
              <p>MotoScreens Blinds</p>
            </a>
          ),
        },
      ]}
    />
  );

  const BalconyZipline = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("Zipline");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Zipline"].Video
                );
              }}
            >
              <p>Zipline</p>
            </a>
          ),
        },
      ]}
    />
  );

  const WindowOpenable = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("Simplo");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Simplo"].Video
                );
              }}
            >
              <p>Simplo</p>
            </a>
          ),
        },
        // {
        //   key: "2",
        //   label: (
        //     <a
        //       onClick={() => {
        //         setsubProduct("Simplo Pro");
        //         setVideo(
        //           data.productLocationTypeMap[Location]
        //             .value[productLocation].value["Simplo Pro"].Video)
        //       }}
        //     >
        //       <p>Simplo Pro</p>
        //     </a>
        //   ),
        // },
      ]}
    />
  );

  const WindowSliding = (
    <Menu
      items={[
        {
          key: "3",
          label: (
            <a
              onClick={() => {
                setsubProduct("Slido");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Slido"].Video
                );
              }}
            >
              <p>Slido</p>
            </a>
          ),
        },
        {
          key: "3",
          label: (
            <a
              onClick={() => {
                setsubProduct("Vertica");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Vertica"].Video
                );
              }}
            >
              <p>Vertica</p>
            </a>
          ),
        },
      ]}
    />
  );

  const WindowPleato = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("Pleato");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Pleato"].Video
                );
              }}
            >
              <p>Pleato</p>
            </a>
          ),
        },
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("Pleato Plus");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Pleato Plus"].Video
                );
              }}
            >
              <p>Pleato Plus</p>
            </a>
          ),
        },
      ]}
    />
  );

  const BalconyMesh = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("Bird Mesh");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Bird Mesh"].Video
                );
              }}
            >
              <p>Bird Mesh</p>
            </a>
          ),
        },
      ]}
    />
  );

  const BalconyPleato = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("Pleato Power");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Pleato Power"].Video
                );
              }}
            >
              <p>Pleato Power</p>
            </a>
          ),
        },
      ]}
    />
  );

  const SecurityDoor = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("Safetec");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Safetec"].Video
                );
              }}
            >
              <p>Safetec</p>
            </a>
          ),
        },
        {
          key: "2",
          label: (
            <a
              onClick={() => {
                setsubProduct("Protekt");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Protekt"].Video
                );
              }}
            >
              <p>Protekt</p>
            </a>
          ),
        },
      ]}
    />
  );

  const SecurityWindows = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("Safetec");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Safetec"].Video
                );
              }}
            >
              <p>Safetec</p>
            </a>
          ),
        },
      ]}
    />
  );

  const FullHomeSecurity = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <a
              onClick={() => {
                setsubProduct("Safetec");
                setVideo(
                  data.productLocationTypeMap[Location].value[productLocation]
                    .value["Safetec"].Video
                );
              }}
            >
              <p>Safetec</p>
            </a>
          ),
        },
      ]}
    />
  );

  return (
    <div className="fill-window">
      <>
        {matches && (
          <>
            {" "}
            <Row>
              <Card
                style={{
                  height: 84,
                  width: "100%",
                  backgroundColor: "#F5FDFF",
                  position: "sticky",
                  top: 0,
                }}
                className="header"
              >
                <Col lg={24} md={24} sm={24}>
                  <Dropdown
                    overlay={menu}
                    // onClick={goTo.bind(this, value)}

                    style={{ paddingRight: "5%" }}
                  >
                    <a>
                      <Space style={{ marginLeft: "5%" }}>
                        <h1
                          style={{
                            // color:
                            //   buttonLocation == value ? "#D32402" : "black",
                            //  color:hover?"red":"black",
                            fontWeight: 800,
                            fontSize: 18,
                          }}
                        >
                          Product Category - {Location}&nbsp;&nbsp;
                        </h1>
                      </Space>
                    </a>
                  </Dropdown>
                  <Col style={{ bottom: 38, marginLeft: "24%" }}>
                    {Object.entries(
                      data.productLocationTypeMap[Location].value
                    ).map(([key, value]) => {
                      return (
                        <>
                          <Dropdown
                            trigger={["click"]}
                            overlay={
                              productLocation === "Openable" &&
                              Location === "Doors"
                                ? DoorOpenable
                                : productLocation === "Sliding" &&
                                  Location === "Doors"
                                ? DoorSliding
                                : productLocation === "Pleated" &&
                                  Location === "Doors"
                                ? DoorPleated
                                : productLocation === "Motorzied" &&
                                  Location === "Balcony"
                                ? BalconyMotorizer
                                : productLocation === "Zipline" &&
                                  Location === "Balcony"
                                ? BalconyZipline
                                : productLocation === "Openable" &&
                                  Location === "Windows"
                                ? WindowOpenable
                                : productLocation === "Sliding" &&
                                  Location === "Windows"
                                ? WindowSliding
                                : productLocation === "Pleated" &&
                                  Location === "Windows"
                                ? WindowPleato
                                : productLocation === "Mesh" &&
                                  Location === "Balcony"
                                ? BalconyMesh
                                : productLocation === "Pleato" &&
                                  Location === "Balcony"
                                ? BalconyPleato
                                : productLocation === "Doors" &&
                                  Location === "Security"
                                ? SecurityDoor
                                : productLocation === "Windows" &&
                                  Location === "Security"
                                ? SecurityWindows
                                : productLocation === "Full Home Security" &&
                                  Location === "Security"
                                ? FullHomeSecurity
                                : ""
                            }
                            onClick={() => {
                              setproductLocation(key);
                              setsubProduct("");
                            }}
                          >
                            <a>
                              <Space style={{ paddingRight: "5%" }}>
                                <h1
                                  style={{
                                    color:
                                      productLocation == key
                                        ? "#D32402"
                                        : "black",
                                    //  color:hover?"red":"black",
                                    fontWeight: 800,
                                    fontSize: 18,
                                  }}
                                >
                                  {key}
                                </h1>
                              </Space>
                            </a>
                          </Dropdown>
                        </>
                      );
                    })}
                  </Col>
                </Col>
              </Card>
            </Row>
            <Row style={{ height: "1%" }}>
              <Col lg={12} md={12} sm={24}>
                <div
                  style={{
                    width: "100%",

                    // height: "100%",
                    paddingBottom: "5%",
                    paddingRight: "5%",
                    paddingLeft: "10%",
                    paddingTop: "8%",
                  }}
                >
                  <div style={{ width: "100%", height: "100%" }}>
                    <Player
                      className="video"
                      fluid={false}
                      autoPlay={true}
                      playsInline
                      // src= {productLocation &&
                      //   subProduct &&
                      //   data.productLocationTypeMap[Location]
                      //     .value[productLocation].value[subProduct].Video}
                      src={video}
                      muted={true}
                      // width={660}
                      height={463}
                      width="95%"
                      style={{
                        objectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                    >
                      <ControlBar autoHide={false} className="my-class" />
                    </Player>
                  </div>
                </div>
              </Col>

              <Col
                lg={12}
                md={12}
                sm={24}
                style={{ width: "100%", height: "100%" }}
              >
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ width: "100%", height: "100%" }}
                >
                  <div style={{ width: "100%", height: "100%" }}>
                    <Col
                      lg={24}
                      md={24}
                      sm={24}
                      style={{ width: "100%", height: "100%" }}
                    >
                      <h3
                        className="font"
                        style={{
                          fontSize: 32,
                          fontWeight: 500,
                          paddingTop: "17%",
                        }}
                      >
                        {productLocation &&
                          subProduct &&
                          data.productLocationTypeMap[Location].value[
                            productLocation
                          ].value[subProduct].Tittle}
                      </h3>
                      <h4
                        className="font"
                        style={{
                          fontSize: 22,
                          fontWeight: 700,
                          paddingTop: "5%",
                        }}
                      >
                        Key Features:
                      </h4>
                      <p
                        className="font"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          paddingTop: "2%",
                        }}
                      >
                        Max. Height:{" "}
                        {productLocation &&
                          subProduct &&
                          data.productLocationTypeMap[Location].value[
                            productLocation
                          ].value[subProduct].Height}
                      </p>
                      <p
                        className="font"
                        style={{
                          fontSize: 16,
                          fontWeight: 400,
                          paddingTop: "1%",
                        }}
                      >
                        Max. Width:{" "}
                        {productLocation &&
                          subProduct &&
                          data.productLocationTypeMap[Location].value[
                            productLocation
                          ].value[subProduct].Width}
                      </p>
                      {productLocation &&
                        subProduct &&
                        data.productLocationTypeMap[Location].value[
                          productLocation
                        ].value[subProduct].meshColor && (
                          <>
                            <h4
                              className="font"
                              style={{
                                fontSize: 22,
                                fontWeight: 700,
                                paddingTop: "1%",
                              }}
                            >
                              Mesh Color:
                            </h4>
                            {productLocation &&
                              subProduct &&
                              data.productLocationTypeMap[Location].value[
                                productLocation
                              ].value[subProduct].meshColor.map((item) => {
                                return (
                                  <Button
                                    size="large"
                                    shape="circle"
                                    style={{
                                      backgroundColor: item,
                                      marginLeft: 5,
                                      borderColor: "#E2E2E2",
                                      borderWidth: 2,
                                    }}
                                  >
                                    {" "}
                                  </Button>
                                );
                              })}
                          </>
                        )}
                      {productLocation &&
                        subProduct &&
                        data.productLocationTypeMap[Location].value[
                          productLocation
                        ].value[subProduct].frameColor && (
                          <>
                            <h4
                              className="font"
                              style={{
                                fontSize: 22,
                                fontWeight: 700,
                                paddingTop: "1%",
                              }}
                            >
                              Frame Color:
                            </h4>
                            {productLocation &&
                              subProduct &&
                              data.productLocationTypeMap[Location].value[
                                productLocation
                              ].value[subProduct].frameColor.map((item) => {
                                return (
                                  <Button
                                    size="large"
                                    shape="circle"
                                    style={{
                                      backgroundColor: item,
                                      marginLeft: 5,
                                      borderColor: "#E2E2E2",
                                      borderWidth: 2,
                                    }}
                                  >
                                    {" "}
                                  </Button>
                                );
                              })}
                          </>
                        )}
                    </Col>
                    <Col
                      lg={24}
                      md={24}
                      sm={24}
                      style={{
                        width: "100%",
                        height: "100%",
                        paddingTop: "3%",
                      }}
                    >
                      <p style={{ color: "red" }}>
                        * Customize color also avaiable
                      </p>
                    </Col>
                  </div>
                </Col>
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{
                  width: "100%",
                  height: "30%",
                  //backgroundColor: "#F5FDFF",
                  paddingLeft: "8%",
                  paddingRight: "8%",
                  paddingBottom: "8%",
                  paddingTop: "5%",
                }}
              >
                <Tabs
                  defaultActiveKey="1"
                  onChange={onChange}
                  tabBarGutter={67}
                  tabBarStyle={{ color: "#8D949C" }}
                  items={[
                    {
                      label: (
                        <h3
                          className="font"
                          style={{ fontSize: 16, fontWeight: 600 }}
                        >
                          Key Benefits
                        </h3>
                      ),
                      key: "2",
                      children: (
                        <h2 style={{ padding: 10 }}>
                          {productLocation &&
                            subProduct &&
                            data.productLocationTypeMap[Location].value[
                              productLocation
                            ].value[subProduct].Key1}{" "}
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          {productLocation &&
                            subProduct &&
                            data.productLocationTypeMap[Location].value[
                              productLocation
                            ].value[subProduct].Key2}
                          &nbsp; &nbsp;&nbsp; &nbsp;
                          {productLocation &&
                            subProduct &&
                            data.productLocationTypeMap[Location].value[
                              productLocation
                            ].value[subProduct].Key3}
                        </h2>
                      ),
                    },
                  ]}
                />
                {/* <Tabs defaultActiveKey="1">
 <Tabs.TabPane tab="Tab 1" key="1" >
   <p Content of Tab Pane ></p>
 </Tabs.TabPane>
 <Tabs.TabPane tab="Tab 2" key="2">
   Content of Tab Pane 2
 </Tabs.TabPane>
 <Tabs.TabPane tab="Tab 3" key="3">
   Content of Tab Pane 3
 </Tabs.TabPane>
</Tabs> */}
              </Col>
            </Row>
          </>
        )}

        {!matches && (
          <>
            {" "}
            <Row>
              <Card
                style={{
                  height: 84,
                  width: "100%",
                  backgroundColor: "#F5FDFF",
                  position: "sticky",
                  top: 0,
                }}
                className="header"
              >
                <Dropdown
                  overlay={menu}
                  // onClick={goTo.bind(this, value)}

                  style={{ paddingRight: "5%" }}
                >
                  <a>
                    <Space style={{ marginLeft: "5%", marginTop: -15 }}>
                      <h1
                        style={{
                          // color:
                          //   buttonLocation == value ? "#D32402" : "black",
                          //  color:hover?"red":"black",
                          fontWeight: 800,
                          fontSize: 18,
                        }}
                      >
                        Product Category
                      </h1>
                    </Space>
                  </a>
                </Dropdown>
                <Col lg={24} md={24} sm={24} style={{ paddingLeft: "1%" }}>
                  {Object.entries(
                    data.productLocationTypeMap[Location].value
                  ).map(([key, value]) => {
                    return (
                      <>
                        <Dropdown
                          trigger={["click"]}
                          overlay={
                            productLocation === "Openable" &&
                            Location === "Doors"
                              ? DoorOpenable
                              : productLocation === "Sliding" &&
                                Location === "Doors"
                              ? DoorSliding
                              : productLocation === "Pleated" &&
                                Location === "Doors"
                              ? DoorPleated
                              : productLocation === "Motorzied" &&
                                Location === "Balcony"
                              ? BalconyMotorizer
                              : productLocation === "Zipline" &&
                                Location === "Balcony"
                              ? BalconyZipline
                              : productLocation === "Openable" &&
                                Location === "Windows"
                              ? WindowOpenable
                              : productLocation === "Sliding" &&
                                Location === "Windows"
                              ? WindowSliding
                              : productLocation === "Pleated" &&
                                Location === "Windows"
                              ? WindowPleato
                              : productLocation === "Mesh" &&
                                Location === "Balcony"
                              ? BalconyMesh
                              : productLocation === "Pleato" &&
                                Location === "Balcony"
                              ? BalconyPleato
                              : productLocation === "Doors" &&
                                Location === "Security"
                              ? SecurityDoor
                              : productLocation === "Windows" &&
                                Location === "Security"
                              ? SecurityWindows
                              : productLocation === "Full Home Security" &&
                                Location === "Security"
                              ? FullHomeSecurity
                              : ""
                          }
                          onClick={() => {
                            setproductLocation(key);
                            setsubProduct("");
                          }}
                        >
                          <a>
                            <Space style={{ paddingRight: "5%" }}>
                              <h1
                                style={{
                                  color:
                                    productLocation == key
                                      ? "#D32402"
                                      : "black",
                                  //  color:hover?"red":"black",
                                  fontWeight: 700,
                                  fontSize: 14,
                                }}
                              >
                                {key}
                              </h1>
                            </Space>
                          </a>
                        </Dropdown>
                      </>
                    );
                  })}
                </Col>
              </Card>
            </Row>
            <Row>
              <Col lg={24} md={24} sm={24}>
                <div
                // style={{
                //   width: "100%",

                //   // height: "100%",
                //   paddingBottom: "5%",
                //   paddingRight: "5%",
                //   paddingLeft: "10%",
                //   paddingTop: "10%",
                // }}
                ></div>
              </Col>

              <Col
                lg={24}
                md={24}
                sm={24}
                style={{ width: "100%", height: "100%" }}
              >
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{
                    width: "100%",
                    height: "100%",
                    paddingRight: "3%",
                    paddingLeft: "3%",
                    marginTop: "10%",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      height: "100%",
                      paddingRight: "3%",
                      paddingLeft: "3%",
                    }}
                  >
                    <Player
                      className="video"
                      fluid={false}
                      autoPlay={true}
                      playsInline
                      src={video}
                      muted={true}
                      // width={660}
                      height={363}
                      width="100%"
                      style={{
                        objectFit: "cover",
                        width: "auto",
                        height: "auto",
                      }}
                    >
                      <ControlBar autoHide={false} className="my-class" />
                    </Player>
                  </div>
                </Col>
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ width: "100%", height: "100%" }}
                >
                  <div style={{ width: "100%", height: "100%" }}>
                    <Col
                      lg={24}
                      md={24}
                      sm={24}
                      style={{
                        width: "100%",
                        height: "100%",
                        paddingLeft: "2%",
                      }}
                    >
                      <h3
                        className="font"
                        style={{
                          fontSize: 20,
                          fontWeight: 500,
                          paddingTop: "8%",
                          paddingLeft: "5%",
                        }}
                      >
                        {productLocation &&
                          subProduct &&
                          data.productLocationTypeMap[Location].value[
                            productLocation
                          ].value[subProduct].Tittle}
                      </h3>
                      <h4
                        className="font"
                        style={{
                          fontSize: 18,
                          fontWeight: 650,
                          paddingTop: "2%",
                          paddingLeft: "5%",
                        }}
                      >
                        Key Features:
                      </h4>
                      <p
                        className="font"
                        style={{
                          fontSize: 12,
                          fontWeight: 400,
                          paddingTop: "2%",
                          paddingLeft: "5%",
                        }}
                      >
                        Max. Height:{" "}
                        {productLocation &&
                          subProduct &&
                          data.productLocationTypeMap[Location].value[
                            productLocation
                          ].value[subProduct].Height}
                      </p>
                      <p
                        className="font"
                        style={{
                          fontSize: 14,
                          fontWeight: 400,
                          paddingTop: "1%",
                          paddingLeft: "5%",
                        }}
                      >
                        Max. Width:{" "}
                        {productLocation &&
                          subProduct &&
                          data.productLocationTypeMap[Location].value[
                            productLocation
                          ].value[subProduct].Width}
                      </p>
                      {productLocation &&
                        subProduct &&
                        data.productLocationTypeMap[Location].value[
                          productLocation
                        ].value[subProduct].meshColor && (
                          <>
                            <h4
                              className="font"
                              style={{
                                fontSize: 18,
                                fontWeight: 650,
                                paddingTop: "1%",
                                paddingLeft: "5%",
                              }}
                            >
                              Mesh Color:
                            </h4>
                            {productLocation &&
                              subProduct &&
                              data.productLocationTypeMap[Location].value[
                                productLocation
                              ].value[subProduct].meshColor.map((item) => {
                                return (
                                  <Button
                                    size="middle"
                                    shape="circle"
                                    style={{
                                      backgroundColor: item,
                                      marginLeft: 5,
                                      borderColor: "#E2E2E2",
                                      borderWidth: 2,
                                      marginLeft: "5%",
                                    }}
                                  >
                                    {" "}
                                  </Button>
                                );
                              })}
                          </>
                        )}
                      {productLocation &&
                        subProduct &&
                        data.productLocationTypeMap[Location].value[
                          productLocation
                        ].value[subProduct].frameColor && (
                          <>
                            <h4
                              className="font"
                              style={{
                                fontSize: 18,
                                fontWeight: 650,
                                paddingTop: "1%",
                                paddingLeft: "5%",
                              }}
                            >
                              Frame Color:
                            </h4>
                            {productLocation &&
                              subProduct &&
                              data.productLocationTypeMap[Location].value[
                                productLocation
                              ].value[subProduct].frameColor.map((item) => {
                                return (
                                  <Button
                                    size="middle"
                                    shape="circle"
                                    style={{
                                      backgroundColor: item,
                                      marginLeft: 5,
                                      borderColor: "#E2E2E2",
                                      borderWidth: 2,
                                      marginLeft: "5%",
                                    }}
                                  >
                                    {" "}
                                  </Button>
                                );
                              })}
                          </>
                        )}
                    </Col>
                  </div>
                </Col>
              </Col>
              <Col
                lg={24}
                md={24}
                sm={24}
                style={{
                  width: "100%",
                  height: "30%",
                  //backgroundColor: "#F5FDFF",
                  paddingLeft: "10%",
                  paddingRight: "10%",
                  paddingBottom: "8%",
                  paddingTop: "5%",
                }}
              >
                <Tabs
                  defaultActiveKey="1"
                  onChange={onChange}
                  tabBarGutter={67}
                  tabBarStyle={{ color: "#8D949C" }}
                  items={[
                    {
                      label: (
                        <h3
                          className="font"
                          style={{ fontSize: 16, fontWeight: 600 }}
                        >
                          Key Benefits
                        </h3>
                      ),
                      key: "2",
                      children: (
                        <h3 style={{ padding: 10 }}>
                          {productLocation &&
                            subProduct &&
                            data.productLocationTypeMap[Location].value[
                              productLocation
                            ].value[subProduct].Key1}{" "}
                          <br></br>
                          {productLocation &&
                            subProduct &&
                            data.productLocationTypeMap[Location].value[
                              productLocation
                            ].value[subProduct].Key2}
                          <br></br>
                          {productLocation &&
                            subProduct &&
                            data.productLocationTypeMap[Location].value[
                              productLocation
                            ].value[subProduct].Key3}
                        </h3>
                      ),
                    },
                  ]}
                />
                {/* <Tabs defaultActiveKey="1">
    <Tabs.TabPane tab="Tab 1" key="1" >
      <p Content of Tab Pane ></p>
    </Tabs.TabPane>
    <Tabs.TabPane tab="Tab 2" key="2">
      Content of Tab Pane 2
    </Tabs.TabPane>
    <Tabs.TabPane tab="Tab 3" key="3">
      Content of Tab Pane 3
    </Tabs.TabPane>
  </Tabs> */}
              </Col>
            </Row>
          </>
        )}

        <RightBar />
        <Whatsapp />

        <Footers />
      </>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bill: state.bill,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setBillItems: (data) => dispatch({ type: "SET_BILL_ITEMS", payload: data }),
    setJSON: (data) => dispatch({ type: "SET_JSON", payload: data }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Products);
