import React, { Component } from "react";
import { AntDesignOutlined } from "@ant-design/icons";
import { Card, Typography, Row, Col, Avatar } from "antd";
import "../App.less";
import environment from "../configure";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const { Title } = Typography;

class Testimonal extends Component {
  state = {
    status: 0,
    testimonialDetails: [],
    matches: window.matchMedia("(min-width: 512px)").matches,
  };

  componentDidMount() {
    const handler = (e) => this.setState({ matches: e.matches });
    window.matchMedia("(min-width: 512px)").addEventListener("change", handler);
    this.fetchTestimonial();
  }
  async fetchTestimonial() {
    try {
      var fetchedDetails = await fetch(
        environment.apiGateway + "fetchProductDetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "Testimonial",
          }),
        }
      );
      var fetchedRespone = await fetchedDetails.json();
      this.setState({ testimonialDetails: fetchedRespone.Items });
    } catch (err) {
      return err;
    }
  }

  render() {
    return (
      <div>
        {this.state.matches && (
          <Row style={{ backgroundColor: "white" }}>
            <Col lg={24} md={24} sm={24}>
              <Row>
                <Card
                  bordered={false}
                  style={{
                    width: "100%",
                    height: "100%",
                    marginTop: 50,
                    backgroundColor: "#F5FDFF",
                  }}
                >
                  <Col lg={24} md={24} sm={24}>
                    <Title
                      align="center"
                      className="font"
                      style={{ fontWeight: 700, marginTop: 50 }}
                    >
                      CLIENT TESTIMONALS
                    </Title>
                  </Col>
                  <Col lg={24} md={24} sm={24}>
                    <Carousel
                      showStatus={false}
                      showArrows={true}
                      style={{
                        color: "black",
                        height: 720,
                        width: "100%",
                      }}
                    >
                      {this.state.testimonialDetails.map((item) => {
                        return (
                          <Row>
                            <Col lg={1} md={1} sm={1} offset={4}>
                              <Avatar
                                src={
                                  process.env.PUBLIC_URL +
                                  "/asset/download.jpeg"
                                }
                                size={150}
                                icon={<AntDesignOutlined />}
                                style={{ marginTop: 70 }}
                              />
                            </Col>
                            <Col lg={12} md={12} sm={12} offset={3}>
                              <p
                                className="font"
                                align="left"
                                style={{
                                  fontWeight: 700,
                                  fontSize: 24,
                                  paddingTop: 30,
                                }}
                              >
                                {item.text1}
                              </p>
                              <p
                                className="font"
                                align="left"
                                style={{ fontWeight: 400, fontSize: 18 }}
                              >
                                {item.text2}
                              </p>
                              <h3
                                className="font"
                                align="left"
                                style={{ fontWeight: 500, fontSize: 14 }}
                              >
                                {item.text3}
                              </h3>
                              <h1>&nbsp;</h1>
                            </Col>
                          </Row>
                        );
                      })}
                    </Carousel>
                  </Col>
                </Card>
              </Row>

              <br></br>
              <br></br>
            </Col>
          </Row>
        )}

        {!this.state.matches && (
          <>
            <Row>
              <Col lg={24} md={24} sm={24} style={{ width: "100%" }}>
                <Card
                  bordered={false}
                  style={{
                    width: "100%",
                    backgroundColor: "#F5FDFF",
                  }}
                >
                  <Col lg={24} md={24} sm={24} style={{ marginTop: 50 }}>
                    <Title
                      align="center"
                      className="font"
                      style={{
                        fontWeight: 700,
                        alignContent: "center",
                        fontSize: 22,
                        textAlign: "center",
                      }}
                    >
                      CLIENT TESTIMONALS
                    </Title>
                  </Col>
                  <Carousel
                    showStatus={false}
                    style={{
                      height: "400px",
                      color: "black",
                      lineHeight: "30px",
                      width: "100%",
                      marginTop: 10,
                    }}
                  >
                    {this.state.testimonialDetails.map((item) => {
                      return (
                        <Row>
                          <Col
                            lg={24}
                            md={24}
                            sm={24}
                            style={{ marginTop: 30 }}
                          >
                            <Avatar
                              src={
                                process.env.PUBLIC_URL + "/asset/download.jpeg"
                              }
                              size={181}
                              icon={<AntDesignOutlined />}
                              style={{
                                justifyContent: "center",
                                alignSelf: "center",
                                marginLeft: "50%",
                              }}
                            />
                          </Col>
                          <Row>
                            <Col
                              lg={24}
                              md={24}
                              sm={24}
                              style={{ width: "100%", marginTop: 30 }}
                            >
                              <Title
                                align="center"
                                className="font"
                                style={{
                                  fontWeight: 700,
                                  fontSize: 24,
                                  textAlign: "center",
                                }}
                              >
                                {item.text1}
                              </Title>
                            </Col>
                            <Col lg={24} md={24} sm={24}>
                              <p
                                className="font"
                                style={{
                                  fontWeight: 400,
                                  fontSize: 18,
                                  textAlign: "center",
                                }}
                              >
                                {item.text2}
                              </p>
                            </Col>
                            <Col
                              lg={24}
                              md={24}
                              sm={24}
                              style={{ width: "100%" }}
                            >
                              <h3
                                className="font"
                                align="center"
                                style={{ fontWeight: 500, fontSize: 14 }}
                              >
                                {item.text3}
                              </h3>
                            </Col>
                            <h1>&nbsp;</h1>
                          </Row>
                        </Row>
                      );
                    })}
                  </Carousel>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

export default Testimonal;
