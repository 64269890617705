import React, { useState, useEffect, useRef } from "react";
import "../App.less";
import { Col, Row, Card, Typography, } from "antd";
import Images2 from "../Images/ceo.png";
const { Title, Text } = Typography;

//class part state they holds the data this page
function Header(props) {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );


  useEffect(() => {    
    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));
   
  }, []);

  return (
    <div>
      <Row>
        {/* Desktop View */}
        {matches && (
          <>
            {" "}
           

            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ marginTop: 50, paddingLeft: "8%", paddingRight: "8%",width:'100%' }}
            >
              <Card
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + Images2})`,
                 height: "70vh",
                  width: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              >
                <h1
                style={{
                  marginLeft: "1%",
                  fontSize: 22,
                  fontWeight: 400,
                  marginTop: "12%",
                }}
              >
                 AllMesh team is passionate to give customers a smooth mesh
                ordering experience. Everytime its not only the cost which
                matters but also the expertise of the team. Headed by able CEO
                allMesh team in total has 20+ years of experience in the
                industry. Please be assured the team is awaiting for your orders
                with enough stock in the factory and eagerness to give the best
                customer experience in the Industry.
              </h1>
              </Card>
            </Col>
          </>
        )}
        {/* Mobile View */}
        {!matches && (
          <>
            {" "}
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ marginTop: 10, width: "100%" }}
            >
             
            </Col>
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{
                marginTop: 30,
                width: "100%",
                paddingLeft: 30,
                paddingRight: 30,
              }}
            >
              <Card
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + Images2})`,
                  height: "72vh",
                  width: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></Card>
            </Col>
            <Col
              lg={12}
              md={12}
              sm={24}
              style={{ marginTop: 25, paddingLeft: 30, paddingRight: 30 }}
            >
              <p
                align="left"
                style={{
                  fontSize: 16,
                  fontWeight: 400,
                  marginTop: 25,
                }}
              >
                 AllMesh team is passionate to give customers a smooth mesh
                ordering experience. Everytime its not only the cost which
                matters but also the expertise of the team. Headed by able CEO
                AllMesh team in total has 20+ years of experience in the
                industry. Please be assured the team is awaiting for your orders
                with enough stock in the factory and eagerness to give the best
                customer experience in the Industry.
              </p>
            </Col>
          </>
        )}
      </Row>
    </div>
  );
}

export default Header;
