import React, { useState, useEffect } from "react";
import "../App.less";
import { useNavigate } from "react-router-dom";
import { Col, Row, Card, Typography, Descriptions } from "antd";
import environment from "../configure";
import Footers from "./footer";
import Contact from "./contact";
import RightBar from "./rightBar";
import Whatsapp from "./whatsapp";
import Team from "./team";
import Images2 from "../Images/ContactUs.png";
import "react-multi-carousel/lib/styles.css";

const { Title } = Typography;
//class part state they holds the data this page
function AboutUs(props) {
  const navigation = useNavigate();
  const [bannerDetails, setbannerDetails] = useState("");

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });

    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));
    fetchBanners();
  }, []);

  const fetchBanners = async () => {
    // alert("fetchBanners");
    try {
      var fetchedDetails = await fetch(
        environment.apiGateway + "fetchProductDetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "Banner",
          }),
        }
      );
      var fetchedRespone = await fetchedDetails.json();
      let res = fetchedRespone.Items;
      setbannerDetails(res);
    } catch (err) {
      // alert(err);
      return err;
    }
  };

  function CustomRightArrow({ onClick }) {
    function handleClick() {
      // do whatever you want on the right button click
      // console.log("Right button clicked, go to next slide");
      // ... and don't forget to call onClick to slide
      onClick();
    }

    return (
      <button
        onClick={handleClick}
        aria-label="Go to next slide"
        className="react-multiple-carousel__arrow react-multiple-carousel__arrow--right"
      />
    );
  }

  return (
    <div className="fill-window">
      {matches && (
        <>
          <Row>
            <RightBar />
            <Whatsapp />

            <Col lg={12} md={12} sm={24} style={{ marginTop: "3.5%" }}>
              <Title
                style={{
                  marginLeft: 60,
                  color: "red",
                  fontSize: 35,
                  fontWeight: 700,
                }}
                className="font"
              >
                ABOUT US
              </Title>
              <p
                style={{
                  marginLeft: 60,
                  fontSize: 18,
                  fontWeight: 400,
                  marginTop: "3.5%",
                }}
              >
                <p>
                  In order to offer long-lasting solutions for a variety of mesh
                  solutions for the home and office, such as security doors,
                  windows, insect screens, and blinds, AllMesh Secure Solutions
                  began business operations. AllMesh firmly believes in offering
                  high-quality products and professional solutions after fully
                  comprehending the needs of the customer.
                </p>
                <p>
                  {" "}
                  With a focus on seamless door solutions, AllMesh offers
                  exceptional pricing on security mesh window screens, sliding
                  security doors, fly screens, pet doors, and much more to home
                  and business owners across India.
                </p>
                Our goal is to give our customers the information, planning, and
                preparation they need to better protect themselves from
                intruder-based crimes. We also offer installation services to
                assist reduce weak places frequently utilised to gain access to
                properties.
                <p
                  style={{ color: "#D32402", fontWeight: 700, marginTop: "1%" }}
                >
                  {" "}
                  " If you need Mesh - Remember AllMesh "
                </p>
              </p>
            </Col>
            <Col
              lg={12}
              md={12}
              sm={24}
              style={{ marginTop: "3.5%", paddingLeft: 31, paddingRight: 60 }}
            >
              <Card
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + Images2})`,
                  height: "100%",
                  width: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></Card>
            </Col>
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ width: "100%", marginTop: "3%" }}
            >
              <div style={{ backgroundColor: "#FCFCFC", paddingBottom: 30 }}>
                <Col lg={24} md={24} sm={24} style={{ width: "100%" }}>
                  <Title
                    align="center"
                    style={{ fontSize: 35, fontWeight: 700, paddingTop: 55 }}
                    className="font"
                  >
                    OUR VALUES
                  </Title>
                  <p
                    align="center"
                    style={{ fontSize: 18, fontWeight: 400 }}
                    className="font"
                  >
                    The foundation of our business is founded on a few crucial
                    basic principles
                  </p>
                </Col>
                <Row justify="center" style={{ marginLeft: "10%" }}>
                  <Col lg={24} md={24} sm={24}>
                    <Descriptions>
                      <Descriptions.Item>
                        {" "}
                        <div style={{ paddingRight: "6%" }}>
                          <img
                            src={process.env.PUBLIC_URL + "/asset/AboutUs1.png"}
                            alt="Ant Design"
                            width="200"
                            height="200"
                            style={{ marginLeft: "10%", marginTop: 30 }}
                          />
                          <p
                            //align="left"
                            style={{
                              fontSize: 18,
                              fontWeight: 600,
                              textAlign: "left",
                              color: "red",
                              marginTop: 24,
                            }}
                            className="font"
                          >
                            Quality Is King..<br></br>
                            (Never Try To Cut Corners!)
                          </p>
                          <p
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              textAlign: "left",
                              color: "#676767",
                              marginTop: 9,
                            }}
                            className="font"
                          >
                            We believe in quality over everything, always.
                            <br></br> We only produce the best quality<br></br>
                            products so you can have a good night’s<br></br>{" "}
                            sleep with your loved ones.
                          </p>
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item>
                        {" "}
                        <div style={{ paddingRight: "5%" }}>
                          <img
                            src={process.env.PUBLIC_URL + "/asset/AboutUs2.png"}
                            alt="Ant Design"
                            width="300"
                            height="200"
                            style={{ marginLeft: "4%", marginTop: 30 }}
                          />
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 600,
                              textAlign: "left",
                              color: "red",
                              marginTop: 24,
                            }}
                          >
                            A Fair Day's Work Results In A Fair<br></br>
                            Day's Compensation
                          </p>
                          <p
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              textAlign: "left",
                              color: "#676767",
                              marginTop: 9,
                            }}
                            className="font"
                          >
                            We are totally against exploitation. Our team{" "}
                            <br></br>members who give their heart and<br></br>
                            soul to creating the best quality, niche, and
                            <br></br> highly durable products for you are
                            <br></br>
                            compensated well.
                          </p>
                        </div>
                      </Descriptions.Item>
                      <Descriptions.Item>
                        {" "}
                        <div style={{ paddingRight: "9.2%" }}>
                          <img
                            src={process.env.PUBLIC_URL + "/asset/AboutUs3.png"}
                            alt="Ant Design"
                            width="250"
                            height="200"
                            style={{ marginLeft: "6%", marginTop: 30 }}
                          />
                          <p
                            style={{
                              fontSize: 18,
                              fontWeight: 600,
                              textAlign: "left",
                              color: "red",
                              marginTop: 24,
                            }}
                          >
                            Take The Proper Action Always!
                          </p>
                          <p
                            style={{
                              fontSize: 14,
                              fontWeight: 400,
                              textAlign: "left",
                              color: "#676767",
                              marginTop: 36,
                            }}
                            className="font"
                          >
                            We always stand by the right. No matter<br></br>{" "}
                            what the situation, every team member<br></br>
                            at AllMesh will always have their arms raised
                            <br></br> for the right
                          </p>
                        </div>
                      </Descriptions.Item>
                    </Descriptions>
                  </Col>
                </Row>

                <Col lg={24} md={24} sm={24}>
                  <Descriptions>
                    <Descriptions.Item>
                      {" "}
                      <div style={{ paddingLeft: "37%" }}>
                        <img
                          src={process.env.PUBLIC_URL + "/asset/AboutUs4.png"}
                          alt="Ant Design"
                          width="230"
                          height="200"
                          style={{ marginLeft: "10%", marginTop: 30 }}
                        />
                        <p
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            textAlign: "left",
                            color: "red",
                            marginTop: 24,
                          }}
                          className="font"
                        >
                          A Secure Home Is <br></br> A Contented Household
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            textAlign: "left",
                            color: "#676767",
                            marginTop: 9,
                          }}
                          className="font"
                        >
                          You are our family. Your family means to us
                          <br></br>as much as it means to you. We sleep well{" "}
                          <br></br>when you sleep well. We never compromise
                          <br></br>on your protection. Enjoy a good night’s
                          sleep
                          <br></br>every night with AllMesh products.
                        </p>
                      </div>
                    </Descriptions.Item>

                    <Descriptions.Item>
                      {" "}
                      <div style={{ paddingLeft: "14%" }}>
                        <img
                          src={process.env.PUBLIC_URL + "/asset/AboutUs5.png"}
                          alt="Ant Design"
                          width="270"
                          height="200"
                          style={{ marginLeft: "6%", marginTop: 30 }}
                        />
                        <p
                          style={{
                            fontSize: 18,
                            fontWeight: 600,
                            textAlign: "left",
                            color: "red",
                            marginTop: 24,
                          }}
                        >
                          {" "}
                          Highest Goal Is To<br></br> Serve Others
                        </p>
                        <p
                          style={{
                            fontSize: 14,
                            fontWeight: 400,
                            textAlign: "left",
                            color: "#676767",
                            marginTop: 9,
                          }}
                          className="font"
                        >
                          We always follow the policy of YOU before US.<br></br>
                          Only when you're happy, we will have a<br></br>
                          satisfactory smile on our face.Your wish is<br></br>
                          our command!
                        </p>
                      </div>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </div>
            </Col>

            <Team />

            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ width: "100%", marginTop: 80 }}
            >
              <Contact from="about us" />
            </Col>
          </Row>
        </>
      )}

      {/* Mobile View */}
      {!matches && (
        <>
          <Row>
            <RightBar />
            <Whatsapp />
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ width: "100%", marginTop: 40, paddingRight: 10 }}
            >
              <Title
                style={{
                  marginLeft: "7%",
                  color: "red",
                  fontSize: 35,
                  fontWeight: 700,
                }}
                className="font"
              >
                ABOUT US
              </Title>
              <p
                style={{
                  marginLeft: "7%",
                  fontSize: 18,
                  fontWeight: 400,
                  marginTop: 23,
                }}
              >
                <p>
                  In order to offer long-lasting solutions for a variety of mesh
                  solutions for the home and office, such as security doors,
                  windows, insect screens, and blinds, AllMesh Secure Solutions
                  began business operations. AllMesh firmly believes in offering
                  high-quality products and professional solutions after fully
                  comprehending the needs of the customer.
                </p>
                <p>
                  {" "}
                  With a focus on seamless door solutions, AllMesh offers
                  exceptional pricing on security mesh window screens, sliding
                  security doors, fly screens, pet doors, and much more to home
                  and business owners across India.
                </p>
                Our goal is to give our customers the information, planning, and
                preparation they need to better protect themselves from
                intruder-based crimes. We also offer installation services to
                assist reduce weak places frequently utilised to gain access to
                properties.
                <p
                  style={{ color: "#D32402", fontWeight: 700, marginTop: "1%" }}
                >
                  {" "}
                  " If you need Mesh - Remember AllMesh "
                </p>
              </p>
            </Col>
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{
                marginTop: 32,
                paddingLeft: 30,
                paddingRight: 30,
                width: "100%",
              }}
            >
              <Card
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + Images2})`,
                  height: "72vh",

                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></Card>
            </Col>

            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ width: "100%", padding: "6%" }}
            >
              <div
                style={{
                  backgroundColor: "#FCFCFC",
                  paddingBottom: 30,
                  padding: "6%",
                }}
              >
                <Col
                  lg={24}
                  md={24}
                  sm={24}
                  style={{ width: "100%", marginTop: 30 }}
                >
                  <Title
                    style={{
                      fontSize: 30,
                      fontWeight: 700,
                      textAlign: "center",
                    }}
                    className="font"
                  >
                    Our Values
                  </Title>
                </Col>
                <Col lg={24} md={24} sm={24}>
                  <Descriptions>
                    <Descriptions.Item>
                      {" "}
                      <div bordered={false}>
                        <img
                          src={process.env.PUBLIC_URL + "/asset/AboutUs1.png"}
                          alt="Ant Design"
                          width="200"
                          height="200"
                          style={{ marginLeft: "18%", marginTop: 30 }}
                        />
                        <p
                          style={{
                            fontSize: 24,
                            fontWeight: 500,
                            textAlign: "left",
                            color: "red",
                          }}
                          className="font"
                        >
                          Quality Is King.. (Never Try To Cut Corners!)
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            textAlign: "left",
                          }}
                          className="font"
                        >
                          We believe in quality over everything, always. We only
                          produce the best quality products so you can have a
                          good night’s sleep with your loved ones.
                        </p>
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                      {" "}
                      <div bordered={false}>
                        <img
                          src={process.env.PUBLIC_URL + "/asset/AboutUs2.png"}
                          alt="Ant Design"
                          style={{ marginLeft: "-5%", marginTop: 30 }}
                        />
                        <p
                          style={{
                            fontSize: 24,
                            fontWeight: 500,
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          A Fair Day's Work Results In A Fair Day's Compensation
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            textAlign: "left",
                          }}
                          className="font"
                        >
                          We are totally against exploitation. Our team members
                          who give their heart and soul to creating the best
                          quality, niche, and highly durable products for you
                          are compensated well.
                        </p>
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                      {" "}
                      <div bordered={false}>
                        <img
                          src={process.env.PUBLIC_URL + "/asset/AboutUs3.png"}
                          alt="Ant Design"
                          style={{ marginTop: 30 }}
                        />
                        <p
                          style={{
                            fontSize: 24,
                            fontWeight: 500,
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          Take The Proper Action. Always.
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            textAlign: "left",
                          }}
                          className="font"
                        >
                          We always stand by the right. No matter what the
                          situation, every team member at AllMesh will always
                          have their arms raised for the right.
                        </p>
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                      {" "}
                      <div bordered={false}>
                        <img
                          src={process.env.PUBLIC_URL + "/asset/AboutUs4.png"}
                          alt="Ant Design"
                          style={{ marginLeft: "8%", marginTop: 30 }}
                        />
                        <p
                          style={{
                            fontSize: 24,
                            fontWeight: 500,
                            textAlign: "left",
                            color: "red",
                          }}
                          className="font"
                        >
                          A Secure Home Is A Contented Household
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            textAlign: "left",
                          }}
                          className="font"
                        >
                          You are our family. Your family means to us as much as
                          it means to you. We sleep well when you sleep well. We
                          never compromise on your protection. Enjoy a good
                          night’s sleep every night with AllMesh products.
                        </p>
                      </div>
                    </Descriptions.Item>
                    <Descriptions.Item>
                      {" "}
                      <div bordered={false}>
                        <img
                          src={process.env.PUBLIC_URL + "/asset/AboutUs5.png"}
                          alt="Ant Design"
                          // width="200"
                          // height="200"
                          style={{ marginTop: 30 }}
                        />
                        <p
                          style={{
                            fontSize: 24,
                            fontWeight: 500,
                            textAlign: "left",
                            color: "red",
                          }}
                        >
                          The Highest Goal Is To Serve Others
                        </p>
                        <p
                          style={{
                            fontSize: 16,
                            fontWeight: 400,
                            textAlign: "left",
                          }}
                          className="font"
                        >
                          We always follow the policy of YOU before US. Only
                          when you're happy, we will have a satisfactory smile
                          on our face.Your wish is our command!
                        </p>
                      </div>
                    </Descriptions.Item>
                  </Descriptions>
                </Col>
              </div>
            </Col>
            <Team />

            <Col lg={24} md={24} sm={24} style={{ width: "100%" }}>
              <Contact from="about us" />
            </Col>
          </Row>
        </>
      )}

      <Footers />
    </div>
  );
}

export default AboutUs;
