import React, { useState, useEffect } from "react";
import "../App.less";
import "antd/dist/antd.css";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Card, Typography } from "antd";
import ReactGA from "react-ga4";
const { Title } = Typography;

//class part state they holds the data this page
function Header() {
  const navigation = useNavigate();

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 512px)")
      .addEventListener("change", (e) => setMatches(e.matches));
  }, []);

  const trackEventFunction = (event) => {
    ReactGA.event({
      category: "Requirement category",
      action: "Requirement " + event + " Clicked",
      label: "Requirement label",
      value: 1,
    });
  };

  return (
    <div>
      {matches && (
        <>
          <Row>
            <Col lg={24} md={24} sm={24} style={{ marginTop: 0 }}>
              <div style={{ backgroundColor: "#F5FDFF" }}>
                <Col lg={24} md={24} sm={24}>
                  <Title
                    align="center"
                    className="font"
                    style={{
                      fontWeight: 600,
                      marginTop: 60,
                      fontSize: 40,
                      paddingTop: 60,
                    }}
                  >
                    Installation is<br></br>
                    complete in 48 hours.
                  </Title>
                </Col>
                <Col lg={24} md={24} sm={24} style={{ width: "100%" }}>
                  <p
                    align="center"
                    className="font"
                    style={{ fontWeight: 400, marginTop: 45, fontSize: 18 }}
                  >
                    We offer installation services to South Indian homeowners,
                    commercial businesses,and builders. <br></br> Our installers
                    do their work swiftly,thoroughly clean up after themselves,
                    adjust<br></br>
                    your new gate as needed, and provide any necessary finishing
                    touches.
                  </p>
                </Col>
                <Col
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={24}
                  style={{ width: "100%", marginTop: 60, paddingBottom: 60 }}
                >
                  <Button
                    style={{
                      fontSize: 12,
                      bottom: 15,
                      height: 48,
                      textAlign: "center",
                      width: "11%",
                      color: "white",
                      fontWeight: 500,
                      marginLeft: "37%",
                      backgroundColor: "#F5FDFF",
                      background:
                        "linear-gradient(0deg, #f5fdff, #f5fdff), #e7ecf0",
                      border: "none",
                      boxShadow:
                        "-5px -5px 6px #ffffff, 5px 5px 10px rgba(49, 50, 64, 0.2)",
                      borderRadius: "10px",
                    }}
                    className="contactneu"
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 900,
                        fontSize: 16,
                        paddingTop: 5,
                        color: "#D32402",
                      }}
                      onClick={() => {
                        navigation("/contactUs");
                        trackEventFunction("Contact");
                      }}
                    >
                      Contact Us
                    </p>
                  </Button>

                  <Button
                    style={{
                      fontSize: 12,
                      bottom: 15,
                      height: 48,
                      textAlign: "center",
                      width: "14%",
                      color: "white",
                      fontWeight: 900,
                      marginLeft: "2%",
                      backgroundColor: "#F5FDFF",
                      background:
                        "linear-gradient(0deg, #f5fdff, #f5fdff), #e7ecf0",
                      border: "none",
                      boxShadow:
                        "-5px -5px 6px #ffffff, 5px 5px 10px rgba(49, 50, 64, 0.2)",
                        borderRadius: "10px",
                    }}
                    className="contactneu"
                  >
                    <p
                      style={{
                        textAlign: "center",
                        fontWeight: 900,
                        fontSize: 16,
                        paddingTop: 5,
                        color: "#D32402",
                      }}
                      onClick={() => {
                        navigation("/estimate");
                        trackEventFunction("Estimation");
                      }}
                    >
                      Get an Estimate
                    </p>
                  </Button>
                </Col>
              </div>
            </Col>
          </Row>
        </>
      )}
      {!matches && (
        <>
          <Row>
            <Col lg={24} md={24} sm={24} style={{ marginTop: 30, padding: 24 }}>
              <Card style={{ backgroundColor: "#F5FDFF" }}>
                <Col lg={24} md={24} sm={24}>
                  <Title
                    align="center"
                    className="font"
                    style={{ fontWeight: 600, marginTop: 39, fontSize: 28 }}
                  >
                    Installation is<br></br>
                    complete in 24 hours.
                  </Title>
                </Col>
                <Col lg={24} md={24} sm={24} style={{ width: "100%" }}>
                  <p
                    align="center"
                    className="font"
                    style={{ fontWeight: 400, marginTop: 45, fontSize: 18 }}
                  >
                    We offer installation services to South Indian homeowners,
                    commercial businesses,and builders. Our installers do their
                    work swiftly,thoroughly clean up after themselves, adjust
                    your new gate as needed, and provide any necessary finishing
                    touches.
                  </p>
                </Col>
                <Col
                  lg={{ span: 24 }}
                  md={{ span: 24 }}
                  sm={24}
                  style={{ width: "100%", marginTop: 60, paddingBottom: 30 }}
                >
                  <Button
                    style={{
                      fontSize: 12,
                      bottom: 15,
                      height: 32,
                      textAlign: "center",
                      width: "30%",
                      color: "#D32402",
                      fontWeight: 900,

                      marginLeft: "10%",
                      backgroundColor: "#F5FDFF",
                    }}
                    className="contactneu"
                    onClick={() => {
                      navigation("/contactUs");
                      trackEventFunction("Contact");
                    }}
                  >
                    Contact Us
                  </Button>

                  <Button
                    style={{
                      fontSize: 12,
                      bottom: 15,
                      height: 32,
                      textAlign: "center",
                      width: "45%",
                      color: "#D32402",
                      fontWeight: 900,

                      marginLeft: "5%",
                      backgroundColor: "#F5FDFF",
                      // borderRadius: 4,
                    }}
                    className="contactneu"
                    onClick={() => {
                      navigation("/estimate");
                      trackEventFunction("Estimation");
                    }}
                  >
                    Get an Estimate
                  </Button>
                </Col>
              </Card>
            </Col>
          </Row>
        </>
      )}
    </div>
  );
}

export default Header;
