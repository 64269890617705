import { ADD_ITEM, REMOVE_ITEM, SET_BILL_ITEMS, SET_VALUE } from "../action";
import uuid from "uuid/dist/v4";

const initialState = {
  total: 0,
  items: {},
  customerName: "",
  customerEmail: "",
  customerPhone: "",
};

export default function cartReducer(state = initialState, action) {
  switch (action.type) {
    case ADD_ITEM:
      //add
      //alert(JSON.stringify(action.payload));
      var tempObj = state.items;
      var amount = state.total;

      var id = uuid();
      tempObj[id] = {};
      tempObj[id].id = action.payload.item.id;
      tempObj[id].productName = action.payload.item.productName;
      tempObj[id].productDescription = action.payload.item.productDescription;
      tempObj[id].panelCount = action.payload.panelCount;
      tempObj[id].height = action.payload.height;
      tempObj[id].width = action.payload.width;
      tempObj[id].itemTotal = parseFloat(action.payload.total);
      tempObj[id].componentList = action.payload.item.componentList;
      tempObj[id].productLocation = action.payload.productLocation;
      tempObj[id].productType = action.payload.productType;
      tempObj[id].areaInSqFt = action.payload.areaInSqFt;
      tempObj[id].itemSubTotal = action.payload.subTotal;
      tempObj[id].productMargin = action.payload.productMargin;
      tempObj[id].marginAmount = action.payload.marginAmount;
      tempObj[id].color = action.payload.color;
      tempObj[id].itemPrice = action.payload.price;
      tempObj[id].quantity = action.payload.quantity;
      tempObj[id].selectedColorCharge = action.payload.selectedColorCharge;
      tempObj[id].colorCharges = action.payload.colorCharges;

      amount = amount + parseFloat(action.payload.total);

      return {
        ...state,
        items: tempObj,
        total: amount,
      };

    // break;
    case REMOVE_ITEM:
      //remove
      var tempObj = state.items;
      var amount = state.total;
      amount -= state.items[action.payload].itemTotal;
      delete tempObj[action.payload];
      return {
        ...state,
        items: tempObj,
        total: amount,
      };

    case SET_BILL_ITEMS:
      return {
        ...state,
        items: action.payload.orderDetails,
        total: action.payload.total,
        customerName: action.payload.customerName,
        customerEmail: action.payload.customerEmail,
        customerPhone: action.payload.customerPhone,
      };

    case SET_VALUE:
      return {
        ...state,
        customerName: action.payload.customerName,
        customerEmail: action.payload.customerEmail,
        customerPhone: action.payload.customerPhone,
      };

    default:
      return state;
  }
}
