import { combineReducers } from "redux";

import commonReducer from "./common.reducer";
import billReducer from "./billing.reducer";

const rootReducer = combineReducers({
  common: commonReducer,
  bill: billReducer,
});

export default rootReducer;
