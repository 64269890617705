import React, { useState, useEffect } from "react";
import "../App.less";
import { useNavigate } from "react-router-dom";
import { Col, Row, Button, Card, Typography, Modal, Input } from "antd";
import environment from "../configure";
import uuid from "uuid/dist/v4";
import ReactGA from "react-ga4";
const { Text } = Typography;

function Popup(props) {
  const navigation = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [customerName, setCustomerName] = useState("");
  const [customerEmail, setCustomerEmail] = useState("");
  const [customerPhone, setCustomerPhone] = useState("");
  const addedDate = new Date().toISOString().substr(0, 10);

  useEffect(() => {
    showModal();
  }, []);

  const submit = async () => {
    if (customerName == "") return alert("Please enter your name");
    if (customerEmail == "") return alert("Please enter your email");
    var mailReg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (customerEmail !== "" && mailReg.test(customerEmail) === false)
      return alert("Please enter valid email");
    if (customerPhone == "") return alert("Please enter your phone number");
    var phoneReg = /^[5-9]{1}[0-9]{9}$/;
    if (phoneReg.test(customerPhone) === false)
      return alert("Please enter valid phone number");

    try {
      var addOrder = await fetch(
        environment.apiGateway + "addQuotationDetails",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            id: uuid(),
            customerName: customerName,
            customerPhone: "+91" + customerPhone,
            customerEmail: customerEmail,
            customerReferenceNumber: "NA",
            addedDate: addedDate,
            time: new Date().toTimeString().substr(0, 8),
            estimatedAmount: 0,
            orderDetails: "NA",
            type: "Lead",
          }),
        }
      );
    } catch (error) {
      console.log(error);
    }
    alert("AllMesh team will contact you soon");
    setIsModalOpen(false);
    navigation("/");
  };

  const showModal = () => {
    setTimeout(function () {
      setIsModalOpen(true);
    }, 5000);
  };

  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1169px)").matches
  );

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const trackEventFunction = () => {
    ReactGA.event({
      category: "Pop Up category",
      action: "Pop Up Submit Clicked",
      label: "Pop Up label",
      value: 1,
    });
  };

  return (
    <div>
      <Row>
        <Col lg={24} md={24} sm={24} style={{ width: "100%" }}>
          <Modal
            open={isModalOpen}
            onOk={handleOk}
            onCancel={handleCancel}
            footer={false}
          >
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ marginTop: 30, marginLeft: 24 }}
            >
              <Text style={{ fontSize: 32, fontWeight: 500 }} className="font">
                Talk to our mesh
              </Text>
              <Text style={{ fontSize: 32, fontWeight: 900 }} className="font">
                {" "}
                experts
              </Text>
              <br></br>
              <Text
                type="danger"
                style={{ fontSize: 32, fontWeight: 900 }}
                className="font"
              >
                {" "}
                now!
              </Text>
            </Col>
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ marginTop: 50, marginLeft: 24 }}
            >
              <Text style={{ fontSize: 14, fontWeight: 500 }}>Name</Text>
              <Text type="danger">*</Text>
            </Col>
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ marginTop: 16, marginLeft: 24 }}
            >
              <Input
                style={{
                  width: "94%",
                  height: 40,
                  marginTop: -100,
                }}
                value={customerName}
                onChange={(e) => setCustomerName(e.target.value)}
              />
            </Col>

            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ marginTop: 30, marginLeft: 24 }}
            >
              <Text style={{ fontSize: 14, fontWeight: 500 }}>E-mail</Text>
              <Text type="danger">*</Text>
            </Col>

            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ marginTop: 16, marginLeft: 24 }}
            >
              <Input
                style={{
                  width: "94%",
                  height: 40,
                  marginTop: -100,
                }}
                value={customerEmail}
                onChange={(e) => setCustomerEmail(e.target.value)}
              />
            </Col>

            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ marginTop: 30, marginLeft: 24 }}
            >
              <Text style={{ fontSize: 14, fontWeight: 500 }}>
                Mobile Number
              </Text>
              <Text type="danger">*</Text>
            </Col>
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ marginTop: 16, marginLeft: 24 }}
            >
              <Input
                style={{
                  width: "94%",
                  height: 40,
                  marginTop: -100,
                }}
                value={customerPhone}
                onChange={(e) => setCustomerPhone(e.target.value)}
                maxLength={10}
              />
            </Col>

            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ marginTop: 45, marginLeft: 24, paddingBottom: 85 }}
            >
              <Button
                style={{
                  width: "94%",
                  height: 40,
                  backgroundColor: "red",
                  borderRadius: 4,
                }}
                onClick={() => {
                  submit();
                  trackEventFunction();
                }}
              >
                <Text style={{ color: "white", fontWeight: 500, fontSize: 14 }}>
                  Submit
                </Text>
              </Button>
            </Col>
          </Modal>
        </Col>
      </Row>
    </div>
  );
}

export default Popup;
