import React from "react";
import "../App.less";
import Testimonal from "./testimonal";
import Banners from "./banners";
import Gallery from "./gallery";
import LocationTypeTabs from "./locationTypeTabs";
import Footers from "./footer";
import RightBar from "./rightBar";
import Whatsapp from "./whatsapp";
import ContactUs from "./contact";
import PopUp from "./popup";
import RequirementTemplate from "./requirementTemplate";
import Journey from "./journey";
import Faq from "./faq";
import axios from "axios";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import DownloadBrochure from "./downloadBrochure";

//class part state they holds the data this page
function LandingPage(props) {
  const navigation = useNavigate();
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 1169px)").matches
  );
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window
      .matchMedia("(min-width: 1169px)")
      .addEventListener("change", (e) => setMatches(e.matches));
    async function getJSONValue() {
      try {
        const res = await axios.get(
          "https://allmeshimages.s3.ap-south-1.amazonaws.com/JSON/allMeshJSON.json",
          {
            headers: {
              "Cache-Control": "no-cache",
            },
          }
        );
        props.setJSON(res.data);
        setLoading(false);
      } catch (error) {
        alert(error);
      }
    }
    getJSONValue();
    props.setBillItems({
      orderDetails: {},
      total: 0,
      customerName: "",
      customerEmail: "",
      customerPhone: "",
    });
  }, []);
  return (
    <div className="fill-window">
      <>
        <PopUp />
        <Banners />
        {!loading && <LocationTypeTabs />}
        <Gallery />
        <RequirementTemplate />
        <Journey />
        <Testimonal />
        <Faq />
        <ContactUs from="home page" />
        <RightBar />
        <Whatsapp />
        {/* <DownloadBrochure /> */}

        <Footers />
      </>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    common: state.common,
    bill: state.bill,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    setBillItems: (data) => dispatch({ type: "SET_BILL_ITEMS", payload: data }),
    setJSON: (data) => dispatch({ type: "SET_JSON", payload: data }),
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(LandingPage);
