import React, { useState,useEffect } from "react";
import "../App.less";
import { Col, Row, Card, Typography ,Button} from "antd";
import Images2 from "../Images/team.png";
import Team from "./ceo";
const { Title, Text } = Typography;

//class part state they holds the data this page
function Header(props) {
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );
  const [tab, setTab] = useState("ceo");


  useEffect(() => {
    setTab("ceo")
    window
    .matchMedia("(min-width: 512px)")
    .addEventListener("change", (e) => setMatches(e.matches));
  }, []);


  return (
    <div>
      <Row>
        {matches && (
          <>
            {" "}
            <Col lg={24} md={24} sm={24} style={{ marginTop: 50 }}>
              {/* <h2
                align="center"
                className="font"
                style={{ fontWeight: 700, fontSize: 35 }}
              >
                OUR TEAM
              </h2> */}

              <Button
                style={{
                  fontSize: 18,
                  bottom: 1,
                  height: 60,
                  width: "16%",
                  fontWeight: 900,
                  borderRadius: 4,
                  marginLeft: "30%",
                  paddingTop: 6,
                  borderTopLeftRadius: 50,
                  borderBottomRightRadius: 50,
                  backgroundColor:tab=="ceo"?'#D32402':''  ,
                  borderColor: "#D32402",
                  borderWidth: 2,
                  color:  tab=="ceo"?'white':'black' , 
                }}
                onClick={() => {
                  setTab("ceo");
                }}
              >
                CEO
              </Button>
              <Button
                style={{
                  fontSize: 18,
                  bottom: 1,
                  height: 60,
                  width: "16%",
                  fontWeight: 900,
                  borderTopLeftRadius: 50,
                  borderBottomRightRadius: 50,
                  marginLeft: "10%",
                  paddingTop: 6,
                  borderColor: "#D32402",
                  borderWidth: 2,
                  backgroundColor:tab=="team"?'#D32402':'' ,
                  color:  tab=="team"?'white':'black' ,   
                }}
               
                onClick={() => {
                  setTab("team");
                }}
              >
                TEAM
              </Button>
            </Col>
            {tab == "ceo" ? (
              <>
                <Col lg={12} md={12} sm={24} style={{ marginTop: 65 }}>
                  <p
                    style={{
                      marginLeft: 60,
                      fontSize: 18,
                      fontWeight: 400,
                      marginTop: -10,
                    }}
                  >
                    <h3>
                      Mr. Kshritij Bhardwaj started AllMesh Secure Solutions in
                      the year 2020. He is a true pioneer of the Mesh industry
                      and has single handedly brought along the much required
                      change that has helped the Indian audience in choosing the
                      right kind of product as per their requirement in mesh
                      industry.
                    </h3>
                    <p>
                      {" "}
                      The mission of the CEO is to bring peace of mind to his
                      customers by ensuring the ease of process and stability in
                      the fabric of this category. AllMesh is India’s very first
                      online platform to buy any and every kind of mesh. From
                      pigeon mesh to mosquito mesh, you name it, they have it.
                    </p>
                    “In times of change, learners inherit the earth, while the
                    learned find themselves beautifully equipped to deal with a
                    world that no longer exists.” said a great philosopher Mr.
                    Eric Hoffer. 2020 was the year that changed the world and
                    its perspective towards life completely. Whilst many were
                    focusing on how to bring about a change that will positively
                    impact the lives of consumers in the modern world, few were
                    focusing on their strengths and how to upskill the same and
                    bring it to the service of a mass audience. Staying true to
                    his nature, Mr. Kshritij Bhardwaj who we found to be a true
                    learned professional, fighter, and a skilled businessman
                    decided to upscale the Mesh market in India, even in those
                    trying times when the world was hit by the Novel
                    Coronavirus.
                  </p>
                </Col>
                <Col
                  lg={12}
                  md={12}
                  sm={24}
                  style={{ marginTop: 50, paddingLeft: 31, paddingRight: 60 }}
                >
                  <Card
                    style={{
                      backgroundImage: `url(${
                        process.env.PUBLIC_URL + Images2
                      })`,
                      height: "90vh",
                      width: "100%",
                      backgroundRepeat: "no-repeat",
                      backgroundSize: "cover",
                    }}
                  ></Card>
                </Col>
              </>
            ) : (
              <Team />
            )}
          </>
        )}
        {!matches && (
          <>
            {" "}
            <Col
              lg={24}
              md={24}
              sm={24}
              style={{ marginTop: 10, width: "100%" }}
            >
              <Button
                style={{
                  fontSize: 14,
                  bottom: 1,
                  height: 60,
                  width: "25%",
                  fontWeight: 900,
                  borderRadius: 4,
                  marginLeft: "20%",
                  paddingTop: 6,
                  borderTopLeftRadius: 50,
                  borderBottomRightRadius: 50,
                  backgroundColor:tab=="ceo"?'#D32402':''  ,
                  borderColor: "#D32402",
                  borderWidth: 2,
                  color:  tab=="ceo"?'white':'black' , 
                }}
                onClick={() => {
                  setTab("ceo");
                }}
              >
                CEO
              </Button>
              <Button
                style={{
                  fontSize: 14,
                  bottom: 1,
                  height: 60,
                  width: "25%",
                  fontWeight: 900,
                  borderTopLeftRadius: 50,
                  borderBottomRightRadius: 50,
                  marginLeft: "10%",
                  paddingTop: 6,
                  borderColor: "#D32402",
                  borderWidth: 2,
                  backgroundColor:tab=="team"?'#D32402':'' ,
                  color:  tab=="team"?'white':'black' ,   
                }}
               
                onClick={() => {
                  setTab("team");
                }}
              >
                TEAM
              </Button>
            </Col>
           
            {tab == "ceo" ? (
              <>
              <Col
              lg={24}
              md={24}
              sm={24}
              style={{
                marginTop: 30,
                width: "100%",
                paddingLeft: 30,
                paddingRight: 30,
              }}
            >
              <Card
                style={{
                  backgroundImage: `url(${process.env.PUBLIC_URL + Images2})`,
                  height: "72vh",
                  width: "100%",
                  backgroundRepeat: "no-repeat",
                  backgroundSize: "cover",
                }}
              ></Card>
            </Col>
                <Col lg={12} md={12} sm={24} style={{ marginTop: 65, }}>
                  <p
                    style={{
                     paddingLeft:'5%',
                      fontSize: 18,
                      fontWeight: 400,
                      marginTop: -10,
                      paddingRight:"5%"
                    }}
                  >
                    <h3>
                      Mr. Kshritij Bhardwaj started AllMesh Secure Solutions in
                      the year 2020. He is a true pioneer of the Mesh industry
                      and has single handedly brought along the much required
                      change that has helped the Indian audience in choosing the
                      right kind of product as per their requirement in mesh
                      industry.
                    </h3>
                    <p>
                      {" "}
                      The mission of the CEO is to bring peace of mind to his
                      customers by ensuring the ease of process and stability in
                      the fabric of this category. AllMesh is India’s very first
                      online platform to buy any and every kind of mesh. From
                      pigeon mesh to mosquito mesh, you name it, they have it.
                    </p>
                    “In times of change, learners inherit the earth, while the
                    learned find themselves beautifully equipped to deal with a
                    world that no longer exists.” said a great philosopher Mr.
                    Eric Hoffer. 2020 was the year that changed the world and
                    its perspective towards life completely. Whilst many were
                    focusing on how to bring about a change that will positively
                    impact the lives of consumers in the modern world, few were
                    focusing on their strengths and how to upskill the same and
                    bring it to the service of a mass audience. Staying true to
                    his nature, Mr. Kshritij Bhardwaj who we found to be a true
                    learned professional, fighter, and a skilled businessman
                    decided to upscale the Mesh market in India, even in those
                    trying times when the world was hit by the Novel
                    Coronavirus.
                  </p>
                </Col>
                
              </>
            ) : (
              <Team />
            )}
          </>
        )}
      </Row>
    </div>
  );
}

export default Header;
