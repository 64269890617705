import React, { useEffect } from "react";
import "./App.less";
import "antd/dist/antd.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Estimate from "./components/estimate";
import LandingPage from "./components/landingPage";
import RightBar from "./components/rightBar";
import Header from "./components/header";
import CustContactDetails from "./components/customerContactDetails";
import QuotationDetails from "./components/quotationDetail";
import AboutUs from "./components/aboutUs";
import Products from "./components/products";
import ContactUs from "./components/contactUs";
import Projects from "./components/projects";
import Condition from "./components/Condition";
import ReactGA from "react-ga4";
import environment from "./configure";
import Franchise from "./components/Franchise";
import LocateUs from "./components/locateUs";
function App() {
  useEffect(() => {
    ReactGA.initialize(environment.TRACKING_ID);
    ReactGA.send("Home Page");
  });
  return (
    <div>
      <BrowserRouter>
        <Header />
        <Routes>
          <Route index element={<LandingPage />} />
          <Route path="estimate" element={<Estimate />} />
          <Route path="custContactDetails" element={<CustContactDetails />} />
          <Route path="quotationDetails" element={<QuotationDetails />} />
          {/* <Route path="home" element={<LandingPage/>}/> */}
          <Route path="aboutUs" element={<AboutUs />} />
          <Route path="franchise" element={<Franchise />} />
          <Route path="products" element={<Products />} />
          <Route path="contactUs" element={<ContactUs />} />
          <Route path="projects" element={<Projects />} />
          <Route path="condition" element={<Condition />} />
          <Route path="locateUs" element={<LocateUs />} />
        </Routes>
      </BrowserRouter>
      {/* <RightBar /> */}
    </div>
  );
}
export default App;
