import React, { Component } from "react";
import "./Franchise.css"; // You can create a CSS file for styling

class Franchise extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedButton: "Why Us",
    };
  }

  handleButtonClick = (button) => {
    this.setState({ selectedButton: button });
  };

  renderContent() {
    switch (this.state.selectedButton) {
      case "Why Us":
        return (
          <div>
            <p> - Expertise and Quality Products</p>
            <p> - Comprehensive Franchise Support: Includes Allmesh CRM</p>
            <p> - Proven Business Model and Success Stories</p>
            <p> - Innovative Products and Sustainable Practices</p>
          </div>
        );
      case "Support":
        return (
          <div>
            <p> - 24x7 Customer Support</p>
            <p> - No Dead Stock</p>
            <p> - End 2 End Order Management</p>
            <p>
              - Marketing Support – Digitally Strong and Hyper Local marketing
            </p>
          </div>
        );
      case "Showroom/Display":
        return (
          <div>
            <p> - Up to 300 Sqft – Allmesh Studio</p>
            <p> - 300Sqft – 450 Sqft – Allmesh boutique</p>
            <p> - Above 450 Sqft – Allmesh Gallery</p>
            <p> - Frontage: Minimum 12Ft prominent and appealing storefront</p>
            <p> - Ceiling Height – Minimum 10Ft height</p>
          </div>
        );
      case "Vision":
        return (
          <div>
            <p>
              Empowering communities worldwide with our innovative mosquito mesh
              solutions, we envision a franchise network committed to
              safeguarding lives through our quality products, sustainable
              practices, and a shared mission to create mosquito-free
              environments for a healthier and happier future
            </p>
          </div>
        );
      default:
        return null;
    }
  }

  render() {
    return (
      <div className="main-frnachise">
        <div className="full-width-card">
          <div className="centered-content-card">
            <p>Budget Friendly - Lowest Franchise fee INR 1,00,000</p>
          </div>
        </div>
        <div id="comp-lpgdgb2h2" className="comp-lpgdgb2h2-container">
          <div className="card">
            <div className="left-content">
              <h1>Profit</h1>
              <h2>20-25%</h2>
            </div>
            <div className="right-content">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTKmbapiUpef7N_FCIKyR-3OajIr6Ias95v8EyugmKyYAwLmYox"
                alt="Profit Image"
              />
            </div>
          </div>
        </div>
        <div id="comp-lpgdgb2h2" className="comp-lpgdgb2h2-container">
          <div className="card">
            <div className="left-content">
              <h1>ROI </h1>
              <h2>18-21 months</h2>
            </div>
            <div className="right-content">
              <img
                src="https://encrypted-tbn3.gstatic.com/images?q=tbn:ANd9GcQgQzHDmP_aNaqfscyWz6v_Ma5SYfJpX8-G8dKWZiTbSguErBxm"
                alt="Profit Image"
              />
            </div>
          </div>
        </div>
        <div id="comp-lpgdgb2h2" className="comp-lpgdgb2h2-container">
          <div className="card">
            <div className="left-content">
              <h1>Capex</h1>
              <h2>6 Lacs onwards</h2>
            </div>
            <div className="right-content">
              <img
                src="https://encrypted-tbn2.gstatic.com/images?q=tbn:ANd9GcSTB_rVj4HS-73TXPnsClCfS62l4EyLwsR07FK63NFLBgadWJWb"
                alt="Profit Image"
              />
            </div>
          </div>
        </div>
        <div id="comp-lpgdgb2h2" className="comp-lpgdgb2h2-container">
          <div className="card">
            <div className="left-content">
              <h1>Space</h1>
              <h2>300Sqft - 600Sqft</h2>
            </div>
            <div className="right-content">
              <img
                src="https://encrypted-tbn1.gstatic.com/images?q=tbn:ANd9GcRMNBAC7k_5Kq0OoRzGCRsPuQcwQBTjpbhs_erAoWX-1fD6-PJF"
                alt="Profit Image"
              />
            </div>
          </div>
        </div>

        <div className="button-section">
          <button
            className={`button ${
              this.state.selectedButton === "Why Us" ? "active" : ""
            }`}
            onClick={() => this.handleButtonClick("Why Us")}
          >
            Why Us
          </button>
          <button
            className={`button ${
              this.state.selectedButton === "Support" ? "active" : ""
            }`}
            onClick={() => this.handleButtonClick("Support")}
          >
            Support
          </button>
          <button
            className={`button ${
              this.state.selectedButton === "Showroom/Display" ? "active" : ""
            }`}
            onClick={() => this.handleButtonClick("Showroom/Display")}
          >
            Showroom/Display
          </button>
          <button
            className={`button ${
              this.state.selectedButton === "Vision" ? "active" : ""
            }`}
            onClick={() => this.handleButtonClick("Vision")}
          >
            Vision
          </button>
        </div>
        <div className="selected-content">{this.renderContent()}</div>
      </div>
    );
  }
}

export default Franchise;
