import React, { useState, useEffect } from "react";
import { Col, Row,} from "antd";
import "../App.less";
import environment from "../configure";
import Carousel from "react-multi-carousel";

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

//class part state they holds the data this page
function Banners() {
  const [banners, setBanners] = useState([]);
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 512px)").matches
  );
  useEffect(() => {
    window
    .matchMedia("(min-width: 512px)")
    .addEventListener("change", (e) => setMatches(e.matches));
    async function fetchBanners() {
      try {
        await fetch(environment.apiGateway + "fetchProductDetails", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            type: "Banner",
          }),
        }).then(async (fetchedDetails) => {
          var fetchedRespone = await fetchedDetails.json();
          let res = fetchedRespone.Items;
          setBanners(res);
        });
      } catch (err) {
        return err;
      }
    }
    fetchBanners();
  }, []);

  return (
    <div>
      <Row>
        <Col lg={24} md={24} sm={24} style={{ width: "100%" }}>
          <Carousel
            swipeable={true}
            draggable={false}
            showDots={false}
            responsive={responsive}
            ssr={true}
            arrows={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            infinite={true}
            keyBoardControl={true}
            transitionDuration={1000}
            itemClass="items"
            sliderClass="slider"
          >
            {banners.map((data) => {
              return (
                <div>
                  {matches && (
                    
                    <img src={data.productImages}  height={600} width="100%" />
                    
                  )}
                  {!matches && (
                    <img src={data.productImages} height="auto" width="100%" />
                  )}
                </div>
              );
            })}
          </Carousel>
        </Col>
      </Row>
    </div>
  );
}

export default Banners;
